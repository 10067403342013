import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { Money } from "@components/containers";
import RenderLineTr from "./RenderLineTr";

var RenderGroupByUshop = function RenderGroupByUshop(props) {
  var lines = props.lines,
      gkey = props.gkey,
      refetchOrder = props.refetchOrder,
      setRefetchChat = props.setRefetchChat;
  var ushops = [];
  lines.forEach(function (line) {
    var tmp = ushops.find(function (ushop) {
      var _line$ushop;

      return ushop.id === ((_line$ushop = line.ushop) === null || _line$ushop === void 0 ? void 0 : _line$ushop.id);
    });

    if (tmp) {
      tmp.lines.push(line);
    } else {
      ushops.push(_objectSpread({}, line.ushop, {
        lines: [line]
      }));
    }
  });
  return React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("td", {
    style: {
      width: 30
    }
  }, "#"), React.createElement("td", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u0443\u0443\u0434"), React.createElement("td", {
    style: {
      width: 75
    }
  }, "\u04AE\u043D\u044D"), React.createElement("td", {
    style: {
      width: 50
    }
  }, "\u0422\u043E\u043E"), React.createElement("td", {
    style: {
      width: 80
    }
  }, "\u041D\u0438\u0439\u0442 \u04AF\u043D\u044D"))), React.createElement("tbody", null, ushops.map(function (ushop) {
    var _ushop$logoImage;

    return React.createElement(React.Fragment, {
      key: "ushop-group-".concat(ushop.id)
    }, React.createElement("tr", {
      className: "ushop-group"
    }, React.createElement("td", null, React.createElement("img", {
      style: {
        width: 30,
        margin: 0
      },
      src: (_ushop$logoImage = ushop.logoImage) === null || _ushop$logoImage === void 0 ? void 0 : _ushop$logoImage.url,
      alt: ushop.name
    })), React.createElement("td", {
      colSpan: 2
    }, React.createElement("div", {
      style: {
        display: "flex",
        alignItems: "center",
        gap: "2rem"
      }
    }, React.createElement("span", null, ushop.name), ushop.lines[0].soonDate ? React.createElement("div", null, React.createElement("span", {
      style: {
        fontWeight: "normal"
      }
    }, "\u0410\u043D\u0433\u043B\u0438\u0434 \u0438\u0440\u044D\u0445 \u043E\u0433\u043D\u043E\u043E"), React.createElement("p", null, ushop.lines[0].soonDate)) : null)), React.createElement("td", null, gkey === "cancelled" ? ushop.lines.reduce(function (a, b) {
      return a + b.orderLine.quantityCanceled;
    }, 0) : ushop.lines.reduce(function (p, c) {
      return p + c.quantity;
    }, 0)), React.createElement("td", null, React.createElement(Money, {
      money: {
        currency: ushop.lines[0].orderLine.unitPrice.gross.currency || "GBP",
        amount: gkey === "cancelled" ? ushop.lines.reduce(function (a, b) {
          return a + b.orderLine.quantityCanceled * b.orderLine.unitPrice.gross.amount;
        }, 0) : ushop.lines.reduce(function (p, c) {
          return p + c.quantity * c.orderLine.unitPrice.gross.amount;
        }, 0)
      }
    }))), ushop.lines.map(function (line, index) {
      return React.createElement(RenderLineTr, {
        line: line,
        key: "ushop-".concat(ushop.id, "-line-").concat(index),
        gkey: gkey,
        refetchOrder: refetchOrder,
        setRefetchChat: setRefetchChat
      });
    }));
  })));
};

export default RenderGroupByUshop;