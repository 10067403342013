import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-bottom: 4px;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 20px;\n  /* padding-top: 20px;*/\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 60px;\n  margin-bottom: 60px;\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 20px;\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  margin-bottom: ", ";\n\n  small {\n    color: ", ";\n    font-size: ", ";\n    font-weight: normal;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    font-size: 18px;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n\n  ", "\n\n  \n  font-weight: ", ";\n  margin-bottom: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var AddToCartSelection = styled.div(_templateObject());
export var ProductNameHeader = styled.h3(_templateObject2(), media.smallScreen(_templateObject3()), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var ProductPricing = styled.h4(_templateObject4(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var UndiscountedPrice = styled.span(_templateObject5(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var VariantPicker = styled.div(_templateObject6());
export var ShippingPicker = styled.div(_templateObject7());
export var QuantityInput = styled.div(_templateObject8());
export var ErrorMessage = styled.p(_templateObject9(), function (props) {
  return props.theme.colors.error;
});
export var SalePercent = styled.p(_templateObject10(), function (props) {
  return props.theme.colors.saleColor;
});