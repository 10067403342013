import _objectSpread from "@babel/runtime/helpers/objectSpread";
import * as React from "react";
import { Link } from "react-router-dom";
import { Money, TaxedMoney } from "@components/containers"; // import { Thumbnail } from "@components/molecules";

import { PRODUCT_TYPE_SHIPPING } from "@temp/constants";
import { generateProductUrl } from "@temp/core/utils";
import * as S from "./styles";

var DraftOrder = function DraftOrder(_ref) {
  var draftOrder = _ref.draftOrder;
  var lines = draftOrder;
  var ushops = [];
  var variants = (lines === null || lines === void 0 ? void 0 : lines.map(function (tmp) {
    return tmp.variant;
  })) || [];
  var productVariants = variants.filter(function (tmp) {
    return tmp.product.productType.id !== PRODUCT_TYPE_SHIPPING;
  });
  var shippingVariants = variants.filter(function (tmp) {
    return tmp.product.productType.id === PRODUCT_TYPE_SHIPPING;
  });
  /** productuudiig delguurt hargalzuulj bga hesge */

  productVariants.map(function (variant) {
    var shop = ushops.find(function (el) {
      return el.id === variant.product.ushop.id;
    });
    var line = lines.find(function (el) {
      return el.variant.id === variant.id;
    });

    if (!shop) {
      ushops.push(_objectSpread({}, variant.product.ushop, {
        lines: [line]
      }));
    } else {
      shop.lines.push(line);
    }
  });
  shippingVariants.map(function (el) {
    var ushop = ushops.find(function (shop) {
      return shop.id === el.product.ushop.id;
    });

    if (ushop) {
      var _el$pricing, _el$pricing$price;

      ushop.shippingUkGross = el === null || el === void 0 ? void 0 : (_el$pricing = el.pricing) === null || _el$pricing === void 0 ? void 0 : (_el$pricing$price = _el$pricing.price) === null || _el$pricing$price === void 0 ? void 0 : _el$pricing$price.gross;
    }
  });
  return React.createElement(React.Fragment, null, ushops === null || ushops === void 0 ? void 0 : ushops.map(function (_shop, index) {
    var _shop$logoImage;

    var shopTotal = _shop.lines.map(function (line) {
      return line.totalPrice.gross.amount;
    }).reduce(function (a, c) {
      return a + c;
    }, 0);

    var _shopVariantQuantity = _shop.lines.map(function (line) {
      return line.quantity;
    }).reduce(function (a, c) {
      return a + c;
    }, 0);

    var priceFormat = null;
    return React.createElement("div", {
      key: index,
      className: "cart__shop"
    }, React.createElement(S.LineTitle, null, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("img", {
      className: "cart__shop__logo",
      src: (_shop$logoImage = _shop.logoImage) === null || _shop$logoImage === void 0 ? void 0 : _shop$logoImage.url,
      alt: _shop.name
    }), React.createElement("span", null, _shop.name), React.createElement("span", null, " (", _shopVariantQuantity, ")")), React.createElement("span", {
      className: "cart__shop__total titleMoney"
    }, React.createElement(Money, {
      money: {
        amount: shopTotal,
        currency: "GBP"
      }
    }), React.createElement("span", {
      style: {
        fontSize: "0.8rem",
        color: "gray"
      }
    }, " ", "(", _shopVariantQuantity, " \u0431\u0430\u0440\u0430\u0430)"), " ", "+", " ", (_shop === null || _shop === void 0 ? void 0 : _shop.shippingUkGross) ? React.createElement(Money, {
      money: _shop.shippingUkGross
    }) : _shop.id === "VXNob3A6MQ==" ? "Free" : "?", " ", "=", " ", React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: Number(shopTotal) + ((_shop === null || _shop === void 0 ? void 0 : _shop.shippingUkGross) ? _shop === null || _shop === void 0 ? void 0 : _shop.shippingUkGross.amount : 0),
        currency: "GBP"
      })
    }))), React.createElement(React.Fragment, null, React.createElement("ul", {
      className: "cart__list"
    }, _shop.lines.map(function (line, index) {
      var _line$variant$product;

      var productUrl = generateProductUrl(line.variant.product.id, line.variant.product.name);
      var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);

      if (!priceFormat) {
        priceFormat = line.variant.pricing.price.gross;
      }

      return React.createElement("li", {
        key: key,
        className: "cart__list__item"
      }, React.createElement(Link, {
        to: productUrl,
        target: "_blank"
      }, React.createElement(S.ProductImage, {
        imageUrl: (_line$variant$product = line.variant.product.thumbnail) === null || _line$variant$product === void 0 ? void 0 : _line$variant$product.url
      })), React.createElement("div", {
        className: "cart__list__item__details"
      }, React.createElement("p", null, React.createElement(TaxedMoney, {
        taxedMoney: line.variant.pricing.price
      })), React.createElement(Link, {
        to: productUrl,
        target: "_blank"
      }, React.createElement("p", null, line.variant.product.name)), React.createElement("span", {
        className: "cart__list__item__details__variant"
      }, React.createElement("span", null, line.variant.name), React.createElement("span", null, "\u0422\u043E\u043E: ".concat(line.quantity)))));
    }))));
  }));
};

export default DraftOrder;