import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { maybe } from "@utils/misc";
import { PlaceholderImage } from "@components/atoms";
import { getAvatarImage, getAvatarImage2x } from "@temp/views/Product/utils";
import { CachedImage } from "..";
export var Thumbnail = function Thumbnail(_ref) {
  var source = _ref.source,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["source", "children"]);

  var thumbnail = source.thumbnail;
  var thumb_url = getAvatarImage(source);
  var thumb_url2x = getAvatarImage2x(source);

  if (!thumb_url && !thumb_url2x) {
    return React.createElement(PlaceholderImage, null);
  }

  return React.createElement(CachedImage, _extends({}, props, {
    url: maybe(function () {
      return thumb_url;
    }),
    url2x: maybe(function () {
      return thumb_url2x;
    }),
    alt: maybe(function () {
      return thumbnail.alt ? thumbnail.alt : "";
    }, "")
  }), children);
};