import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { PackageUstatus } from "gqlTypes/globalTypes";
import React from "react";
import { Money } from "@components/containers";
import RenderLineTr from "./RenderLineTr";

var RenderGroupByPackage = function RenderGroupByPackage(props) {
  var lines = props.lines,
      gkey = props.gkey,
      refetchOrder = props.refetchOrder,
      setRefetchChat = props.setRefetchChat;
  var packages = [];
  lines.forEach(function (line) {
    var tmp = packages.find(function (p) {
      var _line$package;

      return p.id === ((_line$package = line.package) === null || _line$package === void 0 ? void 0 : _line$package.id);
    });

    if (tmp) {
      tmp.lines.push(line);
    } else {
      packages.push(_objectSpread({}, line.package, {
        lines: [line]
      }));
    }
  });
  return React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("td", null, "#"), React.createElement("td", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u0443\u0443\u0434"), React.createElement("td", null, "\u04AE\u043D\u044D"), React.createElement("td", null, "\u0422\u043E\u043E"), React.createElement("td", null, "\u041D\u0438\u0439\u0442 \u04AF\u043D\u044D"))), React.createElement("tbody", null, packages.map(function (p) {
    var _p$gaduur, _p$gaduur2, _p$lines$, _p$gaduur3;

    return React.createElement(React.Fragment, {
      key: "package-group-".concat(p.id)
    }, React.createElement("tr", {
      className: "ushop-group"
    }, Object.keys(PackageUstatus).find(function (key) {
      return PackageUstatus[key] === p.ustatus;
    }) ? React.createElement("td", {
      colSpan: 3
    }, React.createElement("div", {
      className: "dateRow"
    }, React.createElement("span", null, "\u0418\u043B\u0433\u044D\u044D\u043C\u0436 #", p.name), p.ustatus === PackageUstatus.SHIPPING ? React.createElement("span", null, React.createElement("span", {
      style: {
        fontWeight: "normal"
      }
    }, "\u041C\u043E\u043D\u0433\u043E\u043B\u0434 \u0438\u0440\u044D\u0445 \u043E\u0433\u043D\u043E\u043E"), React.createElement("br", null), (_p$gaduur = p.gaduur) === null || _p$gaduur === void 0 ? void 0 : _p$gaduur.endDate) : React.createElement("span", null, React.createElement("span", {
      style: {
        fontWeight: "normal"
      }
    }, "\u041C\u043E\u043D\u0433\u043E\u043B\u0434 \u0438\u0440\u0441\u044D\u043D \u043E\u0433\u043D\u043E\u043E"), React.createElement("br", null), (_p$gaduur2 = p.gaduur) === null || _p$gaduur2 === void 0 ? void 0 : _p$gaduur2.receivedDate))) : React.createElement("td", {
      colSpan: 3
    }, React.createElement("span", null, React.createElement("span", {
      style: {
        fontWeight: "normal"
      }
    }, "\u0418\u0440\u0441\u044D\u043D \u043E\u0433\u043D\u043E\u043E"), React.createElement("br", null), " ", (_p$lines$ = p.lines[0]) === null || _p$lines$ === void 0 ? void 0 : _p$lines$.soonDate)), React.createElement("td", null, p.lines.reduce(function (p, c) {
      return p + c.quantity;
    }, 0)), React.createElement("td", null, React.createElement(Money, {
      money: {
        currency: p.lines[0].orderLine.unitPrice.gross.currency || "GBP",
        amount: p.lines.reduce(function (p, c) {
          return p + c.quantity * c.orderLine.unitPrice.gross.amount;
        }, 0)
      }
    }))), (p.ustatus === PackageUstatus.SHIPPING || p.ustatus === PackageUstatus.INMONGOLIA) && React.createElement("tr", null, React.createElement("td", {
      colSpan: 5
    }, React.createElement("div", {
      className: "package-details"
    }, React.createElement("div", {
      className: "package-details__errorSymbol"
    }, "!"), React.createElement("div", {
      style: {
        width: "100%"
      }
    }, React.createElement("h6", {
      className: "package-details__title"
    }, "\u0418\u043B\u0433\u044D\u044D\u043C\u0436\u0438\u0439\u043D \u043C\u044D\u0434\u044D\u044D\u043B\u044D\u043B"), React.createElement("div", {
      className: "package-details__content"
    }, React.createElement("p", null, "\u0422\u044D\u044D\u0432\u044D\u0440\u043B\u044D\u043B\u0442\u0438\u0439\u043D \u0442\u04E9\u0440\u04E9\u043B"), React.createElement("p", {
      className: "subText"
    }, (p === null || p === void 0 ? void 0 : (_p$gaduur3 = p.gaduur) === null || _p$gaduur3 === void 0 ? void 0 : _p$gaduur3.shippingType) === "air" ? "Агаараар" : "Газраар"), React.createElement("p", null, "\u04E8\u043D\u0434\u04E9\u0440, \u04E8\u0440\u0433\u04E9\u043D, \u0423\u0440\u0442"), React.createElement("p", {
      className: "subText"
    }, "".concat(p === null || p === void 0 ? void 0 : p.height, " \u0441\u043C x ").concat(p === null || p === void 0 ? void 0 : p.width, " \u0441\u043C x ").concat(p === null || p === void 0 ? void 0 : p.length, " \u0441\u043C")), React.createElement("p", null, "\u0416\u0438\u043D"), React.createElement("p", {
      className: "subText"
    }, "".concat(p === null || p === void 0 ? void 0 : p.netWeight, " \u043A\u0433")), React.createElement("p", null, "\u041E\u0432\u0440\u044B\u043D \u0436\u0438\u043D"), React.createElement("p", {
      className: "subText"
    }, "".concat(p === null || p === void 0 ? void 0 : p.grossWeight, " \u043A\u0433"))))))), p.lines.map(function (line, index) {
      return React.createElement(RenderLineTr, {
        line: line,
        gkey: gkey,
        key: "package-".concat(p.id, "-line-").concat(index),
        refetchOrder: refetchOrder,
        setRefetchChat: setRefetchChat
      });
    }));
  })));
};

export default RenderGroupByPackage;