import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 100%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  padding-left: calc(", "*1.2);\n  margin-bottom: calc(", "*1.5);\n  margin-top: calc(", "*1.5);\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  line-height: calc(", "*1.5);\n\n  & > input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n    height: 0;\n    width: 0;\n  }\n  & > .checkmark {\n    position: absolute;\n    border: 1px solid ", ";\n    border-radius: ", ";\n    top: 0;\n    left: 0;\n    height: calc(", "*1.5);\n    width: calc(", "*1.5);\n  }\n  & > .checkmark:after {\n    content: \"\";\n    position: absolute;\n    display: none;\n  }\n\n  & > input:checked ~ .checkmark:after {\n    display: block;\n  }\n\n  & > input:checked ~ .checkmark {\n    background-color: white;\n    border-color: ", ";\n  }\n\n  & > .checkmark:after {\n    left: 9px;\n    top: 4px;\n    width: 6px;\n    height: 12px;\n    border: solid ", ";\n    border-width: 0 2px 2px 0;\n    -webkit-transform: rotate(45deg);\n    -ms-transform: rotate(45deg);\n    transform: rotate(45deg);\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var CheckboxStyle = styled.div(_templateObject2());
export var Checkbox = styled.label(_templateObject3(), function (props) {
  return props.theme.spacing.gutter;
}, function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.colors.primaryLight;
}, function (props) {
  return props.theme.borderRadius.small;
}, function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.primary;
});
Checkbox.displayName = "S.Checkbox";
export var HelpText = styled.span(_templateObject4(), function (props) {
  return props.theme.input.labelColor;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject5());