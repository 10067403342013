import classNames from "classnames";
import * as React from "react"; // import { Link } from "react-router-dom";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";

var ProductRow = function ProductRow(_ref) {
  var _line$variant;

  var processing = _ref.processing,
      line = _ref.line,
      setModalOpen = _ref.setModalOpen,
      setLine = _ref.setLine;
  // const productUrl = generateProductUrl(
  //   line.variant.product.id,
  //   line.variant.product.name
  // );
  return React.createElement(React.Fragment, null, React.createElement("tr", {
    className: classNames({
      "cart-table-row--processing": processing
    })
  }, React.createElement("td", {
    className: "cart-table__thumbnail ",
    onClick: function onClick() {
      setModalOpen(true);
      setLine(line);
    }
  }, React.createElement("div", null, React.createElement(Thumbnail, {
    source: line
  }))), React.createElement("td", {
    onClick: function onClick() {
      setModalOpen(true);
      setLine(line);
    }
  }, line.productName, (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.attributes.map(function (_ref2, attributeIndex) {
    var attribute = _ref2.attribute,
        values = _ref2.values;
    return React.createElement("p", {
      key: attribute.id
    }, attribute.name, ": ", values.map(function (value) {
      return value.name;
    }).join(", "));
  })), React.createElement("td", {
    className: "text-right"
  }, React.createElement(TaxedMoney, {
    taxedMoney: line.unitPrice
  })), React.createElement("td", {
    className: "cart-table__quantity-cell text-right"
  }, React.createElement("p", null, line.quantity)), React.createElement("td", {
    className: "money text-right"
  }, React.createElement(TaxedMoney, {
    taxedMoney: line.totalPrice
  }))));
};

export default ProductRow;