import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import PayHistory from "./PayHistory";
import * as S from "./styles";
import WalletTransactionHistory from "./WalletTransactionHistory";
export var PaymentsHistory = function PaymentsHistory(_ref) {
  var history = _ref.history;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      active = _React$useState2[0],
      setActive = _React$useState2[1];

  return React.createElement(React.Fragment, null, React.createElement(S.Header, null, "\u0422\u04E9\u043B\u0431\u04E9\u0440 \u0442\u04E9\u043B\u04E9\u043B\u0442\u04AF\u04AF\u0434"), React.createElement(S.ButtonGroup, null, React.createElement(S.Button, {
    style: {
      opacity: active === true ? 1 : 0.3
    },
    onClick: function onClick() {
      return setActive(true);
    }
  }, "Card"), React.createElement(S.Button, {
    style: {
      opacity: active === false ? 1 : 0.3
    },
    onClick: function onClick() {
      return setActive(false);
    }
  }, "Wallet")), active ? React.createElement(PayHistory, {
    history: history
  }) : React.createElement(WalletTransactionHistory, {
    history: history
  }));
};