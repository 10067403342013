import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject20() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fff3cd;\n  border: 1px solid #ffecb5;\n  border-radius: 0.25rem;\n  color: #664d03;\n  font-size: 12px;\n  padding: 1rem;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%\n  padding: 1rem 1.5rem;\n  background-color: ", ";\n  margin-bottom: ", ";\n  & button {\n    color: blue;\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  background-color: ", ";\n  padding: 1rem 1.5rem;\n  border-top: 1px solid #ddd;\n  & b {\n    font-weight: bold;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  margin: 0.4rem 0 0 0;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  min-width: 110px;\n\n  button {\n    padding: 0.8rem 1rem;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  clear: both;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  float: left;\n  width: 48%;\n  text-align: center;\n  background-color: ", ";\n  border: 2px solid ", ";\n  \n  padding: 20px;\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n  \n  h6 { font-size: ", "; }\n  h3 { ", " }\n\n  &.right{float: right;}\n  b { font-weight: bold; }\n  &:after{\n    content: \"\u0421\u043E\u043D\u0433\u043E\u0445\";\n    position: absolute;\n    bottom: -15px;\n    left: 50%;\n    transform: translate(-50%, 0);\n    border: 1px solid #323232;\n    background-color: ", ";\n    padding: 3px 10px;\n    border-radius: 4px;\n  }\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  box-shadow: 2px 1px 5px #d9d9d9;\n  ", "\n  cursor: pointer;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: 30px;\n  margin-bottom: ", ";\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Divider = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject3(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var TitleSub = styled.h4(_templateObject4(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var DiscountField = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject6()));
export var Tile = styled.label(_templateObject7(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
});
export var TileRow = styled.label(_templateObject8(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.typography.smallFont2Size;
}, function (props) {
  return props.checked ? "color: #21125E;" : "color: #323232;";
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "\n    &:after{\n      content: \"\u0421\u043E\u043D\u0433\u043E\u0441\u043E\u043D\";\n      color: #fff;\n      border: 1px solid #21125E;\n      background-color: #21125E;\n    }\n  ";
});
export var Clearbox = styled.div(_templateObject9());
export var Input = styled.div(_templateObject10());
export var InputWithButton = styled.div(_templateObject11());
export var InputWrapper = styled.div(_templateObject12());
export var ButtonWrapper = styled.div(_templateObject13());
export var ChipsWrapper = styled.div(_templateObject14());
export var TwoColRow = styled.div(_templateObject15(), function (props) {
  return props.theme.colors.light;
});
export var WalletAmount = styled.div(_templateObject16(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var RemoveWalletAmount = styled.button(_templateObject17());
export var AlertContainer = styled.div(_templateObject18());
export var AlertTitle = styled.h6(_templateObject19());
export var AlertContent = styled.div(_templateObject20());