import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import * as S from "./styles";

/**
 * Address form used in checkout.
 */
var CheckoutAddress = function CheckoutAddress(_ref) {
  var checkoutShippingAddress = _ref.checkoutShippingAddress,
      checkoutBillingAddress = _ref.checkoutBillingAddress,
      _ref$billingAsShippin = _ref.billingAsShippingAddress,
      billingAsShippingAddress = _ref$billingAsShippin === void 0 ? false : _ref$billingAsShippin,
      email = _ref.email,
      selectedUserShippingAddressId = _ref.selectedUserShippingAddressId,
      selectedUserBillingAddressId = _ref.selectedUserBillingAddressId,
      userAddresses = _ref.userAddresses,
      countries = _ref.countries,
      userId = _ref.userId,
      shippingFormId = _ref.shippingFormId,
      shippingFormRef = _ref.shippingFormRef,
      billingFormId = _ref.billingFormId,
      billingFormRef = _ref.billingFormRef,
      shippingAddressRequired = _ref.shippingAddressRequired,
      setShippingAddress = _ref.setShippingAddress,
      setBillingAddress = _ref.setBillingAddress,
      setBillingAsShippingAddress = _ref.setBillingAsShippingAddress,
      shippingErrors = _ref.shippingErrors,
      billingErrors = _ref.billingErrors,
      newAddressFormId = _ref.newAddressFormId;
  return React.createElement(S.Wrapper, null, shippingAddressRequired && React.createElement(React.Fragment, null, React.createElement("section", null, React.createElement("div", {
    className: "ushop-title",
    style: {
      marginTop: "-16px"
    }
  }, React.createElement("h4", null, "\u0425\u04AF\u043B\u044D\u044D\u043D \u0430\u0432\u0430\u0445"), React.createElement("h3", null, "\u0425\u0430\u044F\u0433 \u0441\u043E\u043D\u0433\u043E\u0445")), false && React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), (userAddresses === null || userAddresses === void 0 ? void 0 : userAddresses.length) ? React.createElement(AddressGridSelector, {
    testingContext: "shipping",
    formId: shippingFormId,
    formRef: shippingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserShippingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: shippingErrors,
    onSelect: function onSelect(address, id) {
      return setShippingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "shippingAddressForm",
    formId: shippingFormId,
    formRef: shippingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: _objectSpread({}, checkoutShippingAddress, {
      email: email
    }),
    handleSubmit: function handleSubmit(address) {
      return setShippingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: true,
    errors: shippingErrors
  })), React.createElement(S.Divider, null)), React.createElement("section", null, React.createElement(S.AlertContainer, null, React.createElement(S.AlertTitle, null, "\u0421\u0430\u043D\u0430\u043C\u0436: "), React.createElement(S.AlertContent, null, "1. \u041D\u044D\u0433 \u0442\u04E9\u0440\u043B\u0438\u0439\u043D \u0431\u0430\u0440\u0430\u0430 \u0445\u043E\u0451\u0440\u043E\u043E\u0441 \u0434\u044D\u044D\u0448 \u0442\u043E\u043E\u0433\u043E\u043E\u0440", React.createElement("br", null), "2. \u0425\u04E9\u0434\u04E9\u043B\u043C\u04E9\u0440\u0438\u0439\u043D \u0445\u04E9\u043B\u0441\u043D\u0438\u0439 \u0434\u043E\u043E\u0434 \u0445\u044D\u043C\u0436\u044D\u044D\u0433 10 \u043D\u0443\u0433\u0430\u043B\u0441\u0430\u043D\u0430\u0430\u0441 \u0434\u044D\u044D\u0448 \u04AF\u043D\u0438\u0439\u043D \u0434\u04AF\u043D\u0442\u044D\u0439 \u0431\u0430\u0440\u0430\u0430 \u0437\u0430\u0445\u0438\u0430\u043B\u0441\u0430\u043D \u0442\u043E\u0445\u0438\u043E\u043B\u0434\u043E\u043B\u0434 \u041C\u043E\u043D\u0433\u043E\u043B \u0443\u043B\u0441\u044B\u043D \u0433\u0430\u0430\u043B\u0438\u0439\u043D \u0442\u0430\u0442\u0432\u0430\u0440 \u0442\u04E9\u043B\u04E9\u0445\u0438\u0439\u0433 \u0430\u043D\u0445\u0430\u0430\u0440\u043D\u0430 \u0443\u0443"))), React.createElement("section", {
    style: {
      display: "none"
    }
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.billingAddress)), shippingAddressRequired && React.createElement(Checkbox, {
    "data-test": "checkoutAddressBillingAsShippingCheckbox",
    name: "billing-same-as-shipping",
    checked: billingAsShippingAddress,
    onChange: function onChange() {
      return setBillingAsShippingAddress(!billingAsShippingAddress);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutAddress.CheckoutAddress.1573464320",
    defaultMessage: "\u0425\u04AF\u0440\u0433\u044D\u043B\u0442\u0438\u0439\u043D \u0445\u0430\u044F\u0433\u0442\u0430\u0439 \u0438\u0436\u0438\u043B"
  })), !billingAsShippingAddress && React.createElement(React.Fragment, null, shippingAddressRequired && React.createElement(S.Divider, null), userAddresses ? React.createElement(AddressGridSelector, {
    testingContext: "billing",
    formId: billingFormId,
    formRef: billingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserBillingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: billingErrors,
    onSelect: function onSelect(address, id) {
      return setBillingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "billingAddressForm",
    formId: billingFormId,
    formRef: billingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: checkoutBillingAddress || undefined,
    handleSubmit: function handleSubmit(address) {
      return setBillingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: !shippingAddressRequired,
    errors: billingErrors
  }))));
};

export { CheckoutAddress };