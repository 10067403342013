import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "../scss/order-progress.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { DropdownMenu, IconButton } from "@components/atoms";
import { orderHistoryUrl } from "@temp/app/routes";
import Accordion from "./components/Accordion";
import { OrderInformation } from "./components/OrderInformation";
import QpayModal from "./components/QpayModal"; // import { translateOrderStatus, translatePaymentStatus } from "@temp/intl";
// import { maybe } from "@temp/misc";

import { orderToProgress } from "./utils";

var OrderProgress = function OrderProgress(props) {
  var _order$invoices;

  var guest = props.guest,
      order = props.order,
      refetchOrder = props.refetchOrder,
      downloadInvoice = props.downloadInvoice; // const intl = useIntl();
  // const [modalOpen, setModalOpen] = React.useState(false);
  // const [line, setLine] = React.useState<any>();
  // const onCompletedPayment = () => {
  //   refetchOrder();
  // };

  var _orderToProgress = orderToProgress(order),
      progressData = _orderToProgress.progressData,
      current_ustatus = _orderToProgress.current_ustatus;

  var _React$useState = React.useState(current_ustatus),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      expanded = _React$useState2[0],
      setExpanded = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      refetchChat = _React$useState4[0],
      setRefetchChat = _React$useState4[1];

  var sorted_payments = (order === null || order === void 0 ? void 0 : order.payments.reverse()) || [];
  var qpay_payment = sorted_payments.find(function (p) {
    return p.isActive === true && p.gateway === "unurshop.payments.qpay" && p.chargeStatus === "NOT_CHARGED";
  });
  return React.createElement(React.Fragment, null, !guest && React.createElement(Link, {
    className: "order-details__link",
    to: orderHistoryUrl
  }, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.OrderProgress.index.3924829732",
    defaultMessage: "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0442\u04AF\u04AF\u0445 \u0440\u0443\u0443 \u043E\u0447\u0438\u0445"
  })), React.createElement("div", {
    className: "order-details__header"
  }, React.createElement("div", {
    className: ""
  }, React.createElement("h5", null, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.OrderProgress.index.452056028",
    defaultMessage: "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0434\u0443\u0433\u0430\u0430\u0440: #{orderNum}",
    values: {
      orderNum: order.number
    }
  }))), "invoices" in order && ((_order$invoices = order.invoices) === null || _order$invoices === void 0 ? void 0 : _order$invoices.length) > 0 && React.createElement("div", {
    className: "order-details__header-menu"
  }, React.createElement(DropdownMenu, {
    type: "clickable",
    header: React.createElement(IconButton, {
      testingContext: "expandButton",
      name: "expand",
      size: 28
    }),
    items: [{
      onClick: downloadInvoice,
      content: React.createElement("span", null, React.createElement(FormattedMessage, {
        id: "userAccount.views.OrderDetails.OrderProgress.index.2670827143",
        defaultMessage: "Download invoice",
        description: "action in popup menu in order view"
      }))
    }]
  }))), qpay_payment && order.totalBalance.amount < 0 && React.createElement(QpayModal, {
    payment: qpay_payment,
    orderNumber: order.number,
    totalAmount: order.totalBalance.amount,
    refetchOrder: refetchOrder
  }) // <div style={{ marginTop: 20, marginBottom: 20 }}>
  //   <QPayRender
  //     payment={qpay_payment}
  //     orderNumber={order.number}
  //     totalAmount={order.totalBalance.amount}
  //     refetchOrder={refetchOrder}
  //   />
  // </div>
  , React.createElement("div", {
    className: "op-container"
  }, React.createElement("div", {
    className: "op-accordion-container"
  }, React.createElement("div", {
    className: "op-process"
  }, React.createElement("h2", null, React.createElement("div", {
    className: "process-title"
  }, React.createElement("span", null, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u044F\u0432\u0446"), (order === null || order === void 0 ? void 0 : order.created) && React.createElement("span", {
    className: "process-title_date"
  }, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u0430 \u04E9\u0433\u0441\u04E9\u043D \u043E\u0433\u043D\u043E\u043E:\xA0", React.createElement(Moment, {
    date: order.created,
    format: "DD/MM/YY HH:mm",
    locale: "mn"
  }))), React.createElement("div", {
    className: "op-process__progress"
  }, React.createElement("span", null, (order === null || order === void 0 ? void 0 : order.taskTotal) > 0 ? (order.taskDone / order.taskTotal * 100).toFixed(0) : 0, "%"), React.createElement("div", {
    className: "op-process__progress__bar"
  }, React.createElement("span", {
    className: "op-process__progress__percent",
    style: {
      width: "".concat((order.taskDone / order.taskTotal * 100).toFixed(0), "%")
    }
  }))))), progressData.map(function (item) {
    return React.createElement(Accordion, {
      item: item,
      key: "progress-data-".concat(item.ustatus),
      expanded: expanded === item.ustatus,
      setExpanded: setExpanded,
      refetchOrder: refetchOrder,
      setRefetchChat: setRefetchChat
    });
  })), React.createElement("div", {
    className: "op-information"
  }, React.createElement(OrderInformation, {
    guest: guest,
    order: order,
    refetchOrder: refetchOrder,
    refetchChat: refetchChat,
    setRefetchChat: setRefetchChat
  }))));
};

export default OrderProgress;