// import { TypedFeaturedProductsQuery } from "./queries";
import "./scss/index.scss";
import * as React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import { Carousel } from "..";
import * as appPaths from "../../app/routes";
import { mediumScreen, smallScreen } from "../../globalStyles/scss/variables.scss"; // import pandoraLogo from "../../images/unurshop/xd/pandora.jpg";
// import timberlandLogo from "../../images/unurshop/xd/timberland.jpg";
// import zaraLogo from "../../images/unurshop/xd/zara.jpg";
// import { generateProductUrl } from '../../core/utils';

var ProductsFeatured = function ProductsFeatured(_ref) {
  var title = _ref.title;
  //    (
  //     <TypedFeaturedProductsQuery displayError={false}>
  //       {({ data }) => {
  //         const products = maybe(
  //           () => data.shop.homepageCollection.products.edges,
  //           []
  //         );
  //         if (products.length) {
  //           return ;
  //         } else {
  //           return null;
  //         }
  //       }}
  //     </TypedFeaturedProductsQuery>
  //   )
  var ushops = [// {
    //   id: 1,
    //   logo: timberlandLogo,
    //   name: "Timberland",
    // },
    // {
    //   id: 2,
    //   logo: zaraLogo,
    //   name: "Zara",
    // },
    // {
    //   id: 3,
    //   logo: pandoraLogo,
    //   name: "Pandora",
    // },
    // {
    //   id: 4,
    //   logo: timberlandLogo,
    //   name: "Timberland",
    // },
    // {
    //   id: 5,
    //   logo: zaraLogo,
    //   name: "Zara",
    // },
    // {
    //   id: 6,
    //   logo: pandoraLogo,
    //   name: "Pandora",
    // },
  ];
  var items = ushops.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        logo = _ref2.logo;
    return React.createElement(Link, {
      to: appPaths.baseUrl,
      key: id
    }, React.createElement("img", {
      src: logo,
      alt: name
    }));
  });
  return React.createElement("div", {
    className: "products-featured"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h4", {
    className: "t-c"
  }, "\u0414\u044D\u043B\u0445\u0438\u0439\u043D \u0448\u0438\u043B\u0434\u044D\u0433 \u0431\u0440\u044D\u043D\u0434\u04AF\u04AF\u0434"), React.createElement("h3", {
    className: "t-c m-b3"
  }, "\u0410\u043D\u0433\u043B\u0438 \u0434\u0430\u0445\u044C \u0434\u044D\u043B\u0433\u04AF\u04AF\u0440"), React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement(Carousel, {
      slidesToShow: 2.75
    }, items) : React.createElement(Media, {
      query: {
        maxWidth: mediumScreen
      }
    }, function (matches) {
      return React.createElement(Carousel, {
        slidesToShow: matches ? 4 : 6
      }, items);
    });
  })));
};

ProductsFeatured.defaultProps = {
  title: "Featured"
};
export default ProductsFeatured;