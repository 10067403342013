import * as React from "react";
import { Money } from "@components/containers";

var CostRow = function CostRow(_ref) {
  var heading = _ref.heading,
      cost = _ref.cost;
  return React.createElement("tr", null, React.createElement("td", {
    colSpan: 4
  }, heading), React.createElement("td", {
    className: "text-right money"
  }, cost ? React.createElement(Money, {
    money: cost
  }) : "0"));
};

export default CostRow;