import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { FulfillmentLineHistoryUstatus, FulfillmentLineUstatus } from "@temp/../gqlTypes/globalTypes";
import { PRODUCT_TYPE_SHIPPING } from "@temp/constants";
export function orderToProgress(order) {
  var all_olines = [];
  var all_flines = [];
  order.lines.filter(function (line) {
    var _line$variant, _line$variant$product, _line$variant$product2;

    return (line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : (_line$variant$product = _line$variant.product) === null || _line$variant$product === void 0 ? void 0 : (_line$variant$product2 = _line$variant$product.productType) === null || _line$variant$product2 === void 0 ? void 0 : _line$variant$product2.id) !== PRODUCT_TYPE_SHIPPING;
  }).forEach(function (l) {
    all_olines.push({
      __typename: "FulfillmentLine",
      id: l.id,
      quantity: l.quantity,
      ustatus: FulfillmentLineUstatus.NEW,
      changedDate: null,
      soonDate: null,
      package: null,
      ushop: l.variant.product.ushop,
      orderLine: l,
      lineHistories: null
    });
  });
  order.fulfillments.forEach(function (fulfillment) {
    all_flines.push.apply(all_flines, _toConsumableArray(fulfillment.lines.filter(function (line) {
      var _line$orderLine, _line$orderLine$varia, _line$orderLine$varia2, _line$orderLine$varia3;

      return (line === null || line === void 0 ? void 0 : (_line$orderLine = line.orderLine) === null || _line$orderLine === void 0 ? void 0 : (_line$orderLine$varia = _line$orderLine.variant) === null || _line$orderLine$varia === void 0 ? void 0 : (_line$orderLine$varia2 = _line$orderLine$varia.product) === null || _line$orderLine$varia2 === void 0 ? void 0 : (_line$orderLine$varia3 = _line$orderLine$varia2.productType) === null || _line$orderLine$varia3 === void 0 ? void 0 : _line$orderLine$varia3.id) !== PRODUCT_TYPE_SHIPPING;
    })));
  });
  var renderUstatus = [{
    ustatus: FulfillmentLineHistoryUstatus.NEW,
    groupBy: "USHOP",
    title: "Захиалсан бараа",
    groupsElbeg: [{
      color: "error",
      label: "Асуудалтай",
      lines: all_olines.filter(function (line) {
        return (line.orderLine.issues.edges.filter(function (t) {
          return t.node.status === "TODO" && t.node.type === "ISSUE";
        }) || []).length > 0;
      }),
      key: "buyed"
    }, {
      color: "pending",
      label: "Шалгагдаж байгаа",
      lines: all_olines.filter(function (l) {
        return l.orderLine.quantityFulfilled < l.orderLine.quantity;
      }).filter(function (line) {
        return line.orderLine.issues.edges.filter(function (t) {
          return t.node.status === "TODO" && t.node.type === "ISSUE";
        }).length === 0 && line.quantity > 0;
      }),
      key: "checking"
    }, {
      color: "success",
      label: "Худалдаж авсан",
      lines: all_flines.filter(function (line) {
        return line.ustatus === "NEW";
      }).filter(function (line) {
        return line.orderLine.issues.edges.filter(function (i) {
          return i.node.status === "TODO" && i.node.type === "ISSUE";
        }).length === 0;
      }),
      key: "confirmed"
    }, {
      color: "error",
      label: "Дууссан / Цуцалсан",
      lines: all_olines.filter(function (l) {
        return l.orderLine.quantityCanceled > 0;
      }),
      key: "cancelled"
    }]
  }, {
    ustatus: FulfillmentLineHistoryUstatus.ATUK,
    groupBy: "PACKAGE",
    title: "Тээврийн мэдээлэл",
    groupsElbeg: [{
      color: "pending",
      label: "Англи дахь салбар дээр ирсэн",
      lines: all_flines.filter(function (line) {
        return line.ustatus === FulfillmentLineUstatus.ATUK;
      }),
      key: "atuk"
    }, {
      color: "success",
      label: "Илгээмжид орсон",
      lines: all_flines.filter(function (line) {
        return line.ustatus === FulfillmentLineUstatus.SHIPPING;
      }),
      key: "shipping"
    }]
  }, {
    ustatus: FulfillmentLineHistoryUstatus.INMONGOLIA,
    groupBy: "PACKAGE",
    title: "Монголд ирсэн",
    groupsElbeg: [{
      color: "error",
      label: "Гааль дээр саатсан",
      lines: all_flines.filter(function (line) {
        return line.ustatus === FulfillmentLineUstatus.DELAYED;
      }),
      key: "delayed"
    }, {
      color: "pending",
      label: "Хүлээн авахад бэлэн",
      lines: all_flines.filter(function (line) {
        return line.ustatus === FulfillmentLineUstatus.INMONGOLIA;
      }),
      key: "inmongolia"
    }, {
      color: "success",
      label: "Хүлээн авсан",
      lines: all_flines.filter(function (line) {
        return line.ustatus === FulfillmentLineUstatus.RECEIVED;
      }),
      key: "received"
    }]
  }];
  var progressData = [].concat(renderUstatus);
  var current_ustatus = FulfillmentLineHistoryUstatus.NEW; // eslint-disable-next-line no-restricted-syntax

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = progressData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var item = _step.value;

      if (item.groupsElbeg.find(function (i) {
        return i.lines.length > 0;
      })) {
        current_ustatus = item.ustatus;
      }
    } // const current_ustatus = progressData.reduce(
    //   (p, c) => (c.doneLines.length > 0 ? c.ustatus : p),
    //   FulfillmentLineHistoryUstatus.NEW
    // );

  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return {
    progressData: progressData,
    all_flines: all_flines,
    current_ustatus: current_ustatus
  };
}
export function doneFLinesUstatus(lines, ustatus) {
  return lines.filter(function (line) {
    return line.lineHistories.edges.map(function (e) {
      return e.node.ustatus;
    }).includes(ustatus);
  });
}