import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import { useAccountUpdate } from "unurshop-sdk";
import { ModalSafari as Modal } from "@components/organisms/ModalSafari";
import { AccountUpdateForm } from "./AccountUpdateForm";

var UserPhoneUpdateModal = function UserPhoneUpdateModal(_ref) {
  var user = _ref.user;

  var _useAccountUpdate = useAccountUpdate(),
      _useAccountUpdate2 = _slicedToArray(_useAccountUpdate, 1),
      setAccountUpdate = _useAccountUpdate2[0];

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      openModal = _React$useState2[0],
      setOpenModal = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      setClosed = _React$useState4[1];

  var _hide = function hide() {
    setOpenModal(false);
    setClosed(false);
  };

  React.useEffect(function () {
    setClosed(true);
  }, []);
  return React.createElement(Modal, {
    submitButtonTestingContext: "submitAddressFormModalButton",
    testingContext: "",
    title: "\u0422\u0430 \u0443\u0442\u0430\u0441\u043D\u044B \u0434\u0443\u0433\u0430\u0430\u0440\u0430\u0430 \u043E\u0440\u0443\u0443\u043B\u043D\u0430 \u0443\u0443!",
    hide: function hide() {
      _hide();
    },
    formId: "formid",
    disabled: false,
    show: openModal // submitBtnText="Хадгалах"
    ,
    onSubmit: _hide,
    position: "center-w-max-400"
  }, React.createElement(AccountUpdateForm, {
    initialValues: {
      firstName: user && user.firstName || "",
      lastName: user && user.lastName || "",
      phone: user && user.phone || ""
    },
    handleSubmit: function handleSubmit(data) {
      setAccountUpdate({
        input: data
      });
    },
    hide: function hide() {
      setOpenModal(false);
    }
  }));
};

export default UserPhoneUpdateModal;