import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { Container } from "../Container";
/**
 * Template for empty cart page.
 */

var CartEmpty = function CartEmpty(_ref) {
  var button = _ref.button;
  return React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement(S.TitleFirstLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.3536902689",
    defaultMessage: "\u0422\u0430\u043D\u044B \u0441\u0430\u0433\u0441"
  })), React.createElement(S.TitleSecondLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.3366738986",
    defaultMessage: "\u0445\u043E\u043E\u0441\u043E\u043D \u0431\u0430\u0439\u043D\u0430"
  })), React.createElement(S.HR, null), React.createElement(S.Subtitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.3549636896",
    defaultMessage: "\u041C\u0430\u0433\u0430\u0434\u0433\u04AF\u0439 \u0442\u0430 \u0445\u0430\u0440\u0430\u0430\u0445\u0430\u043D \u0441\u043E\u043D\u0433\u043E\u043B\u0442\u043E\u043E \u0445\u0438\u0439\u0433\u044D\u044D\u0433\u04AF\u0439 \u0431\u0430\u0439\u0433\u0430\u0430 \u0431\u0430\u0439\u0445"
  })), React.createElement(S.ContinueButton, null, button)));
};

export { CartEmpty };