import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query PaymentHistories($first: Int, $after: String, $gte: Date, $lte: Date) {\n    me {\n      id\n      payments(\n        first: $first\n        after: $after\n        filter: { created: { gte: $gte, lte: $lte } }\n      ) {\n        edges {\n          node {\n            id\n            gateway\n            modified\n            chargeStatus\n            capturedAmount {\n              localized\n            }\n            order {\n              number\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query WalletTransactionHistories(\n    $first: Int\n    $after: String\n    $gte: Date\n    $lte: Date\n  ) {\n    me {\n      id\n      walletTransactions(\n        first: $first\n        after: $after\n        filter: { createdAt: { gte: $gte, lte: $lte } }\n      ) {\n        edges {\n          node {\n            description\n            createdAt\n            credit\n            debit\n            balanceAfter\n            id\n            status\n            order {\n              id\n              number\n            }\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
export var walletTransactionHistoriesByUser = gql(_templateObject());
export var TypedWalletTransactionHistoriesByUser = TypedQuery(walletTransactionHistoriesByUser);
export var paymentHistoriesByUser = gql(_templateObject2());
export var TypedPaymentHistoriesByUser = TypedQuery(paymentHistoriesByUser);