import React from "react";
import { ErrorMessage, Radio } from "@components/atoms";
import { Money } from "@components/containers";
import { MNT } from "@temp/constants";
import { PROVIDERS } from "@temp/core/config";
import { AdyenPaymentGateway, BraintreePaymentGateway, DummyPaymentGateway, QpayPaymentGateway, StripePaymentGateway } from "..";
import * as S from "./styles";

/**
 * Payment Gateways list
 */
var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var amount = _ref.amount,
      paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      _processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      errors = _ref.errors,
      onError = _ref.onError,
      handleQpaySubmit = _ref.handleQpaySubmit;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.BRAINTREE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBraintreeInput",
          name: "payment-method",
          value: "credit-card",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBraintreeName"
        }, name))), checked && React.createElement(BraintreePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.DUMMY.label:
        // eslint-disable-next-line no-constant-condition
        return false ? React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, name, " ", id))), checked && React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        })) : null;

      case PROVIDERS.STRIPE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayStripeInput",
          name: "payment-method",
          value: "stripe",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayStripeName",
          style: {
            display: "inline-block",
            width: "100%"
          }
        }, React.createElement("b", {
          style: {
            fontWeight: "bold",
            float: "right"
          }
        }, React.createElement(Money, {
          money: {
            amount: amount,
            currency: "GBP"
          }
        })), React.createElement("b", {
          style: {
            fontWeight: "bold"
          }
        }, name), React.createElement("small", {
          style: {
            display: "block",
            fontSize: 12
          }
        }, "\u041E\u043B\u043E\u043D \u0443\u043B\u0441\u044B\u043D \u0442\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u043A\u0430\u0440\u0442\u0430\u0430\u0440")))), checked && React.createElement(StripePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.ADYEN.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayAdyenInput",
          name: "payment-method",
          value: "adyen",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayAdyenName"
        }, name))), checked && React.createElement(AdyenPaymentGateway, {
          config: config,
          formRef: formRef,
          scriptConfig: PROVIDERS.ADYEN.script,
          styleConfig: PROVIDERS.ADYEN.style,
          processPayment: function processPayment() {
            return _processPayment(id);
          },
          submitPayment: submitPayment,
          submitPaymentSuccess: submitPaymentSuccess,
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.QPAY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayAdyenInput",
          name: "payment-method",
          value: "adyen",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayAdyenName",
          style: {
            display: "inline-block",
            width: "100%"
          }
        }, React.createElement("b", {
          style: {
            fontWeight: "bold",
            float: "right"
          }
        }, Math.ceil(amount * MNT).toLocaleString(), "\u20AE"), React.createElement("b", {
          style: {
            fontWeight: "bold"
          }
        }, name), React.createElement("small", {
          style: {
            display: "block",
            fontSize: 12
          }
        }, "\xA31 = ", MNT, "\u20AE \u0431\u043E\u0434\u043E\u0436 \u0442\u04E9\u043B\u0431\u04E9\u0440 \u0442\u04E9\u043B\u04E9\u0445")))), checked && React.createElement(QpayPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return handleQpaySubmit(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };