import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
// import { CartRow } from "@components/organisms";
import { Money } from "@components/containers";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";

var CheckoutUkShippingSubpage = function CheckoutUkShippingSubpage(_ref) {
  var lines = _ref.lines,
      removeItem = _ref.removeItem,
      addItem = _ref.addItem;

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      _ushops = _useState2[0],
      setUshops = _useState2[1];

  useEffect(function () {
    var ushops = [];
    var removeIds = [];
    var variants = lines === null || lines === void 0 ? void 0 : lines.map(function (tmp) {
      return tmp.variant;
    });
    var productVariants = variants === null || variants === void 0 ? void 0 : variants.filter(function (tmp) {
      var _tmp$product;

      return (tmp === null || tmp === void 0 ? void 0 : (_tmp$product = tmp.product) === null || _tmp$product === void 0 ? void 0 : _tmp$product.productType.id) !== PRODUCT_TYPE_SHIPPING;
    });
    var shippingVariants = variants === null || variants === void 0 ? void 0 : variants.filter(function (tmp) {
      var _tmp$product2;

      return (tmp === null || tmp === void 0 ? void 0 : (_tmp$product2 = tmp.product) === null || _tmp$product2 === void 0 ? void 0 : _tmp$product2.productType.id) === PRODUCT_TYPE_SHIPPING;
    });
    /** productuudiig delguurt hargalzuulj bga hesge */

    productVariants === null || productVariants === void 0 ? void 0 : productVariants.map(function (variant) {
      var shop = ushops === null || ushops === void 0 ? void 0 : ushops.find(function (el) {
        var _variant$product, _variant$product$usho;

        return el.id === (variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : (_variant$product$usho = _variant$product.ushop) === null || _variant$product$usho === void 0 ? void 0 : _variant$product$usho.id);
      });
      var line = lines === null || lines === void 0 ? void 0 : lines.find(function (el) {
        return el.variant.id === variant.id;
      }); // if (variant.product.metadata) {
      //   line.variant.product["metadata"] = variant.product.metadata;
      // }

      if (!shop) {
        var _variant$product2;

        if (variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.ushop) {
          var _variant$product3;

          ushops.push(_objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : _variant$product3.ushop, {
            lines: [line]
          }));
        }
      } else {
        shop.lines.push(line);
      }
    });
    /** remove hiih shaardlagtai id bwal olj bga  */

    shippingVariants === null || shippingVariants === void 0 ? void 0 : shippingVariants.map(function (el) {
      var ushop = ushops === null || ushops === void 0 ? void 0 : ushops.find(function (shop) {
        var _el$product, _el$product$ushop;

        return shop.id === (el === null || el === void 0 ? void 0 : (_el$product = el.product) === null || _el$product === void 0 ? void 0 : (_el$product$ushop = _el$product.ushop) === null || _el$product$ushop === void 0 ? void 0 : _el$product$ushop.id);
      });

      if (ushop) {
        ushop.shippingVariantId = el.id;
      } else {
        removeIds.push(el.id);
      }
    });
    var addIds = [];
    ushops.filter(function (ushop) {
      var _ushop$shippingProduc;

      return ushop.shippingVariantId === undefined && ushop.shippingProduct !== null && ((_ushop$shippingProduc = ushop.shippingProduct) === null || _ushop$shippingProduc === void 0 ? void 0 : _ushop$shippingProduc.variants.length) > 0;
    }).map(function (ushop) {
      var _ushop$shippingProduc2;

      var max_variant = (_ushop$shippingProduc2 = ushop.shippingProduct) === null || _ushop$shippingProduc2 === void 0 ? void 0 : _ushop$shippingProduc2.variants.reduce(function (p, v) {
        return p.pricing.price.gross.amount > v.pricing.price.gross.amount ? p : v;
      });
      addIds.push(max_variant.id);
    }); // addIds.map(id => {
    //   addItem(id, 1);
    // });
    // removeIds.map(id => {
    //   removeItem(id);
    // });

    setUshops(ushops);
  }, [lines]);

  var changeUkShipping = function changeUkShipping(addId, removeId) {
    addItem(addId, 1);
    removeItem(removeId);
  };

  return React.createElement(React.Fragment, null, _ushops.map(function (_shop, index) {
    var _shop$shippingProduct, _shop$shippingProduct2, _shop$logoImage, _shop$shippingProduct3, _selectedShipping$pri;

    var shopTotal = _shop.lines.map(function (line) {
      var _line$totalPrice;

      return (_line$totalPrice = line.totalPrice) === null || _line$totalPrice === void 0 ? void 0 : _line$totalPrice.gross.amount;
    }).reduce(function (a, c) {
      return a + c;
    });

    var selectedShipping = _shop.shippingProduct !== null ? (_shop$shippingProduct = _shop.shippingProduct) === null || _shop$shippingProduct === void 0 ? void 0 : (_shop$shippingProduct2 = _shop$shippingProduct.variants) === null || _shop$shippingProduct2 === void 0 ? void 0 : _shop$shippingProduct2.find(function (v) {
      return v.id === _shop.shippingVariantId;
    }) : null;
    return React.createElement("div", {
      key: index,
      className: "cart__shop"
    }, React.createElement("h5", {
      className: "cart__shop__title",
      style: {
        padding: "0.5rem 1rem"
      }
    }, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("img", {
      className: "cart__shop__logo",
      src: (_shop$logoImage = _shop.logoImage) === null || _shop$logoImage === void 0 ? void 0 : _shop$logoImage.url,
      alt: _shop.name
    }), React.createElement("span", null, _shop.name)), React.createElement("span", {
      className: "cart__shop__total"
    }, React.createElement(Money, {
      money: {
        amount: shopTotal,
        currency: "GBP"
      }
    }))), React.createElement("h6", {
      className: "cart__shop__title",
      style: {
        padding: "0.2rem 1rem"
      }
    }, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("span", null, "\u0410\u043D\u0433\u043B\u0438 \u0434\u043E\u0442\u043E\u043E\u0434 \u0445\u04AF\u0440\u0433\u044D\u043B\u0442")), React.createElement("span", {
      className: "cart__shop__total"
    }, selectedShipping ? React.createElement("select", {
      style: {
        fontSize: "1rem",
        padding: 5
      },
      onChange: function onChange(el) {
        changeUkShipping(el.target.value, selectedShipping.id);
      }
    }, (_shop$shippingProduct3 = _shop.shippingProduct) === null || _shop$shippingProduct3 === void 0 ? void 0 : _shop$shippingProduct3.variants.map(function (variant, index) {
      var _variant$pricing$pric;

      return React.createElement("option", {
        key: index,
        value: variant.id,
        selected: variant.id === selectedShipping.id
      }, variant.name === "Next day" ? "Дараа өдөр" : variant.name === "Regular" ? "Энгийн" : "Үнэгүй", ": \xA3", (_variant$pricing$pric = variant.pricing.price) === null || _variant$pricing$pric === void 0 ? void 0 : _variant$pricing$pric.gross.amount);
    })) : _shop.id === "VXNob3A6MQ==" ? "Free" : "-")), React.createElement("h5", {
      className: "cart__shop__title",
      style: {
        padding: "0.5rem 1rem"
      }
    }, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("span", null, "\u041D\u0438\u0439\u0442 (".concat(_shop.name, ")"))), React.createElement("span", {
      className: "cart__shop__total"
    }, React.createElement(Money, {
      money: {
        currency: "GBP",
        amount: shopTotal + (selectedShipping ? (_selectedShipping$pri = selectedShipping.pricing.price) === null || _selectedShipping$pri === void 0 ? void 0 : _selectedShipping$pri.gross.amount : 0)
      }
    }))));
  }));
};

export { CheckoutUkShippingSubpage };