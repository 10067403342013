import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react"; // import { FormattedMessage } from 'react-intl';

import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";
import { Money, TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { generateProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg"; // import CustomList from "./CustomList";
// import { TypedProductVariants } from "./queries";

var ProductList = function ProductList(_ref) {
  var lines = _ref.lines,
      add = _ref.add,
      remove = _ref.remove;
  var ptShippingId = PRODUCT_TYPE_SHIPPING;
  var ushops = [];
  var removeIds = [];
  var variants = lines.map(function (tmp) {
    return tmp.variant;
  });
  var productVariants = variants.filter(function (tmp) {
    return tmp.product.productType.id !== ptShippingId;
  });
  var shippingVariants = variants.filter(function (tmp) {
    return tmp.product.productType.id === ptShippingId;
  });
  /** productuudiig delguurt hargalzuulj bga hesge */

  productVariants.map(function (variant) {
    var shop = ushops.find(function (el) {
      return el.id === variant.product.ushop.id;
    });
    var line = lines.find(function (el) {
      return el.variant.id === variant.id;
    }); // if (variant.product.metadata) {
    //   line.variant.product["metadata"] = variant.product.metadata;
    // }

    if (!shop) {
      ushops.push(_objectSpread({}, variant.product.ushop, {
        lines: [line]
      }));
    } else {
      shop.lines.push(line);
    }
  });
  /** remove hiih shaardlagtai id bwal olj bga  */

  shippingVariants.map(function (el) {
    var ushop = ushops.find(function (shop) {
      return shop.id === el.product.ushop.id;
    });

    if (ushop) {
      ushop.shippingVariantId = el.id;
    } else {
      removeIds.push(el.id);
    }
  }); // if (add) {
  //   const addIds = [];
  //   ushops
  //     .filter(
  //       ushop =>
  //         ushop.shippingVariantId === undefined &&
  //         ushop.shippingProduct !== null &&
  //         ushop.shippingProduct.variants.length > 0
  //     )
  //     .map(ushop => {
  //       const max_variant = ushop.shippingProduct.variants.reduce((p, v) =>
  //         p.pricing.price.gross.amount > v.pricing.price.gross.amount ? p : v
  //       );
  //       addIds.push(max_variant.id);
  //     });
  //   addIds.map(id => {
  //     add(id, 1);
  //   });
  // }
  // if (remove) {
  //   removeIds.map(id => {
  //     remove(id);
  //   });
  // }
  // console.log(ushops);

  return React.createElement(React.Fragment, null, ushops.map(function (_shop, index) {
    var shopTotal = _shop.lines.map(function (line) {
      return line.totalPrice.gross.amount;
    }).reduce(function (a, c) {
      return a + c;
    });

    var priceFormat = null;
    var selectedShipping = _shop.shippingProduct !== null ? _shop.shippingProduct.variants.find(function (v) {
      return v.id === _shop.shippingVariantId;
    }) : null;
    return React.createElement("div", {
      key: index,
      className: "cart__shop"
    }, React.createElement("h5", {
      className: "cart__shop__title"
    }, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("img", {
      className: "cart__shop__logo",
      src: _shop.logoImage.url,
      alt: _shop.name
    }), React.createElement("span", null, _shop.name)), React.createElement("span", {
      className: "cart__shop__total"
    }, React.createElement(Money, {
      money: {
        amount: shopTotal,
        currency: "GBP"
      }
    }))), React.createElement(React.Fragment, null, React.createElement("ul", {
      className: "cart__list"
    }, _shop.lines.map(function (line, index) {
      var productUrl = generateProductUrl(line.variant.product.id, line.variant.product.name);
      var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);

      if (!priceFormat) {
        priceFormat = line.variant.pricing.price.gross;
      }

      return React.createElement("li", {
        key: key,
        className: "cart__list__item"
      }, React.createElement(Link, {
        to: productUrl
      }, React.createElement(Thumbnail, {
        source: line.variant.product
      })), React.createElement("div", {
        className: "cart__list__item__details"
      }, React.createElement("p", null, React.createElement(TaxedMoney, {
        taxedMoney: line.variant.pricing.price
      })), React.createElement(Link, {
        to: productUrl
      }, React.createElement("p", null, line.variant.product.name)), React.createElement("span", {
        className: "cart__list__item__details__variant"
      }, React.createElement("span", null, line.variant.name), React.createElement("span", null, "\u0422\u043E\u043E: ".concat(line.quantity))), remove && React.createElement(ReactSVG, {
        path: removeImg,
        className: "cart__list__item__details__delete-icon",
        onClick: function onClick() {
          return remove(line.variant.id);
        }
      })));
    })), React.createElement("table", {
      className: "ushop-price-table"
    }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u04AF\u043D\u044D"), React.createElement("td", null, React.createElement("b", null, React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: shopTotal
      })
    })))), selectedShipping && React.createElement("tr", null, React.createElement("td", null, "\u0410\u043D\u0433\u043B\u0438 \u0434\u043E\u0442\u043E\u043E\u0434 \u0445\u04AF\u0440\u0433\u044D\u043B\u0442"), React.createElement("td", null, selectedShipping ? React.createElement(Money, {
      money: selectedShipping.pricing.price.gross
    }) : _shop.id === "VXNob3A6MQ==" ? "Free" : "0"))), React.createElement("tfoot", null, React.createElement("tr", null, React.createElement("td", null, "\u041D\u0438\u0439\u0442 (".concat(_shop.name, ")")), React.createElement("td", null, React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: shopTotal + (selectedShipping ? selectedShipping.pricing.price.gross.amount : 0)
      })
    })))))));
  }));
};

export default ProductList;