import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  & > button {\n    margin-bottom: calc(", ");\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  & > div {\n    width: calc(50% - ", " / 2);\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  padding: ", ";\n  height: calc(100% - 3.75rem);\n  overflow: auto;\n  margin-bottom: calc(", "*4);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var RowWithTwoCells = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.fieldSpacer;
});
export var Form = styled.form(_templateObject3());
export var RowWithOneCell = styled.div(_templateObject4());
export var FormControl = styled.div(_templateObject5(), function (props) {
  return props.theme.spacing.spacer;
});