import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { CartRow } from "@components/organisms/CartRow/CartRowCantBuy";
import { TaxedMoney } from "@components/containers";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";

var GroupUshopCantBuy = function GroupUshopCantBuy(_ref) {
  var lines = _ref.lines,
      messages = _ref.messages,
      removeItem = _ref.removeItem,
      updateItem = _ref.updateItem,
      addItem = _ref.addItem;

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      _ushops = _useState2[0],
      setUshops = _useState2[1];

  useEffect(function () {
    var ushops = [];
    var removeIds = [];
    var variants = lines === null || lines === void 0 ? void 0 : lines.map(function (tmp) {
      return tmp.variant;
    });
    var productVariants = variants === null || variants === void 0 ? void 0 : variants.filter(function (tmp) {
      var _tmp$product;

      return (tmp === null || tmp === void 0 ? void 0 : (_tmp$product = tmp.product) === null || _tmp$product === void 0 ? void 0 : _tmp$product.productType.id) !== PRODUCT_TYPE_SHIPPING;
    });
    var shippingVariants = variants === null || variants === void 0 ? void 0 : variants.filter(function (tmp) {
      var _tmp$product2;

      return (tmp === null || tmp === void 0 ? void 0 : (_tmp$product2 = tmp.product) === null || _tmp$product2 === void 0 ? void 0 : _tmp$product2.productType.id) === PRODUCT_TYPE_SHIPPING;
    });
    /** productuudiig delguurt hargalzuulj bga hesge */

    productVariants === null || productVariants === void 0 ? void 0 : productVariants.map(function (variant) {
      var shop = ushops.find(function (el) {
        var _variant$product, _variant$product$usho;

        return el.id === (variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : (_variant$product$usho = _variant$product.ushop) === null || _variant$product$usho === void 0 ? void 0 : _variant$product$usho.id);
      });
      var line = lines === null || lines === void 0 ? void 0 : lines.find(function (el) {
        return el.variant.id === variant.id;
      }); // if (variant.product.metadata) {
      //   line.variant.product["metadata"] = variant.product.metadata;
      // }

      if (!shop) {
        var _variant$product2;

        ushops.push(_objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.ushop, {
          lines: [line]
        }));
      } else {
        shop.lines.push(line);
      }
    });
    /** remove hiih shaardlagtai id bwal olj bga  */

    shippingVariants === null || shippingVariants === void 0 ? void 0 : shippingVariants.map(function (el) {
      var ushop = ushops.find(function (shop) {
        var _el$product, _el$product$ushop;

        return shop.id === (el === null || el === void 0 ? void 0 : (_el$product = el.product) === null || _el$product === void 0 ? void 0 : (_el$product$ushop = _el$product.ushop) === null || _el$product$ushop === void 0 ? void 0 : _el$product$ushop.id);
      });

      if (ushop) {
        ushop.shippingVariantId = el.id;
      } else {
        removeIds.push(el.id);
      }
    });
    var addIds = [];
    ushops.filter(function (ushop) {
      var _ushop$shippingProduc;

      return ushop.shippingVariantId === undefined && ushop.shippingProduct !== null && ((_ushop$shippingProduc = ushop.shippingProduct) === null || _ushop$shippingProduc === void 0 ? void 0 : _ushop$shippingProduc.variants.length) > 0;
    }).map(function (ushop) {
      var max_variant = ushop.shippingProduct.variants.reduce(function (p, v) {
        return p.pricing.price.gross.amount > v.pricing.price.gross.amount ? p : v;
      });
      addIds.push(max_variant.id);
    }); // addIds.map(id => {
    //   addItem(id, 1);
    // });
    // removeIds.map(id => {
    //   removeItem(id);
    // });

    setUshops(ushops);
  }, [lines]);
  return React.createElement(React.Fragment, null, lines && (lines === null || lines === void 0 ? void 0 : lines.length) > 0 && React.createElement("h5", {
    className: "cart__shop__title"
  }, React.createElement("span", {
    className: "cart__shop__name"
  }, React.createElement("span", null, "\u0414\u043E\u043E\u0440\u0445 \u0431\u0430\u0440\u0430\u0430\u043D\u0443\u0443\u0434 \u0445\u0443\u0434\u0430\u043B\u0434\u0430\u043D \u0430\u0432\u0430\u0445 \u0431\u043E\u043B\u043E\u043C\u0436\u0433\u04AF\u0439 \u0431\u043E\u043B\u0441\u043E\u043D \u0431\u0430\u0439\u043D\u0430. \u0422\u0430 \u0441\u0430\u0433\u0441\u043D\u0430\u0430\u0441\u0430\u0430 \u0443\u0441\u0442\u0433\u0430\u043D\u0430 \u0443\u0443"))), _ushops.map(function (_shop, index) {
    return React.createElement("div", {
      key: index,
      className: "cart__shop"
    }, React.createElement(React.Fragment, null, React.createElement("ul", {
      className: "cart__list",
      style: {
        padding: 0
      }
    }, _shop.lines.map(function (line, index) {
      var _variant$product3, _variant$product4, _messages$find, _variant$product5, _variant$product6, _variant$product6$thu, _variant$pricing, _variant$attributes;

      var id = line.id,
          variant = line.variant,
          quantity = line.quantity,
          totalPrice = line.totalPrice;
      return React.createElement(CartRow, {
        key: id ? "id-".concat(id) : "idx-".concat(index),
        index: index,
        id: (variant === null || variant === void 0 ? void 0 : (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : _variant$product3.id) || "",
        name: (variant === null || variant === void 0 ? void 0 : (_variant$product4 = variant.product) === null || _variant$product4 === void 0 ? void 0 : _variant$product4.name) || "",
        maxQuantity: variant.quantityAvailable || quantity,
        quantity: quantity,
        onRemove: function onRemove() {
          return removeItem(variant.id);
        },
        onQuantityChange: function onQuantityChange(quantity) {
          return updateItem(variant.id, quantity);
        },
        message: messages === null || messages === void 0 ? void 0 : (_messages$find = messages.find(function (e) {
          return e.variantId === (variant === null || variant === void 0 ? void 0 : variant.id);
        })) === null || _messages$find === void 0 ? void 0 : _messages$find.message,
        thumbnail: _objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product5 = variant.product) === null || _variant$product5 === void 0 ? void 0 : _variant$product5.thumbnail, {
          alt: (variant === null || variant === void 0 ? void 0 : (_variant$product6 = variant.product) === null || _variant$product6 === void 0 ? void 0 : (_variant$product6$thu = _variant$product6.thumbnail) === null || _variant$product6$thu === void 0 ? void 0 : _variant$product6$thu.alt) || ""
        }),
        line: line,
        totalPrice: React.createElement(TaxedMoney, {
          taxedMoney: totalPrice
        }),
        unitPrice: React.createElement(TaxedMoney, {
          taxedMoney: variant === null || variant === void 0 ? void 0 : (_variant$pricing = variant.pricing) === null || _variant$pricing === void 0 ? void 0 : _variant$pricing.price
        }),
        sku: variant.sku,
        attributes: (_variant$attributes = variant.attributes) === null || _variant$attributes === void 0 ? void 0 : _variant$attributes.map(function (attribute) {
          return {
            attribute: {
              id: attribute.attribute.id,
              name: attribute.attribute.name || ""
            },
            values: attribute.values.map(function (value) {
              return {
                id: value === null || value === void 0 ? void 0 : value.id,
                name: (value === null || value === void 0 ? void 0 : value.name) || "",
                value: value === null || value === void 0 ? void 0 : value.value
              };
            })
          };
        })
      });
    }))));
  }));
};

export default GroupUshopCantBuy;