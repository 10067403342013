import { Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, ButtonLink } from "@components/atoms";
import { TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AccountUpdateForm = function AccountUpdateForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      hide = _ref.hide,
      initialValues = _ref.initialValues;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: initialValues,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      handleSubmit({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone
      });
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      onSubmit: handleSubmit,
      "data-test": "accountUpdateForm"
    }, React.createElement(S.ContentEditOneLine, null, React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "firstName",
      label: intl.formatMessage(commonMessages.firstName),
      type: "text",
      value: values.firstName,
      onBlur: handleBlur,
      onChange: handleChange
    })), React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "lastName",
      label: intl.formatMessage(commonMessages.lastName),
      type: "text",
      value: values.lastName,
      onBlur: handleBlur,
      onChange: handleChange
    })), React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "phone",
      label: intl.formatMessage(commonMessages.phone),
      type: "number",
      value: values.phone,
      onBlur: handleBlur,
      onChange: handleChange
    }))), React.createElement(S.FormButtons, null, React.createElement(ButtonLink, {
      testingContext: "cancelButton",
      type: "button",
      color: "secondary",
      onClick: hide
    }, React.createElement(FormattedMessage, commonMessages.cancel)), React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting || !isValid,
      size: "sm"
    }, React.createElement(FormattedMessage, commonMessages.save))));
  }));
};