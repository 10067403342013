import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { setTimeout } from "timers";
import { Button } from "@components/atoms";
import QPayRender from "../../QPayRender";
import { ReviewDetails } from "./ReviewDetails"; // import * as S from "./styles";

var Review = function Review(_ref) {
  var order = _ref.order,
      refetchOrder = _ref.refetchOrder,
      draftOrderCompleteWithPayment = _ref.draftOrderCompleteWithPayment,
      setCurrentStep = _ref.setCurrentStep;
  var dd = false;

  var onFocus = function onFocus() {
    if (dd) {
      setTimeout(function () {
        refetchOrder();
      }, 1000);
    }
  };

  var onBlur = function onBlur() {
    if (!dd) {
      dd = true;
    }
  };

  React.useEffect(function () {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();
    return function () {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);
  var sorted_payments = (order === null || order === void 0 ? void 0 : order.payments.reverse()) || [];
  var active_payment = sorted_payments.find(function (p) {
    return p.isActive === true && p.chargeStatus === "NOT_CHARGED";
  }); // p.gateway === "unurshop.payments.qpay" &&

  if (!active_payment) return React.createElement(React.Fragment, null, "\u0418\u0434\u044D\u0432\u0445\u0438\u0442\u044D\u0439 \u0442\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u0441\u043E\u043D\u0433\u043E\u043B\u0442 \u0431\u0430\u0439\u0445\u0433\u04AF\u0439 \u0431\u0430\u0439\u043D\u0430 \u04E9\u043C\u043D\u04E9\u0445 \u0430\u043B\u0445\u0430\u043C \u0440\u0443\u0443 \u0448\u0438\u043B\u0436\u0438\u043D\u044D \u04AF\u04AF");

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loading = _React$useState2[0],
      setLoading = _React$useState2[1];

  var getPaymentMethodDescription = function getPaymentMethodDescription(payment) {
    if ((payment === null || payment === void 0 ? void 0 : payment.gateway) === "mirumee.payments.adyen") {
      return "Adyen payments";
    }

    if (payment === null || payment === void 0 ? void 0 : payment.creditCard) {
      return "**** **** **** ".concat(payment === null || payment === void 0 ? void 0 : payment.creditCard.lastDigits);
    }

    return "";
  };

  return React.createElement("div", null, active_payment.gateway === "unurshop.payments.qpay" && React.createElement(QPayRender, {
    payment: active_payment,
    orderNumber: order === null || order === void 0 ? void 0 : order.number,
    totalAmount: order.total.gross.amount,
    refetchOrder: refetchOrder,
    setCurrentStep: setCurrentStep
  }), active_payment.gateway !== "unurshop.payments.qpay" && React.createElement(React.Fragment, null, React.createElement(ReviewDetails, {
    shippingAddress: order.shippingAddress,
    payment: active_payment,
    paymentMethodName: getPaymentMethodDescription(active_payment),
    email: order.userEmail,
    errors: [],
    totalPrice: order.total
  }), React.createElement(Button, {
    testingContext: "submit",
    type: "submit",
    size: "sm",
    disabled: loading // disabled={!selectedPaymentGateway}
    ,
    onClick: function onClick() {
      if (loading) return;
      setLoading(true);
      draftOrderCompleteWithPayment({
        variables: {
          id: order.id
        }
      });
    },
    style: {
      marginTop: "1rem"
    }
  }, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u0430 \u04E9\u0433\u04E9\u0445")));
};

export default Review;