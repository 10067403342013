export var apiUrl = process.env.API_URI;
export var sentryDsn = process.env.SENTRY_DSN;
var sampleRate = parseFloat(process.env.SENTRY_APM);
export var sentrySampleRate = isNaN(sampleRate) ? 0 : sampleRate;
export var serviceWorkerTimeout = parseInt(process.env.SERVICE_WORKER_TIMEOUT, 10) || 60 * 1000;
export var demoMode = process.env.DEMO_MODE === "true";
export var PRODUCT_TYPE_SHIPPING = "UHJvZHVjdFR5cGU6MTY=";
export var PRODUCT_TYPE_ONLINE_ZAHIALGA = "UHJvZHVjdFR5cGU6MTU=";
export var PRODUCT_CATEGORY_SHIPPING = "Q2F0ZWdvcnk6MjQ=";
export var PRODUCT_CATEGORY_ONLINE_ZAHIALGA = "Q2F0ZWdvcnk6MjM=";
export var PRODUCT_TYPE_FBLIVE = "UHJvZHVjdFR5cGU6MTk=";
export var MNT = 4500;
export var SHIPPING_STATUS = [{
  label: "Draft",
  value: "DRAFT"
}, {
  label: "Шинэ",
  value: "NEW"
}, {
  label: "Тээвэрлэж буй",
  value: "SHIPPING"
}, {
  label: "Монголд",
  value: "INMONGOLIA"
}, {
  label: "Хүлээн авсан",
  value: "RECEIVED"
}, {
  label: "Саатсан",
  value: "DELAYED"
}, {
  label: "Цуцалсан",
  value: "CANCELED"
}];