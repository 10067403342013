import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query UshopByLink($link: String!) {\n    ushopByLink(link: $link) {\n      id\n      name\n      logoImage {\n        url\n        alt\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "@temp/core/queries";
export var ushopByLink = gql(_templateObject());
export var TypedUshopByLinkQuery = TypedQuery(ushopByLink);