import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Attribute, IconButton, Tile } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { usePasswordChange } from "unurshop-sdk";
import { PasswordChangeForm } from "./PasswordChangeForm";
import * as S from "./styles";
export var PasswordTile = function PasswordTile() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isEditing = _React$useState2[0],
      setIsEditing = _React$useState2[1];

  var _usePasswordChange = usePasswordChange(),
      _usePasswordChange2 = _slicedToArray(_usePasswordChange, 2),
      setPasswordChange = _usePasswordChange2[0],
      _usePasswordChange2$ = _usePasswordChange2[1],
      data = _usePasswordChange2$.data,
      error = _usePasswordChange2$.error;

  var intl = useIntl();
  React.useEffect(function () {
    if (data && !error) {
      setIsEditing(false);
    }
  }, [data, error]);
  return React.createElement(S.TileWrapper, null, React.createElement(Tile, null, React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.PasswordTile.3445609170",
    defaultMessage: "MY PASSWORD"
  }), !isEditing && React.createElement(IconButton, {
    testingContext: "editPasswordButton",
    name: "edit",
    size: 22,
    onClick: function onClick() {
      return setIsEditing(function (isEditing) {
        return !isEditing;
      });
    }
  })), React.createElement(S.Content, null, isEditing ? React.createElement(S.ContentEdit, null, React.createElement(PasswordChangeForm, {
    handleSubmit: function handleSubmit(data) {
      setPasswordChange(data);
    },
    hide: function hide() {
      setIsEditing(false);
    },
    error: error ? error.extraInfo.userInputErrors : []
  })) : React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.password),
    attributeValue: "**************",
    testingContext: "passwordText"
  })))));
};