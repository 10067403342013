import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AddressForm } from "@components/organisms/AddressForm";
import { TypedOrderUpdateShippingAddress } from "@temp/components/CustomAddressDialog/query";
import CustomAddressSummary from "@temp/components/CustomAddressSummary";
import { ShopContext } from "@temp/components/ShopProvider/context";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { TypedUserAddressCreateMutation } from "../../query";
import * as S from "./styles";
import ShippingAddressSummary from "./ShippingAddressSummary";

var Address = function Address(_ref) {
  var _selected$country;

  var order = _ref.order,
      setCurrentStep = _ref.setCurrentStep,
      refetchOrder = _ref.refetchOrder;

  var _React$useContext = React.useContext(ShopContext),
      countries = _React$useContext.countries;

  var _React$useState = React.useState(Object),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      selected = _React$useState2[0],
      setSelected = _React$useState2[1];

  var _React$useState3 = React.useState([]),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      shippingErrors = _React$useState4[0],
      setShippingErrors = _React$useState4[1];

  var clone = JSON.parse(JSON.stringify(selected));
  delete clone.id;
  delete clone.isDefaultBillingAddress;
  delete clone.isDefaultShippingAddress;
  delete clone.__typename;
  var countryValue = (_selected$country = selected.country) === null || _selected$country === void 0 ? void 0 : _selected$country.code;
  Object.keys(clone).forEach(function (key) {
    if (key === "country") {
      clone.country = countryValue;
    }
  });

  var onCompletedOrderUpdateShipping = function onCompletedOrderUpdateShipping() {
    refetchOrder();
    setSelected({});
    setCurrentStep(1);
  };

  var orderShippingAddressUpdate = function orderShippingAddressUpdate(mutation) {
    mutation({
      variables: {
        id: order.id,
        input: {
          billingAddress: _objectSpread({}, clone),
          shippingAddress: _objectSpread({}, clone)
        }
      }
    });
  };

  var addressFormRef = React.useRef(null); // console.log(addressFormRef.current[3].value);

  var submitAddressForm = function submitAddressForm() {
    if (addressFormRef.current) {
      var _addressFormRef$curre;

      (_addressFormRef$curre = addressFormRef.current) === null || _addressFormRef$curre === void 0 ? void 0 : _addressFormRef$curre.dispatchEvent(new Event("submit", {
        cancelable: true
      }));
    }
  };

  var valsEdited = function valsEdited(vars) {
    var _vars$country;

    var clone1 = JSON.parse(JSON.stringify(vars));
    delete clone1.email;
    var countryValue1 = (_vars$country = vars.country) === null || _vars$country === void 0 ? void 0 : _vars$country.code;
    Object.keys(clone1).forEach(function (key) {
      if (key === "country") {
        clone1.country = countryValue1;
      }
    });
    return clone1;
  };

  var validateAddressForm = function validateAddressForm(vals) {
    var errors = [];
    var requiredFields = [{
      field: "phone",
      message: "Заавал оруулна уу"
    }, {
      field: "firstName",
      message: "Заавал оруулна уу"
    }, {
      field: "lastName",
      message: "Заавал оруулна уу"
    }, {
      field: "streetAddress1",
      message: "Заавал оруулна уу"
    }, {
      field: "streetAddress2",
      message: "Заавал оруулна уу"
    }, {
      field: "city",
      message: "Заавал оруулна уу"
    }, {
      field: "countryArea",
      message: "Заавал оруулна уу"
    }, {
      field: "postalCode",
      message: "Заавал оруулна уу"
    }];
    requiredFields.map(function (tmp) {
      if (!(tmp.field in vals)) {
        errors.push(tmp);
      }
    });
    setShippingErrors(errors);
    return errors;
  };

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      shippingAddressChangeButton = _React$useState6[0],
      setShippingAddressChangeButton = _React$useState6[1];

  return React.createElement("div", null, order.shippingAddress && !shippingAddressChangeButton && React.createElement(React.Fragment, null, React.createElement("section", null, React.createElement("div", {
    className: "ushop-title",
    style: {
      marginTop: "-16px"
    }
  }, React.createElement("h4", null, "\u0425\u04AF\u043B\u044D\u044D\u043D \u0430\u0432\u0430\u0445"), React.createElement("h3", null, "\u0425\u0430\u044F\u0433 \u0441\u043E\u043D\u0433\u043E\u0445")), false && React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress))), React.createElement(S.ShippingAddress, null, React.createElement("div", {
    className: "root1"
  }, React.createElement(S.ShippingAddressButton, {
    onClick: function onClick() {
      return setShippingAddressChangeButton(true);
    }
  }, React.createElement("button", {
    disabled: false,
    className: "add-btn1"
  }, React.createElement("p", null, "\u0445\u0430\u044F\u0433 \u04E9\u04E9\u0440\u0447\u043B\u04E9\u0445")))), React.createElement("div", {
    className: "root1"
  }, React.createElement(ShippingAddressSummary, {
    address: order.shippingAddress
  })))), (!order.shippingAddress || shippingAddressChangeButton) && React.createElement(React.Fragment, null, React.createElement("section", null, React.createElement("div", {
    className: "ushop-title",
    style: {
      marginTop: "-16px"
    }
  }, React.createElement("h4", null, "\u0425\u04AF\u043B\u044D\u044D\u043D \u0430\u0432\u0430\u0445"), React.createElement("h3", null, "\u0425\u0430\u044F\u0433 \u0441\u043E\u043D\u0433\u043E\u0445")), false && React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress))), order.user.addresses.length ? React.createElement(CustomAddressSummary, {
    userId: order.user.id,
    address: order.user.addresses,
    email: order.userEmail,
    selected: selected,
    setSelected: setSelected
  }) : React.createElement(TypedOrderUpdateShippingAddress, {
    onCompleted: onCompletedOrderUpdateShipping
  }, function (orderUpdateShippingAddressMutation) {
    return React.createElement(TypedUserAddressCreateMutation, null, function (UserAddressCreateMutation) {
      return React.createElement(AddressForm, {
        testingContext: "shippingAddressForm",
        formId: undefined,
        formRef: addressFormRef,
        countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
        address: _objectSpread({}, order.user.addresses, {
          email: order.userEmail
        }),
        handleSubmit: function handleSubmit(vals) {
          if (validateAddressForm(vals).length > 0) {
            return;
          }

          var __v = valsEdited(vals);

          UserAddressCreateMutation({
            variables: {
              input: __v
            }
          });
          orderUpdateShippingAddressMutation({
            variables: {
              id: order.id,
              input: {
                shippingAddress: __v,
                billingAddress: __v
              }
            }
          });
        },
        includeEmail: true,
        errors: shippingErrors
      });
    });
  })), React.createElement(S.Divider, null), React.createElement("section", null, React.createElement(S.AlertContainer, null, React.createElement(S.AlertTitle, null, "\u0421\u0430\u043D\u0430\u043C\u0436: "), React.createElement(S.AlertContent, null, "1. \u041D\u044D\u0433 \u0442\u04E9\u0440\u043B\u0438\u0439\u043D \u0431\u0430\u0440\u0430\u0430 \u0445\u043E\u0451\u0440\u043E\u043E\u0441 \u0434\u044D\u044D\u0448 \u0442\u043E\u043E\u0433\u043E\u043E\u0440", React.createElement("br", null), "2. \u0425\u04E9\u0434\u04E9\u043B\u043C\u04E9\u0440\u0438\u0439\u043D \u0445\u04E9\u043B\u0441\u043D\u0438\u0439 \u0434\u043E\u043E\u0434 \u0445\u044D\u043C\u0436\u044D\u044D\u0433 10 \u043D\u0443\u0433\u0430\u043B\u0441\u0430\u043D\u0430\u0430\u0441 \u0434\u044D\u044D\u0448 \u04AF\u043D\u0438\u0439\u043D \u0434\u04AF\u043D\u0442\u044D\u0439 \u0431\u0430\u0440\u0430\u0430 \u0437\u0430\u0445\u0438\u0430\u043B\u0441\u0430\u043D \u0442\u043E\u0445\u0438\u043E\u043B\u0434\u043E\u043B\u0434 \u041C\u043E\u043D\u0433\u043E\u043B \u0443\u043B\u0441\u044B\u043D \u0433\u0430\u0430\u043B\u0438\u0439\u043D \u0442\u0430\u0442\u0432\u0430\u0440 \u0442\u04E9\u043B\u04E9\u0445\u0438\u0439\u0433 \u0430\u043D\u0445\u0430\u0430\u0440\u043D\u0430 \u0443\u0443"))), React.createElement(S.NButton, null, React.createElement(TypedOrderUpdateShippingAddress, {
    onCompleted: onCompletedOrderUpdateShipping
  }, function (orderUpdateShippingAddressMutation) {
    return order.user.addresses.length ? React.createElement(S.NextButton, {
      disabled: Object.keys(selected).length === 0,
      onClick: function onClick() {
        return orderShippingAddressUpdate(orderUpdateShippingAddressMutation);
      }
    }, React.createElement(S.NextButtonText, null, "\u04AE\u0440\u0433\u044D\u043B\u0436\u043B\u04AF\u04AF\u043B\u044D\u0445")) : React.createElement(S.NextButton, {
      disabled: false,
      onClick: submitAddressForm
    }, React.createElement(S.NextButtonText, null, "\u04AE\u0440\u0433\u044D\u043B\u0436\u043B\u04AF\u04AF\u043B\u044D\u0445"));
  })));
};

export default Address;