import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  mutation DraftOrderCompleteWithPayment($id: ID!) {\n    draftOrderCompleteWithPayment(id: $id) {\n      order {\n        id\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  mutation OrderWalletTransactionCreate(\n    $orderId: ID!\n    $input: CheckoutTransactionInput!\n  ) {\n    orderWalletTransactionCreate(orderId: $orderId, input: $input) {\n      walletTransaction {\n        id\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation OrderPaymentCreate($orderId: ID!, $input: PaymentInput!) {\n    orderPaymentCreate(orderId: $orderId, input: $input) {\n      payment {\n        id\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag"; // import { TypedQuery } from "@temp/core/queries";

import { TypedMutation } from "@temp/core/mutations";
export var OrderPaymentCreateMutation = gql(_templateObject());
export var TypedOrderPaymentCreate = TypedMutation(OrderPaymentCreateMutation);
export var OrderWalletTransactionCreateMutation = gql(_templateObject2());
export var TypedOrderWalletTransactionCreate = TypedMutation(OrderWalletTransactionCreateMutation);
export var DraftOrderCompleteWithPaymentMutation = gql(_templateObject3());
export var TypedDraftOrderCompleteWithPayment = TypedMutation(DraftOrderCompleteWithPaymentMutation);