import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { Icon } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import CartSummaryProductList from "@components/organisms/CartSummary/CartSummaryProductList";
import * as S from "./styles";

var CostLine = function CostLine(_ref) {
  var name = _ref.name,
      cost = _ref.cost,
      _ref$last = _ref.last,
      last = _ref$last === void 0 ? false : _ref$last,
      _ref$negative = _ref.negative,
      negative = _ref$negative === void 0 ? false : _ref$negative;
  return React.createElement(S.CostLine, {
    last: last
  }, React.createElement("span", null, name), React.createElement("span", {
    "data-test": "cartOrderCost".concat(name.replace(/\s/g, ""))
  }, negative && "- ", React.createElement(TaxedMoney, {
    taxedMoney: cost
  })));
};

var Costs = function Costs(_ref2) {
  var subtotal = _ref2.subtotal,
      shipping = _ref2.shipping,
      total = _ref2.total;
  return React.createElement(S.Costs, null, subtotal && React.createElement(CostLine, {
    name: "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u043D\u0438\u0439\u0442",
    cost: subtotal
  }), shipping && React.createElement(CostLine, {
    name: "\u0422\u044D\u044D\u0432\u044D\u0440",
    cost: shipping
  }), total && React.createElement(CostLine, {
    name: "\u041D\u0438\u0439\u0442",
    cost: total,
    last: true
  }));
};

var OrderSummary = function OrderSummary(_ref3) {
  var order = _ref3.order;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      mobileOpened = _React$useState2[0],
      setMobileOpened = _React$useState2[1];

  return React.createElement(S.Wrapper, {
    mobileCartOpened: mobileOpened
  }, React.createElement(S.Title, {
    "data-test": "cartSummaryTitle",
    onClick: function onClick() {
      return setMobileOpened(!mobileOpened);
    }
  }, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0445\u0443\u0440\u0430\u0430\u043D\u0433\u0443\u0439", React.createElement(S.ArrowUp, {
    mobileCartOpened: mobileOpened
  }, React.createElement(Icon, {
    name: "arrow_up",
    size: 24
  }))), React.createElement(S.Content, null, React.createElement(S.HR, null), React.createElement(S.CartSummaryProductList, null, React.createElement(CartSummaryProductList, {
    lines: order === null || order === void 0 ? void 0 : order.lines
  })), React.createElement(Costs, {
    subtotal: order === null || order === void 0 ? void 0 : order.subtotal,
    total: order === null || order === void 0 ? void 0 : order.total,
    shipping: order === null || order === void 0 ? void 0 : order.shippingPrice
  })));
};

export default OrderSummary;