import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import { Link } from "react-router-dom";
import { commonMessages } from "@temp/intl";
import { baseUrl } from "../../app/routes";
import { getDBIdFromGraphqlId, slugify } from "../../core/utils";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
export var extractBreadcrumbs = function extractBreadcrumbs(category) {
  var constructLink = function constructLink(item) {
    return {
      link: ["/category", "/".concat(slugify(item.name)), "/".concat(getDBIdFromGraphqlId(item.id, "Category"), "/")].join(""),
      value: item.name
    };
  };

  var breadcrumbs = [constructLink(category)];

  if (category.ancestors.edges.length) {
    var ancestorsList = category.ancestors.edges.map(function (edge) {
      return constructLink(edge.node);
    });
    breadcrumbs = ancestorsList.concat(breadcrumbs);
  }

  return breadcrumbs;
}; // const getBackLink = (breadcrumbs: Breadcrumb[]) =>
//   breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].link : "/";

var backClink = function backClink() {
  var _window$history$state;

  // Check if there is a history page available
  var historyPage = (_window$history$state = window.history.state) === null || _window$history$state === void 0 ? void 0 : _window$history$state.backLink; // If a history page is available, navigate back

  if (historyPage) {
    window.history.back();
  } else {
    // Close the current page
    window.close();
  }
};

var Breadcrumbs = function Breadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs;
  return React.createElement(Media, {
    query: {
      minWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement("ul", {
      className: "breadcrumbs"
    }, React.createElement("li", null, React.createElement(Link, {
      to: baseUrl
    }, React.createElement(FormattedMessage, commonMessages.home))), breadcrumbs.map(function (breadcrumb, index) {
      return React.createElement("li", {
        key: breadcrumb.value,
        className: classNames({
          breadcrumbs__active: index === breadcrumbs.length - 1
        })
      }, React.createElement(Link, {
        to: breadcrumb.link
      }, breadcrumb.value));
    })) : React.createElement("div", {
      className: "breadcrumbs"
    }, React.createElement("button", {
      onClick: backClink
    }, React.createElement(FormattedMessage, {
      id: "components.Breadcrumbs.index.1707935629",
      defaultMessage: "\u0411\u0443\u0446\u0430\u0445"
    })));
  });
};

export default Breadcrumbs;