import React from "react";
import { AddressSummary } from "./AddressSummary";
import { OrderNote } from "./OrderNote";
import { PaymentSlip } from "./PaymentSlip";

var OrderInformation = function OrderInformation(_ref) {
  var guest = _ref.guest,
      order = _ref.order,
      refetchOrder = _ref.refetchOrder,
      refetchChat = _ref.refetchChat,
      setRefetchChat = _ref.setRefetchChat;
  return React.createElement("div", {
    className: "op-information-container"
  }, React.createElement(PaymentSlip, {
    order: order,
    refetchOrder: refetchOrder
  }), React.createElement(AddressSummary, {
    address: order.shippingAddress,
    email: order.userEmail // paragraphRef={this.shippingAddressRef}

  }), !guest && React.createElement("div", {
    className: "wrapper"
  }, React.createElement(OrderNote, {
    orderId: order.id,
    userEmail: order.userEmail,
    refetchOrder: refetchOrder,
    refetchChat: refetchChat,
    setRefetchChat: setRefetchChat
  })));
};

export { OrderInformation };