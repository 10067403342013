import { createContext } from "react";
export var defaultCountry = {
  __typename: "CountryDisplay",
  code: "US",
  country: "United States of America"
};
export var defaultContext = {
  __typename: "Shop",
  countries: [{
    country: "Mongolia",
    code: "MN",
    __typename: "CountryDisplay"
  }, {
    country: "United Kingdom",
    code: "GB",
    __typename: "CountryDisplay"
  }],
  defaultCountry: defaultCountry,
  displayGrossPrices: true,
  geolocalization: {
    __typename: "Geolocalization",
    country: defaultCountry
  }
};
export var ShopContext = createContext(defaultContext);
ShopContext.displayName = "ShopContext";