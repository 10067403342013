import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { StringParam, useQueryParams } from "use-query-params";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";
import { TaxedMoney } from "@components/containers";
import { commonMessages, translateOrderStatus } from "@temp/intl";
import { Thumbnail } from ".."; // import { generateProductUrl } from "../../../../core/utils";

import * as S from "./styles";

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.952864986",
    defaultMessage: "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0434\u0443\u0433\u0430\u0430\u0440"
  })), matches && React.createElement(React.Fragment, null, React.createElement(S.ProductsOrdered, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.2402251368",
    defaultMessage: "\u0417\u0430\u0445\u0438\u0430\u043B\u0441\u0430\u043D \u0431\u0430\u0440\u0430\u0430"
  })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.3765763246",
    defaultMessage: "\u041E\u0433\u043D\u043E\u043E"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.3310576937",
    defaultMessage: "\u041D\u0438\u0439\u0442"
  }))), React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)));
};

export var OrderTabel = function OrderTabel(_ref) {
  var orders = _ref.orders,
      history = _ref.history;
  var theme = React.useContext(ThemeContext);
  var intl = useIntl();

  var _useQueryParams = useQueryParams({
    token: StringParam
  }),
      _useQueryParams2 = _slicedToArray(_useQueryParams, 1),
      query = _useQueryParams2[0];

  var token = query.token;

  if (token) {
    history.push("/order-history/".concat(token));
  }

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches)), orders && orders.map(function (order) {
      var date = new Date(order.node.created);
      return React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: order.node.number,
        onClick: function onClick(evt) {
          evt.stopPropagation();
          history.push("/order-history/".concat(order.node.token));
        }
      }, React.createElement(S.IndexNumber, null, order.node.number), matches ? React.createElement(React.Fragment, null, React.createElement(S.ProductsOrdered, null, order.node.lines // .filter((line: any) => line.variant)
      .filter(function (line) {
        var _line$variant;

        return ((_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.product.productType.id) !== PRODUCT_TYPE_SHIPPING;
      }).filter(function (line) {
        return String(line.productName).toLowerCase().search("cargo") === -1;
      }).slice(0, 5).map(function (product) {
        return React.createElement("span", {
          key: product.id // onClick={evt => {
          //   evt.stopPropagation();paymentHistories
          //   );
          // }}

        }, React.createElement(Thumbnail, {
          source: product
        }));
      })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedDate, {
        value: date
      })), React.createElement(S.Value, null, React.createElement(TaxedMoney, {
        taxedMoney: order.node.total
      }))) : "", React.createElement(S.Status, null, translateOrderStatus(order.node.statusDisplay, intl)));
    }));
  }));
};