import React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-responsive";
import { mediumScreen } from "@styles/constants"; // import LogoSmall from "../../../../images/logo-small.svg";

import * as S from "./styles";
export var DemoBanner = function DemoBanner() {
  return React.createElement(S.Wrapper, null, React.createElement(S.BorderedWrapper, null, React.createElement(S.LinkList, null, React.createElement(S.TextEmphasis, null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.DemoBanner.DemoBanner.1302000723",
    defaultMessage: "Beta version"
  }))), React.createElement(S.LinkList, null, React.createElement(Media, {
    minWidth: mediumScreen
  }, React.createElement(S.TextEmphasis2, null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.DemoBanner.DemoBanner.3378615674",
    defaultMessage: "\u042F\u043C\u0430\u0440 \u043D\u044D\u0433 \u0430\u043B\u0434\u0430\u0430 \u0430\u0441\u0443\u0443\u0434\u0430\u043B \u0433\u0430\u0440\u0432\u0430\u043B \u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u0442\u043E\u0439 \u0445\u043E\u043B\u0431\u043E\u0433\u0434\u043E\u043D\u043E \u0443\u0443"
  }))))));
};