import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  min-height: 190px;\n  display: block;\n  background-color: ", ";\n  padding: ", ";\n  position: relative;\n  border: ", ";\n  font-size: ", ";\n  cursor: pointer;\n\n  ", "\n  &:after{\n    content: \"", "\";\n    color: ", ";\n    position: absolute;\n    top: -15px;\n    left: 50%;\n    transform: translate(-50%, 0);\n    border: 1px solid #323232;\n    background-color: ", ";\n    padding: 3px 10px;\n    border-radius: 4px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Label = styled.label(_templateObject(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked ? "28px" : "30px";
}, function (props) {
  return props.checked ? "2px solid #21125E" : "2px solid #ccc";
}, function (props) {
  return props.theme.typography.smallFontSize;
}, media.smallScreen(_templateObject2()), function (props) {
  return props.checked ? "\u0421\u043E\u043D\u0433\u043E\u0441\u043E\u043D" : "\u0421\u043E\u043D\u0433\u043E\u0445";
}, function (props) {
  return props.checked ? "#fff" : "inherit";
}, function (props) {
  return props.checked ? "#21125E" : props.theme.colors.light;
});
export var Input = styled.input(_templateObject3());