import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import * as React from "react";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import closeImg from "@temp/images/x.svg";
import { TypedDraftOrderHistory } from "../../../query";
import DraftOrder from "./DraftOrder";
import { OverlaySafari as Overlay } from "./OverlaySafari";
import * as S from "./styles";

var DraftOrderModal = function DraftOrderModal(_ref) {
  _objectDestructuringEmpty(_ref);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      openModal = _React$useState2[0],
      setOpenModal = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isClosed = _React$useState4[0],
      setClosed = _React$useState4[1];

  var hide = function hide() {
    setOpenModal(false);
    setClosed(false);
  };

  React.useEffect(function () {
    setClosed(true);
  }, []);
  return React.createElement(TypedDraftOrderHistory, {
    variables: {
      perPage: 10,
      after: null
    }
  }, function (draftOrderHistory) {
    var _draftOrderHistory$da, _draftOrderHistory$da2, _draftOrderHistory$da3;

    var draftOrders = draftOrderHistory === null || draftOrderHistory === void 0 ? void 0 : (_draftOrderHistory$da = draftOrderHistory.data) === null || _draftOrderHistory$da === void 0 ? void 0 : (_draftOrderHistory$da2 = _draftOrderHistory$da.me) === null || _draftOrderHistory$da2 === void 0 ? void 0 : (_draftOrderHistory$da3 = _draftOrderHistory$da2.orders) === null || _draftOrderHistory$da3 === void 0 ? void 0 : _draftOrderHistory$da3.edges.filter(function (edges) {
      return edges.node.statusDisplay === "Draft";
    });
    setOpenModal((draftOrders === null || draftOrders === void 0 ? void 0 : draftOrders.length) > 0);
    return React.createElement(Overlay, {
      position: "center",
      show: openModal && isClosed,
      hide: undefined,
      testingContext: undefined
    }, React.createElement(S.Modal, null, React.createElement(S.ModalTitle, null, React.createElement(S.CloseButton, {
      onClick: hide
    }, React.createElement(ReactSVG, {
      path: closeImg,
      className: "message__close-icon",
      onClick: hide
    })), React.createElement("p", {
      style: {
        fontSize: "0.875rem",
        marginTop: "0.625rem"
      }
    }, "Unurshop live"), React.createElement("p", {
      className: "strong"
    }, "\u0422\u0430\u043D\u044C\u0434 \u0442\u04E9\u043B\u0431\u04E9\u0440 \u0445\u04AF\u043B\u044D\u044D\u0433\u0434\u044D\u0436 \u0431\u0430\u0439\u0433\u0430\u0430 \u0437\u0430\u0445\u0438\u0430\u043B\u0433\u0443\u0443\u0434")), React.createElement(S.ModalDraftOrderHistory, null, React.createElement(React.Fragment, null, draftOrders === null || draftOrders === void 0 ? void 0 : draftOrders.map(function (__draftOrder, index) {
      return React.createElement("div", {
        key: index
      }, React.createElement(S.OrderNumber, null, React.createElement("span", {
        style: {
          fontWeight: "bold",
          textTransform: "uppercase"
        }
      }, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0434\u0443\u0433\u0430\u0430\u0440: #", __draftOrder.node.number)), React.createElement(DraftOrder, {
        draftOrder: __draftOrder.node.lines
      }), React.createElement("table", {
        className: "ushop-price-table",
        style: {
          fontSize: "1rem",
          margin: 0
        }
      }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", {
        style: {
          textAlign: "end"
        }
      }, "\u041D\u0438\u0439\u0442 (\u0434\u044D\u0434):"), React.createElement("td", {
        style: {
          textAlign: "center"
        }
      }, __draftOrder.node.subtotal.gross.localized)), false && React.createElement("tr", null, React.createElement("td", null, "\u0425\u04AF\u0440\u0433\u044D\u043B\u0442"), React.createElement("td", null)), true && React.createElement("tr", null, React.createElement("td", {
        style: {
          textAlign: "end"
        }
      }, "\u041D\u0438\u0439\u0442:"), React.createElement("td", {
        style: {
          textAlign: "center"
        }
      }, __draftOrder.node.total.gross.localized)))), React.createElement(S.ModalButton, null, React.createElement(Link, {
        to: "/order-history/".concat(__draftOrder.node.token)
      }, React.createElement(S.ModalBtn, null, React.createElement("span", null, "\u0422\u04E9\u043B\u0431\u04E9\u0440 \u0442\u04E9\u043B\u04E9\u0445")))));
    })))));
  });
};

export default DraftOrderModal;