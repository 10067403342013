import "./scss/index.scss";
import * as React from "react";
import { useAuth } from "unurshop-sdk";
import { Loader } from "@components/atoms";
import NotFound from "@temp/components/NotFound";
import DraftOrder from "./DraftOrder";
import OrderProgress from "./OrderProgress"; // import Page from "./Page";

import { TypedUserOrderByToken } from "./query";

var View = function View(_ref) {
  var token = _ref.match.params.token;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  if (!user) {
    return React.createElement(NotFound, null);
  }

  return React.createElement(React.Fragment, null, React.createElement(TypedUserOrderByToken, {
    variables: {
      token: token
    },
    fetchPolicy: "network-only"
  }, function (_ref2) {
    var loading = _ref2.loading,
        data = _ref2.data,
        refetch = _ref2.refetch;
    var order = data.orderByToken;
    var guest = !user;

    if (!order) {
      return React.createElement(NotFound, null);
    }

    var handleDownloadInvoice = function handleDownloadInvoice() {
      var _order$invoices;

      if (order && "invoices" in order && ((_order$invoices = order.invoices) === null || _order$invoices === void 0 ? void 0 : _order$invoices.length) > 0) {
        // Always download latest invoice
        var invoice = order.invoices.reduce(function (a, b) {
          return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
        });

        if (invoice) {
          window.open(invoice.url, "_blank");
        }
      }
    };

    if (loading) {
      return React.createElement(Loader, null);
    }

    return React.createElement("div", {
      className: "order-details container"
    }, (order === null || order === void 0 ? void 0 : order.status) === "DRAFT" ? React.createElement(DraftOrder, {
      order: order,
      refetchOrder: refetch
    }) : React.createElement(OrderProgress, {
      guest: guest,
      order: order,
      refetchOrder: refetch,
      downloadInvoice: handleDownloadInvoice
    }) // <Page
    //   guest={guest}
    //   order={order}
    //   refetchOrder={refetch}
    //   downloadInvoice={handleDownloadInvoice}
    // />
    );
  }));
}; // const { data: order, loading, refetch } = useOrderDetails(
//   { token },
//   { fetchPolicy: "cache-and-network" }
// );
// );


export default View;