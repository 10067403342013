import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { components } from "react-select";
import { ThemeContext } from "styled-components";
import { Icon, InputLabel, Select } from "@components/atoms";
import * as S from "./styles";
export var InputSelect = function InputSelect(_ref) {
  var label = _ref.label,
      inputProps = _ref.inputProps,
      props = _objectWithoutProperties(_ref, ["label", "inputProps"]);

  var customTheme = React.useContext(ThemeContext);
  var secondaryColor = customTheme.colors.secondary;
  var borderColor = customTheme.input.border;
  var customStyles = {
    control: function control(provided, state) {
      return _objectSpread({}, provided, {
        ":hover": {
          border: "1px solid ".concat(secondaryColor),
          outlineColor: secondaryColor,
          outlineStyle: "solid",
          outlineWidth: "1px"
        },
        background: "none",
        border: state.menuIsOpen ? "1px solid ".concat(secondaryColor) : "1px solid ".concat(borderColor),
        borderRadius: 0,
        boxShadow: 0,
        boxSizing: "border-box",
        margin: "0 0 16px",
        outline: state.menuIsOpen ? "1px solid ".concat(secondaryColor) : "",
        padding: "0.55rem 1rem"
      });
    },
    valueContainer: function valueContainer(provided) {
      return _objectSpread({}, provided, {
        padding: 0
      });
    }
  };
  var customComponents = {
    Control: function Control(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(React.Fragment, null, React.createElement(components.Control, _extends({
        "data-test": "inputSelect"
      }, _objectSpread({
        customTheme: customTheme
      }, props))), React.createElement(InputLabel, {
        labelBackground: customTheme.colors.light,
        active: props.selectProps.menuIsOpen || props.hasValue
      }, label));
    },
    IndicatorSeparator: function IndicatorSeparator() {
      return null;
    },
    IndicatorsContainer: function IndicatorsContainer(_ref2) {
      var selectProps = _ref2.selectProps,
          hasValue = _ref2.hasValue,
          clearValue = _ref2.clearValue;
      var showClearIndicator = selectProps.isClearable || selectProps.isMulti && selectProps.isClearable === undefined;

      if (showClearIndicator && hasValue) {
        return React.createElement(S.ClearIndicator, {
          onClick: clearValue
        }, React.createElement(Icon, {
          name: "select_x",
          size: 10
        }));
      } // Boolean to string conversion done due to
      // https://github.com/styled-components/styled-components/issues/1198


      return React.createElement(S.DropdownIndicator, {
        rotate: String(selectProps.menuIsOpen)
      }, React.createElement(Icon, {
        name: "select_arrow",
        size: 10
      }));
    },
    Input: function Input(props) {
      return React.createElement(components.Input, _objectSpread({}, props, inputProps));
    },
    Option: function Option(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(components.Option, _objectSpread({
        customTheme: customTheme
      }, props));
    }
  };
  return React.createElement(Select, _extends({
    customComponents: customComponents
  }, props, {
    customStyles: customStyles
  }));
};