import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  cursor: pointer;\n  color: #fff;\n  font-weight: 600;\n  text-align: center;\n  border: 1px solid #fff;\n  padding: 0.5rem 1rem;\n  background-color: #323232;\n  &:not(:last-child) {\n    border-right: none;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: #fff;\n  font-size: 1.125rem;\n  text-transform: inherit;\n  font-weight: 600;\n  line-height: 1.25rem;\n  transform: skew(0deg);\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100px;\n  background-color: #323232;\n  transform: skew(0deg);\n  border: 2px solid #323232;\n  box-shadow: -5px 5px 14px 0px rgb(0 0 0 / 20%);\n  transition: 0.3s;\n  outline: none;\n  font-family: \"Montserrat\", sans-serif;\n  cursor: pointer;\n  border-radius: 4px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  padding-right: 10px;\n  align-items: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: flex-end;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: ", ";\n  margin-bottom: 15px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin: 1rem 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var OgnooContainer = styled.div(_templateObject3());
export var Ognoo = styled.div(_templateObject4());
export var Tovch = styled.button(_templateObject5());
export var ButtonText = styled.span(_templateObject6());
export var ButtonGroup = styled.div(_templateObject7());
export var Button = styled.button(_templateObject8());