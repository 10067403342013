import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  bottom: 1rem;\n  padding: 0.2rem 0.3rem;\n  background-color: #eb0037;\n  color: #fff;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 0.8rem;\n  > img {\n    width: 100%;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  /* background: ", "; */\n  width: auto;\n  height: 100%;\n  max-width: 100%;\n  position: relative;\n  display: flex;\n\n  > img {\n    width: 100%;\n    height: auto;\n    max-width: 100%;\n    max-height: 26rem;\n    object-fit: cover;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 0.5rem;\n  grid-template-columns: 48px 1fr;\n  background: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  font-weight: 600;\n  margin-top: 0.5rem;\n  > span {\n    &:nth-child(1) {\n      color: ", ";\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-weight: normal;\n  margin: 0 0 0.5rem 0;\n  line-height: 14px;\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    padding: 0rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  padding: 0rem;\n  margin: 0px;\n  text-align: center;\n  max-height: 30rem; \n  transition: 0.3s;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  :hover {\n    /* background-color: ", "; */\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from "styled-components";
import { media, styled } from "@styles";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.light;
}, media.largeScreen(_templateObject3()));
export var Title = styled.h4(_templateObject4(), textProps);
export var Price = styled.p(_templateObject5(), textProps, function (props) {
  return props.theme.colors.saleColor;
});
export var RowLk = styled.div(_templateObject6(), function (props) {
  return props.theme.colors.white;
});
export var TitleAndPrice = styled.div(_templateObject7());
export var Image = styled.div(_templateObject8(), function (props) {
  return props.theme.colors.light;
});
export var ShopLogo = styled.div(_templateObject9());
export var Sale = styled.div(_templateObject10());