import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { useIntl } from "react-intl";
import { TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AddressFormContentDefault = function AddressFormContentDefault(_ref) {
  var basicInputProps = _ref.basicInputProps,
      fieldErrors = _ref.fieldErrors,
      values = _ref.values,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "firstName",
    label: intl.formatMessage(commonMessages.firstName),
    value: values.firstName,
    autoComplete: "given-name",
    errors: fieldErrors.firstName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "lastName",
    label: intl.formatMessage(commonMessages.lastName),
    value: values.lastName,
    autoComplete: "family-name",
    errors: fieldErrors.lastName
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "companyName",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Байгууллагын нэр (заавал биш)",
      "id": "@next.components.organisms.AddressForm.AddressFormContentDefault.2765405485"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentDefault.2765405485")),
    value: values.companyName,
    autoComplete: "organization",
    errors: fieldErrors.companyName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "phone",
    label: intl.formatMessage(commonMessages.phone),
    value: values.phone || undefined,
    autoComplete: "tel",
    errors: fieldErrors.phone
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress1",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Хаягын мэдээлэл 1",
      "id": "@next.components.organisms.AddressForm.AddressFormContentDefault.3913663790"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentDefault.3913663790")),
    value: values.streetAddress1,
    autoComplete: "address-line1",
    errors: fieldErrors.streetAddress1
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress2",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Хаягын мэдээлэл 2",
      "id": "@next.components.organisms.AddressForm.AddressFormContentDefault.3858448138"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentDefault.3858448138")),
    value: values.streetAddress2,
    autoComplete: "address-line2",
    errors: fieldErrors.streetAddress2
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "city",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Хот",
      "id": "@next.components.organisms.AddressForm.AddressFormContentDefault.4199759880"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentDefault.4199759880")),
    value: values.city,
    autoComplete: "address-level2",
    errors: fieldErrors.city
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "countryArea",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Аймаг",
      "id": "@next.components.organisms.AddressForm.AddressFormContentDefault.3210131561"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentDefault.3210131561")),
    value: values.countryArea,
    autoComplete: "address-level1",
    errors: fieldErrors.countryArea
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "postalCode",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "ZIP/Postal Code",
      "id": "@next.components.organisms.AddressForm.AddressFormContentDefault.2796540622"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentDefault.2796540622")),
    value: values.postalCode,
    autoComplete: "postal-code",
    errors: fieldErrors.postalCode
  }, basicInputProps()))), includeEmail && React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "email",
    label: intl.formatMessage(commonMessages.shortEmail),
    value: values.email,
    autoComplete: "email",
    errors: fieldErrors.email
  }, basicInputProps()))));
};