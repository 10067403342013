import classNames from "classnames";
import * as React from "react"; // import { Link } from "react-router-dom";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { maybe } from "@temp/misc";
import { SHIPPING_STATUS } from "@temp/constants"; // import { generateProductUrl } from "../../core/utils";

var ProductRow = function ProductRow(_ref) {
  var processing = _ref.processing,
      line = _ref.line,
      setModalOpen = _ref.setModalOpen,
      setLine = _ref.setLine;
  // const productUrl = generateProductUrl(
  //   line.orderLine.variant.product.id,
  //   line.orderLine.variant.product.name
  // );
  var soonDateStatus = ["shipping"];

  var isSoonDate = function isSoonDate(line) {
    return soonDateStatus.includes(line.ustatus.toLowerCase()) ? line.changedDate : line.soonDate;
  };

  var ushopStatusRender = function ushopStatusRender(line) {
    return isSoonDate(line) ? "".concat(statusLabel(line.ustatus), " /").concat(isSoonDate(line), "/") : "".concat(statusLabel(line.ustatus));
  };

  var statusLabel = function statusLabel(value) {
    return SHIPPING_STATUS.filter(function (l) {
      return l.value === value;
    })[0].label;
  };

  return React.createElement(React.Fragment, null, React.createElement("tr", {
    className: classNames({
      "cart-table-row--processing": processing
    })
  }, React.createElement("td", {
    className: "cart-table__thumbnail",
    onClick: function onClick() {
      setModalOpen(true);
      setLine(line.orderLine);
    }
  }, React.createElement("div", null, React.createElement(Thumbnail, {
    source: line.orderLine.variant.product
  }))), React.createElement("td", {
    onClick: function onClick() {
      setModalOpen(true);
      setLine(line.orderLine);
    }
  }, line.orderLine.variant.product.name, line.orderLine.variant.attributes.map(function (_ref2, attributeIndex) {
    var attribute = _ref2.attribute,
        values = _ref2.values;
    return React.createElement("p", {
      key: attribute.id
    }, attribute.name, ": ", values.map(function (value) {
      return value.name;
    }).join(", "));
  }), React.createElement("span", {
    className: "cart-table_status"
  }, maybe(function () {
    return ushopStatusRender(line);
  }) || "")), React.createElement("td", {
    className: "text-right"
  }, React.createElement(TaxedMoney, {
    taxedMoney: line.orderLine.variant.pricing.price
  })), React.createElement("td", {
    className: "cart-table__quantity-cell text-right"
  }, React.createElement("p", null, line.orderLine.quantity)), React.createElement("td", {
    className: "money text-right"
  }, React.createElement(TaxedMoney, {
    taxedMoney: line.orderLine.totalPrice
  }))));
};

export default ProductRow;