import * as React from "react"; // import { maybe, renderCollection } from "../../../misc";

var ShopRow = function ShopRow(_ref) {
  var line = _ref.line;
  return React.createElement("tr", null, React.createElement("td", {
    className: "no-padding",
    colSpan: 6
  }, React.createElement("h5", {
    className: "cart__shop__title"
  }, React.createElement("span", {
    className: "cart__shop__name"
  }, React.createElement("img", {
    className: "cart__shop__logo",
    src: line.logoImage.url,
    alt: line.name
  }), React.createElement("span", null, line.name)))));
};

export default ShopRow;