import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fff3cd;\n  border: 1px solid #ffecb5;\n  border-radius: 0.25rem;\n  color: #664d03;\n  font-size: 12px;\n  padding: 1rem;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    position: fixed;\n    z-index: 20;\n    bottom: 0;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: cartSummary;\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: checkout;\n  padding: 3rem 0 0 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  border-bottom: 1px solid\n    ", ";\n  padding-bottom: 43px;\n  height: 85px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"navigation\"\n      \"checkout\"\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 45px 0;\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-template-rows: 85px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation cartSummary\"\n    \"checkout cartSummary\";\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.mediumScreen(_templateObject2()));
export var Navigation = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Checkout = styled.div(_templateObject4());
export var CartSummary = styled.div(_templateObject5(), media.mediumScreen(_templateObject6()));
export var Divider = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var AlertContainer = styled.div(_templateObject8());
export var AlertTitle = styled.h6(_templateObject9());
export var AlertContent = styled.div(_templateObject10());