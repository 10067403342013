import "../globalStyles/scss/index.scss";
import React from "react";
import { useAuth } from "unurshop-sdk";
import { Loader } from "@components/atoms";
import OrderDetailManagerProvider from "@components/templates/OrderDetailModal"; // import { demoMode } from "@temp/constants";

import { Footer, MainMenu, MetaConsumer, OverlayManager, OverlayProvider, Topbar } from "../components";
import ShopProvider from "../components/ShopProvider";
import Notifications from "./Notifications";
import { Routes } from "./routes";

var App = function App() {
  var _useAuth = useAuth(),
      tokenRefreshing = _useAuth.tokenRefreshing,
      tokenVerifying = _useAuth.tokenVerifying;

  if (tokenRefreshing || tokenVerifying) {
    return React.createElement(Loader, null);
  }

  return React.createElement(ShopProvider, null, React.createElement(OverlayProvider, null, React.createElement(OrderDetailManagerProvider, null, React.createElement(MetaConsumer, null), React.createElement(Topbar, null), React.createElement("header", {
    id: "header-sticky"
  }, React.createElement(MainMenu, null)), React.createElement(Routes, null), React.createElement(Footer, null), React.createElement(OverlayManager, null), React.createElement(Notifications, null))));
};

export default App;