import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../..";

var Empty = function Empty(_ref) {
  var overlayHide = _ref.overlayHide;
  return React.createElement("div", {
    className: "cart__empty",
    style: {
      textAlign: "center"
    }
  }, React.createElement("h4", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.873624592",
    defaultMessage: "\u0422\u0430\u043D\u044B \u0441\u0430\u0433\u0441 \u0445\u043E\u043E\u0441\u043E\u043D \u0431\u0430\u0439\u043D\u0430"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.3201277464",
    defaultMessage: "\u0422\u0430 \u0441\u0430\u0433\u0441\u0430\u043D\u0434 \u0431\u0430\u0440\u0430\u0430 \u043D\u044D\u043C\u044D\u044D\u0433\u04AF\u0439 \u0431\u0430\u0439\u043D\u0430. \u0411\u0438\u0434 \u0442\u0430\u043D\u044B\u0433 \u043C\u0430\u043D\u0430\u0439 \u0434\u044D\u043B\u0433\u04AF\u04AF\u0440\u044D\u044D\u0441 \u0445\u04AF\u0441\u0441\u044D\u043D \u0431\u0430\u0440\u0430\u0430\u0433\u0430\u0430 \u043E\u043B\u043D\u043E \u0433\u044D\u0434\u044D\u0433\u0442 \u0438\u0442\u0433\u044D\u043B\u0442\u044D\u0439 \u0431\u0430\u0439\u043D\u0430"
  })), React.createElement("div", {
    className: "cart__empty__action"
  }, React.createElement(Button, {
    testingContext: "emptyCartHideOverlayButton",
    secondary: true,
    onClick: overlayHide
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.3702876472",
    defaultMessage: "\u041D\u04AF\u04AF\u0440 \u0440\u04AF\u04AF \u043E\u0447\u0438\u0445"
  }))));
};

export default Empty;