import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { IconButton } from "@components/atoms";
import { Thumbnail } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { generateProductUrl } from "../../../../core/utils";
import * as S from "./styles-cant-buy";

/**
 * Product row displayed on cart page
 */
export var CartRow = function CartRow(_ref) {
  var _line$variant;

  var index = _ref.index,
      totalPrice = _ref.totalPrice,
      unitPrice = _ref.unitPrice,
      name = _ref.name,
      sku = _ref.sku,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      _ref$attributes = _ref.attributes,
      attributes = _ref$attributes === void 0 ? [] : _ref$attributes,
      onRemove = _ref.onRemove,
      id = _ref.id,
      line = _ref.line,
      message = _ref.message;

  var _useState = useState(quantity.toString()),
      _useState2 = _slicedToArray(_useState, 2),
      tempQuantity = _useState2[0],
      setTempQuantity = _useState2[1];

  useEffect(function () {
    setTempQuantity(quantity.toString());
  }, [quantity]);
  var productUrl = generateProductUrl(id, name);
  return React.createElement(S.WrapperCantBuy, {
    "data-test": "cartRow",
    "data-test-id": sku
  }, React.createElement(S.Photo, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(Thumbnail, {
    source: line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.product
  }))), React.createElement(S.Description, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(S.Name, {
    "data-test": "itemName"
  }, name)), React.createElement(S.Sku, null, React.createElement(S.WarningFont, null, message)), React.createElement(S.Attributes, {
    "data-test": "itemAttributes"
  }, attributes.map(function (_ref2, attributeIndex) {
    var attribute = _ref2.attribute,
        values = _ref2.values;
    return React.createElement(S.SingleAttribute, {
      key: attribute.id
    }, React.createElement("span", {
      "data-test": "itemSingleAttribute",
      "data-test-id": attributeIndex
    }, React.createElement(S.LightFont, null, attribute.name, ":"), " ", values.map(function (value) {
      return value.name;
    }).join(", ")));
  }))), React.createElement(S.Quantity, null, React.createElement(S.Name, {
    "data-test": "itemName"
  }, tempQuantity)), React.createElement(S.Trash, null, React.createElement(IconButton, {
    testingContext: "removeButton",
    testingContextId: sku,
    size: 22,
    name: "trash",
    onClick: onRemove
  })), React.createElement(S.TotalPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.totalPrice), ":")), React.createElement("p", {
    "data-test": "totalPrice"
  }, totalPrice)), React.createElement(S.UnitPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.price), ":")), React.createElement("p", {
    "data-test": "unitPrice"
  }, unitPrice)));
};