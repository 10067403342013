import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { maybe } from "@temp/misc";
import { PRODUCT_TYPE_SHIPPING } from "@temp/constants";
import ProductRow from "./ProductRow";
import ShopRow from "./ShopRow";
import ShopFooterRow from "./ShopFooterRow";
import "./scss/index.scss";

var TableBodyMobile = function TableBodyMobile(_ref) {
  var fulfillment = _ref.fulfillment,
      setModalOpen = _ref.setModalOpen,
      setLine = _ref.setLine,
      rowProps = _objectWithoutProperties(_ref, ["fulfillment", "setModalOpen", "setLine"]);

  var lines = maybe(function () {
    return fulfillment.lines.filter(function (l) {
      return l.orderLine.variant.product.productType.id !== PRODUCT_TYPE_SHIPPING;
    });
  }, []);
  var shippingUk = maybe(function () {
    return fulfillment.lines.find(function (l) {
      return l.orderLine.variant.product.productType.id === PRODUCT_TYPE_SHIPPING;
    });
  });
  var ushop = maybe(function () {
    return lines[0].orderLine.variant.product.ushop;
  });
  return lines && React.createElement("tbody", null, React.createElement(ShopRow, {
    key: ushop.id,
    line: ushop
  }), lines.map(function (line) {
    return React.createElement(ProductRow, _extends({
      key: "".concat(ushop.id, "-").concat(line.id),
      line: line,
      setModalOpen: setModalOpen,
      setLine: setLine
    }, rowProps));
  }), React.createElement(ShopFooterRow, {
    key: "footer".concat(ushop.id),
    heading: "\u0410\u043D\u0433\u043B\u0438 \u0434\u043E\u0442\u043E\u043E\u0434 \u0445\u04AF\u0440\u0433\u044D\u043B\u0442",
    cost: shippingUk ? shippingUk.orderLine.unitPrice.gross : null
  }));
};

export default TableBodyMobile;