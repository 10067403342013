import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "@temp/misc";
import * as S from "../styles";

var DatePickers = function DatePickers(props) {
  var setDateRange = props.setDateRange;

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      startDate = _React$useState2[0],
      setStartDate = _React$useState2[1];

  var _React$useState3 = React.useState(null),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      endDate = _React$useState4[0],
      setEndDate = _React$useState4[1];

  return React.createElement(S.OgnooContainer, null, React.createElement(S.Ognoo, null, React.createElement("p", null, "\u041E\u0433\u043D\u043E\u043E:"), React.createElement(DatePicker, {
    selected: startDate,
    onChange: function onChange(date) {
      return setStartDate(date);
    }
  }), React.createElement("p", null, "-"), React.createElement(DatePicker, {
    selected: endDate,
    onChange: function onChange(date) {
      return setEndDate(date);
    }
  })), React.createElement(S.Tovch, null, React.createElement("button", {
    onClick: function onClick() {
      return setDateRange({
        start: formatDate(startDate === null || startDate === void 0 ? void 0 : startDate.toDateString()),
        end: formatDate(endDate === null || endDate === void 0 ? void 0 : endDate.toDateString())
      });
    }
  }, React.createElement(S.ButtonText, null, "\u0428\u04AF\u04AF\u0445"))));
};

export default DatePickers;