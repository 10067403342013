import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import * as S from "./styles";

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PayHistoryTable.PayHistoryTable.1383321068",
    defaultMessage: "\u041E\u043D \u0441\u0430\u0440"
  })), matches && React.createElement(React.Fragment, null, React.createElement(S.PaymentType, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PayHistoryTable.PayHistoryTable.1585103234",
    defaultMessage: "\u0422\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u0445\u044D\u043B\u0431\u044D\u0440"
  })), React.createElement(S.PaymentDescription, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PayHistoryTable.PayHistoryTable.521738134",
    defaultMessage: "\u0422\u0430\u0439\u043B\u0431\u0430\u0440"
  })), React.createElement(S.Balance, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PayHistoryTable.PayHistoryTable.790064868",
    defaultMessage: "\u0414\u04AF\u043D"
  }))));
};

export var PayHistoryTable = function PayHistoryTable(_ref) {
  var payHistories = _ref.payHistories;
  var theme = React.useContext(ThemeContext);
  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, " ", header(matches), " "), payHistories && (payHistories === null || payHistories === void 0 ? void 0 : payHistories.map(function (history, index) {
      var date = new Date(history.node.modified);
      return React.createElement(S.Row, {
        "data-test": "paymentHistoryEntry",
        "data-test-id": index,
        key: index // onClick={() =>
        //   console.log(
        //     "1)event avna 2) wallet transactionoos token avaad history deer pushlen",
        //     history
        //   )
        // }

      }, React.createElement(S.DateOfOrder, null, React.createElement(FormattedDate, {
        value: date
      })), React.createElement(S.PaymentType, null, history.node.gateway === "mirumee.payments.stripe" ? "Stripe" : null), React.createElement(S.PaymentDescription, null, history.node.order.number > 0 ? "".concat(history.node.order.number, " \u0437\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0442\u04E9\u043B\u0431\u04E9\u0440") : null), React.createElement(S.Balance, null, history.node.capturedAmount.localized, " \xA3"));
    })));
  }));
};