/* eslint-disable react/jsx-no-target-blank */
import "./scss/index.scss";
import * as React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAuth } from "unurshop-sdk"; // import { useSignOut, useUserDetails } from '@sdk/react';
// import { OverlayContext } from "..";

import { OverlayContext, OverlayTheme, OverlayType, MenuDropdown } from "..";
import * as appPaths from "../../app/routes"; // import iconUk from "../../images/unurshop/icons/flag.svg";

import { mediumScreen } from "../../globalStyles/scss/variables.scss"; // import iconMn from "../../images/unurshop/icons/mn.svg";

import iconPhone from "../../images/unurshop/icons/phone.svg"; // import iconPound from "../../images/unurshop/icons/pound.svg";
// import iconTugrug from "../../images/unurshop/icons/tugrug.svg";

import iconUser from "../../images/unurshop/icons/user.svg"; // import {
//   accountUrl,
//   addressBookUrl,
//   baseUrl,
//   orderHistoryUrl,
//   paymentOptionsUrl
// } from "../../routes";

var Topbar = function Topbar() {
  // const { user } = useAuth();
  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement(React.Fragment, null, React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("div", {
          className: "topbar"
        }, React.createElement("div", {
          className: "topbar-container"
        }, React.createElement("ul", {
          className: "unurshop-type-menu"
        }, React.createElement("li", {
          className: "active"
        }, React.createElement(Link, {
          to: appPaths.baseUrl
        }, React.createElement(FormattedMessage, commonMessages.orderProduct))), React.createElement("li", null, React.createElement("a", {
          href: appPaths.carParts,
          target: "_blank"
        }, React.createElement(FormattedMessage, commonMessages.carAndPart))), React.createElement("li", null, React.createElement("a", {
          href: appPaths.cargo,
          target: "_blank"
        }, React.createElement(FormattedMessage, commonMessages.cargo)))), React.createElement("ul", {
          className: "contact-menu"
        }, React.createElement("li", {
          className: "phone"
        }, React.createElement("a", {
          href: "tel:+97670000509",
          className: "with-icon"
        }, React.createElement(ReactSVG, {
          path: iconPhone,
          className: "svg-icon",
          style: {
            marginRight: "0.6rem"
          }
        }), "7000-0509")))));
      }
    }), React.createElement(Media, {
      query: {
        maxWidth: mediumScreen
      },
      render: function render() {
        return React.createElement("div", {
          className: "topbar"
        }, React.createElement("div", {
          style: {
            backgroundColor: "#f1f3f4"
          }
        }, React.createElement("ul", {
          className: "contact-menu"
        }, React.createElement(React.Fragment, null, user ? React.createElement(MenuDropdown, {
          head: React.createElement("li", {
            className: "main-menu__icon main-menu__user--active",
            style: {
              display: "flex"
            }
          }, React.createElement(ReactSVG, {
            path: iconUser
          }), React.createElement("span", {
            style: {
              paddingLeft: "1rem"
            }
          }, user.firstName ? user.firstName : user.email)),
          content: React.createElement("ul", {
            className: "main-menu__dropdown"
          }, React.createElement("li", null, React.createElement("p", null, React.createElement(FormattedMessage, commonMessages.userCode), " ", "\xA0", React.createElement("span", null, user.code))), React.createElement("li", {
            "data-test": "desktopMenuMyAccountLink"
          }, React.createElement(Link, {
            to: appPaths.accountUrl
          }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
            "data-test": "desktopMenuOrderHistoryLink"
          }, React.createElement(Link, {
            to: appPaths.orderHistoryUrl
          }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
            "data-test": "desktopMenuAddressBookLink"
          }, React.createElement(Link, {
            to: appPaths.addressBookUrl
          }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
            onClick: function onClick() {
              return signOut();
            },
            "data-test": "desktopMenuLogoutLink"
          }, React.createElement(FormattedMessage, commonMessages.logOut)))
        }) : React.createElement("li", {
          "data-test": "desktopMenuLoginOverlayLink",
          className: "main-menu__icon user-icon",
          onClick: function onClick() {
            return overlayContext.show(OverlayType.login, OverlayTheme.right);
          }
        }, React.createElement(ReactSVG, {
          path: iconUser
        }), React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.login)))), React.createElement("li", {
          className: "phone"
        }, React.createElement("a", {
          href: "tel:+97670000509",
          className: "with-icon"
        }, React.createElement(ReactSVG, {
          path: iconPhone,
          className: "svg-icon",
          style: {
            marginRight: "0.6rem"
          }
        }), "7000-0509")))));
      }
    }));
  });
};

export default Topbar;