import { Formik } from "formik";
import { IssueStatus, IssueType } from "gqlTypes/globalTypes";
import SendIcon from "images/send.svg";
import React from "react";
import Moment from "react-moment";
import ReactSVG from "react-svg";
import { TextField, Thumbnail } from "@components/molecules";
import IssueDialog from "./IssueDialog";
export var OrderChats = function OrderChats(props) {
  var addMessage = props.addMessage,
      chats = props.chats,
      orderId = props.orderId,
      userEmail = props.userEmail,
      refetchOrder = props.refetchOrder,
      setRefetchChat = props.setRefetchChat;
  var initialValues = {
    message: ""
  };

  var renderLineInfo = function renderLineInfo(line) {
    var _line$variant, _line$variant2, _line$variant2$produc, _line$variant3, _line$variant3$attrib;

    return React.createElement("div", {
      className: "product-info"
    }, React.createElement(Thumbnail, {
      source: line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.product
    }), React.createElement("div", {
      className: "product-name-size"
    }, React.createElement("b", null, line === null || line === void 0 ? void 0 : (_line$variant2 = line.variant) === null || _line$variant2 === void 0 ? void 0 : (_line$variant2$produc = _line$variant2.product) === null || _line$variant2$produc === void 0 ? void 0 : _line$variant2$produc.name), line === null || line === void 0 ? void 0 : (_line$variant3 = line.variant) === null || _line$variant3 === void 0 ? void 0 : (_line$variant3$attrib = _line$variant3.attributes) === null || _line$variant3$attrib === void 0 ? void 0 : _line$variant3$attrib.map(function (_ref, attributeIndex) {
      var attribute = _ref.attribute,
          values = _ref.values;
      return React.createElement("p", {
        key: "product-attribute-".concat(attribute === null || attribute === void 0 ? void 0 : attribute.id, "-").concat(attributeIndex)
      }, attribute === null || attribute === void 0 ? void 0 : attribute.name, ": ", values.map(function (value) {
        return value === null || value === void 0 ? void 0 : value.name;
      }).join(", "));
    })));
  };

  return React.createElement("div", {
    className: "chat-wrapper"
  }, React.createElement("h2", null, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0445\u04AF\u0441\u044D\u043B\u0442"), React.createElement("ul", {
    className: "chat-body"
  }, chats.map(function (chat, index) {
    var _chat$issue, _chat$issue2, _chat$issue3, _chat$issue4, _chat$issue5, _chat$issue6, _chat$issue7, _chat$issue8, _chat$issue9, _chat$issue9$events, _chat$issue9$events$f, _chat$issue10, _chat$issue10$events;

    return React.createElement("li", {
      className: userEmail === chat.user.email ? "is-me" : "",
      key: index
    }, chat.type === "ISSUE_CREATED" ? React.createElement("div", null, (chat === null || chat === void 0 ? void 0 : chat.issue) && (chat === null || chat === void 0 ? void 0 : (_chat$issue = chat.issue) === null || _chat$issue === void 0 ? void 0 : _chat$issue.type) === IssueType.ISSUE && (chat === null || chat === void 0 ? void 0 : (_chat$issue2 = chat.issue) === null || _chat$issue2 === void 0 ? void 0 : _chat$issue2.status) === IssueStatus.TODO && React.createElement(React.Fragment, null, React.createElement(IssueDialog, {
      issue: chat === null || chat === void 0 ? void 0 : chat.issue,
      line: chat === null || chat === void 0 ? void 0 : (_chat$issue3 = chat.issue) === null || _chat$issue3 === void 0 ? void 0 : _chat$issue3.orderLine,
      refetchOrder: refetchOrder,
      setRefetchChat: setRefetchChat
    }), React.createElement("span", {
      className: "chat-date"
    }, React.createElement(Moment, {
      date: chat.date,
      format: "DD/MM/YY HH:mm",
      locale: "mn"
    }, chat.date))), (chat === null || chat === void 0 ? void 0 : chat.issue) && (chat === null || chat === void 0 ? void 0 : (_chat$issue4 = chat.issue) === null || _chat$issue4 === void 0 ? void 0 : _chat$issue4.type) === IssueType.ISSUE && (chat === null || chat === void 0 ? void 0 : (_chat$issue5 = chat.issue) === null || _chat$issue5 === void 0 ? void 0 : _chat$issue5.status) === IssueStatus.DONE && React.createElement(React.Fragment, null, React.createElement("div", {
      className: "chat-issue"
    }, (chat === null || chat === void 0 ? void 0 : (_chat$issue6 = chat.issue) === null || _chat$issue6 === void 0 ? void 0 : _chat$issue6.orderLine) ? renderLineInfo(chat === null || chat === void 0 ? void 0 : (_chat$issue7 = chat.issue) === null || _chat$issue7 === void 0 ? void 0 : _chat$issue7.orderLine) : "", React.createElement("div", {
      style: {
        fontSize: "0.875rem"
      }
    }, chat === null || chat === void 0 ? void 0 : (_chat$issue8 = chat.issue) === null || _chat$issue8 === void 0 ? void 0 : _chat$issue8.description, (chat === null || chat === void 0 ? void 0 : (_chat$issue9 = chat.issue) === null || _chat$issue9 === void 0 ? void 0 : (_chat$issue9$events = _chat$issue9.events) === null || _chat$issue9$events === void 0 ? void 0 : (_chat$issue9$events$f = _chat$issue9$events.filter(function (e) {
      return e.type === "COMMENT_ADDED";
    })) === null || _chat$issue9$events$f === void 0 ? void 0 : _chat$issue9$events$f.length) > 0 && React.createElement("div", {
      style: {
        display: "flex",
        justifyContent: "end"
      }
    }, React.createElement("h6", null, "\u0425\u0430\u0440\u0438\u0443\u043B\u0442:\xA0"), React.createElement("ul", null, chat === null || chat === void 0 ? void 0 : (_chat$issue10 = chat.issue) === null || _chat$issue10 === void 0 ? void 0 : (_chat$issue10$events = _chat$issue10.events) === null || _chat$issue10$events === void 0 ? void 0 : _chat$issue10$events.filter(function (e) {
      return e.type === "COMMENT_ADDED";
    }).map(function (e) {
      return React.createElement("li", {
        key: e.id,
        style: {
          fontWeight: "bold"
        }
      }, e.message);
    }))))), React.createElement("span", {
      className: "chat-date"
    }, React.createElement(Moment, {
      date: chat.date,
      format: "DD/MM/YY HH:mm",
      locale: "mn"
    }, chat.date)))) : React.createElement(React.Fragment, null, React.createElement("span", {
      className: "chat-message"
    }, chat.message), React.createElement("br", {
      className: "clearfix"
    }), React.createElement("span", {
      className: "chat-date"
    }, React.createElement(Moment, {
      date: chat.date,
      format: "DD/MM/YY HH:mm",
      locale: "mn"
    }, chat.date))));
  })), React.createElement(Formik, {
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var resetForm = _ref2.resetForm;
      addMessage({
        variables: {
          order: orderId,
          input: {
            message: values.message
          }
        }
      });
      resetForm();
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        values = _ref3.values,
        handleSubmit = _ref3.handleSubmit;
    return React.createElement("form", {
      onSubmit: handleSubmit
    }, React.createElement("div", {
      className: "chatinput"
    }, React.createElement("div", {
      className: "cht"
    }, React.createElement(TextField, {
      name: "message",
      label: "\u0425\u04AF\u0441\u044D\u043B\u0442\u044D\u044D \u044D\u043D\u0434 \u0431\u0438\u0447\u043D\u044D \u04AF\u04AF",
      value: values.message,
      onChange: handleChange
    })), React.createElement("button", {
      type: "submit",
      className: "cht-btn"
    }, React.createElement(ReactSVG, {
      path: SendIcon
    }))));
  }));
};