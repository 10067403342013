import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  border: 1px solid #c3e6cb;\n  border-radius: 0.25rem;\n  padding: 1rem;\n  color: #155724;\n  background-color: #d4edda;\n  margin-bottom: 1rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fff3cd;\n  border: 1px solid #ffecb5;\n  border-radius: 0.25rem;\n  color: #664d03;\n  padding: 1rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Divider = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject3(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var AlertContainer = styled.div(_templateObject4());
export var AlertTitle = styled.h6(_templateObject5());
export var AlertContent = styled.div(_templateObject6());
export var SuccessContainer = styled.div(_templateObject7());