import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  mutation ProductUpdateMetadata($id: ID!, $input: [MetadataInput!]!) {\n    updateMetadata(id: $id, input: $input) {\n      errors: metadataErrors {\n        code\n        field\n        __typename\n      }\n      item {\n        metadata {\n          key\n          value\n        }\n      }\n      __typename\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation ProductCreate($input: ProductCreateInput!) {\n    productCreate(input: $input) {\n      errors: productErrors {\n        code\n        field\n        __typename\n      }\n      product {\n        id\n        variants {\n          id\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
export var createSimpleProduct = gql(_templateObject());
export var updateMetadata = gql(_templateObject2());
export var TypedCreateSimpleProduct = TypedMutation(createSimpleProduct);
export var TypedUpdateMetadata = TypedMutation(updateMetadata);