import React from "react";
import * as S from "./styles";
var activeDot = React.createElement(S.ActiveDot, null, React.createElement(S.Dot, {
  done: true
}));
var doneDot = React.createElement(S.Dot, {
  done: true
});
var inactiveDot = React.createElement(S.Dot, null);

var generateDot = function generateDot(stepIndex, currentActiveStep) {
  if (stepIndex < currentActiveStep) {
    return doneDot;
  }

  if (stepIndex === currentActiveStep) {
    return activeDot;
  }

  if (stepIndex > currentActiveStep) {
    return inactiveDot;
  }
};

var generateLabel = function generateLabel(stepIndex, name, numberOfSteps) {
  if (stepIndex === 0) {
    return React.createElement(S.LeftLabel, null, name);
  }

  if (stepIndex === numberOfSteps - 1) {
    return React.createElement(S.RightLabel, null, name);
  }

  return React.createElement(S.Label, null, name);
};

var generateProgressBar = function generateProgressBar(index, currentActive, numberOfSteps) {
  if (index !== numberOfSteps - 1) {
    return React.createElement(S.ProgressBar, {
      done: currentActive > index
    });
  }
};

var generateSteps = function generateSteps(steps, currentActive, setCurrentStep) {
  return steps === null || steps === void 0 ? void 0 : steps.map(function (step, index) {
    var name = step.name;
    /* eslint-disable default-case */

    switch (step.name) {
      case "Address":
        name = "Хаяг";
        break;

      case "Payment":
        name = "Төлбөр";
        break;

      case "Review":
        name = "Хянах";
        break;
    }

    return React.createElement(S.Step, {
      key: step.index
    }, React.createElement("span", {
      onClick: function onClick() {
        return step.active ? setCurrentStep(step.index) : false;
      }
    }, generateDot(index, currentActive), generateLabel(index, name, steps.length)), generateProgressBar(index, currentActive, steps.length));
  });
};

var Navigation = function Navigation(_ref) {
  var currentStep = _ref.currentStep,
      setCurrentStep = _ref.setCurrentStep,
      steps = _ref.steps;
  return React.createElement(S.WrapperNav, null, generateSteps(steps, currentStep, setCurrentStep));
};

export default Navigation;