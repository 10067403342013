import React from "react";
import { IconButton } from "@components/atoms";
import * as S from "./styles";
export var CardHeaderSafari = function CardHeaderSafari(_ref) {
  var children = _ref.children,
      customIcon = _ref.customIcon,
      _ref$divider = _ref.divider,
      divider = _ref$divider === void 0 ? false : _ref$divider,
      onHide = _ref.onHide,
      _ref$textStyle = _ref.textStyle,
      textStyle = _ref$textStyle === void 0 ? "title" : _ref$textStyle,
      _ref$titleSize = _ref.titleSize,
      titleSize = _ref$titleSize === void 0 ? "md" : _ref$titleSize;
  var withCloseIcon = !!onHide && !customIcon;
  return React.createElement(S.Header, {
    divider: divider
  }, textStyle === "title" ? React.createElement(S.Title, {
    size: titleSize
  }, children) : React.createElement(S.Paragraph, null, children), withCloseIcon && React.createElement(IconButton, {
    name: "x",
    size: 19,
    onClick: onHide,
    testingContext: "closeOverlayButton"
  }), customIcon);
};