import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  query UshopList($ushopCount: Int) {\n    ushops(\n      first: $ushopCount\n      filter: { isPublished: true }\n      sortBy: { field: NAME, direction: ASC }\n    ) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query LatestProducts($after: String, $pageSize: Int, $ushopId: ID) {\n    products(\n      after: $after\n      first: $pageSize\n      sortBy: { direction: ASC, field: PRICE }\n      filter: { ushop: $ushopId }\n    ) {\n      totalCount\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n          category {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProductsList {\n    shop {\n      description\n      name\n      homepageCollection {\n        id\n        backgroundImage {\n          url\n        }\n        name\n      }\n    }\n    categories(\n      level: 0\n      first: 2\n      filter: { ids: [\"Q2F0ZWdvcnk6OQ==\", \"Q2F0ZWdvcnk6OA==\"] }\n      sortBy: { field: NAME, direction: DESC }\n    ) {\n      edges {\n        node {\n          id\n          name\n          backgroundImage {\n            url\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../Product/queries";
export var homePageQuery = gql(_templateObject());
export var TypedHomePageQuery = TypedQuery(homePageQuery);
export var latestProductsQuery = gql(_templateObject2(), basicProductFragment, productPricingFragment);
export var TypedLatestProductsQuery = TypedQuery(latestProductsQuery);
var ushopList = gql(_templateObject3());
export var TypedUshopList = TypedQuery(ushopList);