import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Loader } from "@components/atoms";
import { PayHistoryTable } from "@components/organisms/PayHistoryTable";
import React from "react";
import DatePickers from "./components/DatePickers";
import { TypedPaymentHistoriesByUser } from "./query";
import * as S from "./styles";
var PAYMENT_HISTORIES_PER_APICALL = 10;

var PayHistory = function PayHistory(_ref) {
  var history = _ref.history;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      load = _React$useState2[0],
      setLoad = _React$useState2[1];

  var _React$useState3 = React.useState({
    start: null,
    end: null
  }),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      dateRange = _React$useState4[0],
      setDateRange = _React$useState4[1];

  return React.createElement(TypedPaymentHistoriesByUser, {
    variables: {
      first: PAYMENT_HISTORIES_PER_APICALL,
      after: null,
      gte: (dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) !== "NaN-NaN-NaN" ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.start : null,
      lte: (dateRange === null || dateRange === void 0 ? void 0 : dateRange.end) !== "NaN-NaN-NaN" ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.end : null
    },
    fetchPolicy: "network-only"
  }, function (_ref2) {
    var _data$me, _data$me2, _data$me2$payments, _data$me2$payments$pa;

    var data = _ref2.data,
        loading = _ref2.loading,
        loadMore = _ref2.loadMore;
    if (loading && !data) return React.createElement(Loader, null);
    var paymentHistories = data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.payments;
    var hasNextPage = data === null || data === void 0 ? void 0 : (_data$me2 = data.me) === null || _data$me2 === void 0 ? void 0 : (_data$me2$payments = _data$me2.payments) === null || _data$me2$payments === void 0 ? void 0 : (_data$me2$payments$pa = _data$me2$payments.pageInfo) === null || _data$me2$payments$pa === void 0 ? void 0 : _data$me2$payments$pa.hasNextPage;
    return React.createElement(React.Fragment, null, React.createElement(S.Wrapper, null, React.createElement(DatePickers, {
      setDateRange: setDateRange
    })), React.createElement(PayHistoryTable, {
      payHistories: paymentHistories === null || paymentHistories === void 0 ? void 0 : paymentHistories.edges,
      history: history
    }), hasNextPage && React.createElement(S.Wrapper, null, React.createElement(S.Tovch, null, React.createElement("button", {
      onClick: function onClick() {
        setLoad(true);
        loadMore(function (prev, next) {
          var _prev$me, _prev$me$payments, _next$me, _next$me$payments, _next$me2, _next$me2$payments;

          setLoad(false);
          return {
            me: {
              __typename: "User",
              id: "dd",
              payments: {
                __typename: "PaymentCountableConnection",
                edges: [].concat(_toConsumableArray(((_prev$me = prev.me) === null || _prev$me === void 0 ? void 0 : (_prev$me$payments = _prev$me.payments) === null || _prev$me$payments === void 0 ? void 0 : _prev$me$payments.edges) || []), _toConsumableArray(((_next$me = next.me) === null || _next$me === void 0 ? void 0 : (_next$me$payments = _next$me.payments) === null || _next$me$payments === void 0 ? void 0 : _next$me$payments.edges) || [])),
                pageInfo: (next === null || next === void 0 ? void 0 : (_next$me2 = next.me) === null || _next$me2 === void 0 ? void 0 : (_next$me2$payments = _next$me2.payments) === null || _next$me2$payments === void 0 ? void 0 : _next$me2$payments.pageInfo) || {
                  __typename: "PageInfo",
                  hasNextPage: false,
                  endCursor: null
                }
              }
            }
          };
        }, {
          after: (paymentHistories === null || paymentHistories === void 0 ? void 0 : paymentHistories.pageInfo.endCursor) || null
        });
      }
    }, load ? "Loading..." : React.createElement(S.ButtonText, {
      style: {
        fontSize: "0.85rem"
      }
    }, "Load more")))));
  });
};

export default PayHistory;