import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query SearchBySKUResults($sku: String!) {\n    productVariant(sku: $sku) {\n      id\n      name\n      sku\n      product {\n        id\n        name\n        metadata {\n          key\n          value\n        }\n        thumbnail {\n          url\n          alt\n        }\n        thumbnail2x: thumbnail(size: 510) {\n          url\n        }\n        category {\n          id\n          name\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query SearchResults($query: String!) {\n    products(filter: { search: $query }, first: 20) {\n      edges {\n        node {\n          id\n          name\n          metadata {\n            key\n            value\n          }\n          thumbnail {\n            url\n            alt\n          }\n          thumbnail2x: thumbnail(size: 510) {\n            url\n          }\n          category {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../../core/queries";
var searchResultsQuery = gql(_templateObject());
export var TypedSearchResults = TypedQuery(searchResultsQuery);
var searchBySKUResultsQuery = gql(_templateObject2());
export var TypedSearchBySKUResults = TypedQuery(searchBySKUResultsQuery);