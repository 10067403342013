import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  a {\n    color: #190c4e;\n    text-decoration: underline;\n    font-size: 0.825rem;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  button {\n    color: #190c4e;\n    text-decoration: underline;\n    font-size: 0.825rem;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 0.5rem;\n  margin-bottom: 1rem;\n  align-items: center;\n\n  .ushop-logo {\n    width: 2rem;\n    height: 2rem;\n    border-radius: 1rem;\n    overflow: hidden;\n\n    img {\n      max-width: 100%;\n    }\n  }\n\n  h3 {\n    font-size: 1rem;\n    font-weight: bold;\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1rem;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.1rem;\n  margin-bottom: 1rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  h2 {\n    font-weight: bold;\n    font-size: 1.5rem;\n    margin-bottom: 0.75rem;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    display: none;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  img {\n    max-width: 100%;\n  }\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    flex-direction: row;\n    width: ", ";\n\n    button { \n      width: 100%;\n      padding: 0px;\n      border: none;\n  \n    }\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n\n  button {\n    padding: 5px;\n    border: 1px solid #eee;\n\n    img {\n      max-width: 100%;\n    }\n  }\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    overflow-x: auto;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr 5fr;\n  gap: 1rem;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  gap: 1rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles";
export var ProductDetails = styled.div(_templateObject(), media.smallScreen(_templateObject2()));
export var ProductImages = styled.div(_templateObject3(), media.smallScreen(_templateObject4()));
export var ProductThumbnails = styled.div(_templateObject5(), media.smallScreen(_templateObject6(), function (props) {
  return "".concat((props.dd * 100).toString(), "%");
}));
export var ProductImage = styled.div(_templateObject7(), media.smallScreen(_templateObject8()));
export var ProductInfo = styled.div(_templateObject9());
export var Price = styled.div(_templateObject10());
export var Size = styled.div(_templateObject11());
export var RealLink = styled.div(_templateObject12());
export var UnurshopLink = styled.div(_templateObject13());
export var ShopLink = styled.div(_templateObject14());