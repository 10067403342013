import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { OrderTabel } from "@components/molecules";
import * as S from "./styles";
import { TypedOrdersByUser } from "./query";
var ORDERS_PER_APICALL = 10;
export var OrdersHistory = function OrdersHistory(_ref) {
  var history = _ref.history;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      load = _React$useState2[0],
      setLoad = _React$useState2[1];

  return React.createElement(TypedOrdersByUser, {
    variables: {
      perPage: ORDERS_PER_APICALL
    },
    fetchPolicy: "network-only"
  }, function (_ref2) {
    var _data$me;

    var data = _ref2.data,
        loading = _ref2.loading,
        loadMore = _ref2.loadMore;
    if (loading && !data) return React.createElement(Loader, null);
    var orders = data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.orders;
    return React.createElement(React.Fragment, null, React.createElement(OrderTabel, {
      orders: orders === null || orders === void 0 ? void 0 : orders.edges,
      history: history
    }), (orders === null || orders === void 0 ? void 0 : orders.pageInfo.hasNextPage) && React.createElement(S.Wrapper, null, React.createElement(Button, {
      testingContext: "loadMoreOrdersButton",
      onClick: function onClick() {
        setLoad(true);
        loadMore(function (prev, next) {
          var _prev$me, _prev$me$orders, _next$me, _next$me$orders, _next$me2, _next$me2$orders;

          setLoad(false);
          return {
            me: {
              __typename: "User",
              id: "dd",
              orders: {
                __typename: "OrderCountableConnection",
                // ...prev.me?.orders,
                edges: [].concat(_toConsumableArray(((_prev$me = prev.me) === null || _prev$me === void 0 ? void 0 : (_prev$me$orders = _prev$me.orders) === null || _prev$me$orders === void 0 ? void 0 : _prev$me$orders.edges) || []), _toConsumableArray(((_next$me = next.me) === null || _next$me === void 0 ? void 0 : (_next$me$orders = _next$me.orders) === null || _next$me$orders === void 0 ? void 0 : _next$me$orders.edges) || [])),
                pageInfo: (next === null || next === void 0 ? void 0 : (_next$me2 = next.me) === null || _next$me2 === void 0 ? void 0 : (_next$me2$orders = _next$me2.orders) === null || _next$me2$orders === void 0 ? void 0 : _next$me2$orders.pageInfo) || {
                  __typename: "PageInfo",
                  hasNextPage: false,
                  endCursor: null
                }
              }
            }
          };
        }, {
          after: orders === null || orders === void 0 ? void 0 : orders.pageInfo.endCursor
        });
      }
    }, load ? "Loading..." : React.createElement(FormattedMessage, {
      id: "@next.pages.OrdersHistory.OrdersHistory.1138265409",
      defaultMessage: "Load more"
    }))));
  });
};