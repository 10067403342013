import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "unurshop-sdk";
import { OverlayContext, OverlayTheme, OverlayType } from "@temp/components/Overlay/context";
import { commonMessages } from "@temp/intl";
import { getSalePercent, getWasPrice } from "@temp/views/Product/utils";
import { Money } from "../../containers";
import AddToCartButton from "../../molecules/AddToCartButton";
import QuantityInput from "../../molecules/QuantityInput";
import ProductVariantPicker from "../ProductVariantPicker";
import ShippingPicker from "../ShippingPicker";
import { canAddToCart, getAvailableQuantity, getProductPrice } from "./stockHelpers";
import * as S from "./styles";
var LOW_STOCK_QUANTITY = 5;

var AddToCartSection = function AddToCartSection(_ref) {
  var _productPricing$price, _productPricing$price2, _productPricing$price3;

  var availableForPurchase = _ref.availableForPurchase,
      isAvailableForPurchase = _ref.isAvailableForPurchase,
      items = _ref.items,
      name = _ref.name,
      product = _ref.product,
      productPricing = _ref.productPricing,
      productVariants = _ref.productVariants,
      queryAttributes = _ref.queryAttributes,
      onAddToCart = _ref.onAddToCart,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var overlayContext = React.useContext(OverlayContext);
  var wasPrice = getWasPrice(product);

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      variantId = _useState4[0],
      setVariantId = _useState4[1];

  var _useState5 = useState(0),
      _useState6 = _slicedToArray(_useState5, 2),
      variantStock = _useState6[0],
      setVariantStock = _useState6[1];

  var _useState7 = useState(null),
      _useState8 = _slicedToArray(_useState7, 2),
      variantPricing = _useState8[0],
      setVariantPricing = _useState8[1];

  var availableQuantity = getAvailableQuantity(items, variantId, variantStock);
  var isOutOfStock = !!variantId && variantStock === 0;
  var noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  var purchaseAvailableDate = !isAvailableForPurchase && availableForPurchase && Date.parse(availableForPurchase);
  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity;
  var isLowStock = !!variantId && !isOutOfStock && !isNoItemsAvailable && availableQuantity < LOW_STOCK_QUANTITY;
  var disableButton = !canAddToCart(items, !!isAvailableForPurchase, variantId, variantStock, quantity);

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage" // data-testId={testingContextId}

    }, message);
  };

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
  };

  return React.createElement(S.AddToCartSelection, null, React.createElement(S.ProductNameHeader, {
    "data-test": "productName"
  }, name), productPricing && getSalePercent(wasPrice, (_productPricing$price = productPricing.priceRange) === null || _productPricing$price === void 0 ? void 0 : _productPricing$price.start) && React.createElement(S.SalePercent, null, getSalePercent(wasPrice, (_productPricing$price2 = productPricing.priceRange) === null || _productPricing$price2 === void 0 ? void 0 : (_productPricing$price3 = _productPricing$price2.start) === null || _productPricing$price3 === void 0 ? void 0 : _productPricing$price3.gross.amount), " ", "\u0445\u044F\u043C\u0434\u0440\u0430\u043B"), isOutOfStock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : React.createElement(S.ProductPricing, null, getProductPrice(productPricing, variantPricing), "\xA0\xA0", wasPrice && React.createElement(React.Fragment, null, React.createElement(S.UndiscountedPrice, null, React.createElement("span", {
    style: {
      textDecoration: "line-through"
    }
  }, React.createElement(Money, {
    money: {
      amount: parseFloat(wasPrice),
      currency: "GBP"
    }
  }))))), noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
    date: new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(purchaseAvailableDate),
    time: new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric"
    }).format(purchaseAvailableDate)
  }), "timeRestrictedAvailability"), isLowStock && renderErrorMessage(intl.formatMessage(commonMessages.lowStock), "lowStockWarning"), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noItemsAvailable), "noItemsAvailable"), React.createElement(S.VariantPicker, null, React.createElement(ProductVariantPicker, {
    productVariants: productVariants,
    onChange: onVariantPickerChange,
    selectSidebar: true,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  })), productVariants.length > 0 && React.createElement(S.QuantityInput, null, React.createElement(QuantityInput, {
    quantity: quantity,
    maxQuantity: availableQuantity,
    disabled: isOutOfStock || isNoItemsAvailable,
    onQuantityChange: setQuantity,
    hideErrors: !variantId || isOutOfStock || isNoItemsAvailable,
    testingContext: "addToCartQuantity"
  })), false && React.createElement(S.ShippingPicker, null, React.createElement(ShippingPicker, {
    productVariants: productVariants,
    onChange: onVariantPickerChange,
    selectSidebar: true,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  })), productVariants.length === 0 && renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock"), React.createElement(AddToCartButton, {
    onSubmit: user ? function () {
      return onAddToCart(variantId, quantity);
    } : function () {
      return overlayContext.show(OverlayType.login, OverlayTheme.right);
    },
    disabled: disableButton
  }));
};

AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;