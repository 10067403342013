import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import Address from "./components/Address";
import Navigation from "./components/Navigation";
import OrderSummary from "./components/OrderSummary";
import Payment from "./components/Payment";
import Review from "./components/Review";
import * as S from "./styles";
import { TypedOrderPaymentCreate, TypedOrderWalletTransactionCreate, TypedDraftOrderCompleteWithPayment } from "./query";

var DraftOrder = function DraftOrder(_ref) {
  var order = _ref.order,
      refetchOrder = _ref.refetchOrder;

  var getActiveStepIndex = function getActiveStepIndex(order) {
    var sorted_payments = (order === null || order === void 0 ? void 0 : order.payments.reverse()) || [];
    var active_payment = sorted_payments.find(function (p) {
      return p.isActive === true && // p.gateway === "unurshop.payments.qpay" &&
      p.chargeStatus === "NOT_CHARGED";
    });

    if (!(order === null || order === void 0 ? void 0 : order.shippingAddress)) {
      return 0;
    }

    if (active_payment) {
      return 2;
    }

    return !(order === null || order === void 0 ? void 0 : order.shippingAddress) ? 0 : 1;
  };

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      currentStep = _React$useState2[0],
      setCurrentStep = _React$useState2[1];

  var renderPage = function renderPage(step, orderPaymentCreate, orderWalletTransactionCreate, draftOrderCompleteWithPayment) {
    switch (step) {
      case 0:
        return React.createElement(Address, {
          order: order,
          setCurrentStep: setCurrentStep,
          refetchOrder: refetchOrder
        });

      case 1:
        return React.createElement(Payment, {
          order: order,
          orderPaymentCreate: orderPaymentCreate,
          orderWalletTransactionCreate: orderWalletTransactionCreate
        });

      case 2:
        return React.createElement(Review, {
          order: order,
          refetchOrder: refetchOrder,
          draftOrderCompleteWithPayment: draftOrderCompleteWithPayment,
          setCurrentStep: setCurrentStep
        });

      default:
        return React.createElement(Address, {
          order: order,
          setCurrentStep: setCurrentStep,
          refetchOrder: refetchOrder
        });
    }
  };

  React.useEffect(function () {
    setCurrentStep(getActiveStepIndex(order));
  }, [order]);
  var steps = [{
    active: true,
    index: 0,
    name: "Address"
  }, {
    active: true,
    index: 1,
    name: "Payment"
  }, {
    active: true,
    index: 2,
    name: "Review"
  }];
  return React.createElement(TypedDraftOrderCompleteWithPayment, {
    onCompleted: function onCompleted() {
      refetchOrder();
    }
  }, function (draftOrderCompleteWithPayment) {
    return React.createElement(TypedOrderWalletTransactionCreate, null, function (orderWalletTransactionCreate) {
      return React.createElement(TypedOrderPaymentCreate, {
        onCompleted: function onCompleted() {
          refetchOrder();
        }
      }, function (orderPaymentCreate) {
        return React.createElement(S.Wrapper, null, React.createElement(S.Navigation, null, React.createElement(Navigation, {
          currentStep: currentStep,
          setCurrentStep: setCurrentStep,
          steps: steps
        })), React.createElement(S.Checkout, null, renderPage(currentStep, orderPaymentCreate, orderWalletTransactionCreate, draftOrderCompleteWithPayment)), React.createElement(S.CartSummary, null, React.createElement(OrderSummary, {
          order: order
        })));
      });
    });
  });
};

export default DraftOrder;