import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";

var ForgottenPassword = function ForgottenPassword(_ref) {
  var onClick = _ref.onClick;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "login__content__password-reminder"
  }, React.createElement("p", null, React.createElement(FormattedMessage, commonMessages.forgottenPassword), " ", React.createElement("span", {
    className: "u-link",
    onClick: onClick,
    "data-test": "accountOverlayForgottenPasswordLink"
  }, React.createElement(FormattedMessage, commonMessages.clickHere)))));
};

export default ForgottenPassword;