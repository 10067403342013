import "./scss/index.scss";
import * as React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg"; // import userImg from '../../images/user.svg';

import * as appPaths from "../../app/routes"; // import { Trans } from '@lingui/react';
// import { maybe } from '../../core/utils';

import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import svgCargo from "../../images/unurshop/icons/cargo.svg";
import svgOrdershop from "../../images/unurshop/icons/ordershop.svg";
import svgPart from "../../images/unurshop/icons/part.svg";
import svgRedLogo from "../../images/unurshop/icons/red-logo.svg";
import svgUuruu from "../../images/unurshop/icons/uuruu.svg"; // import NavDropdown from './NavDropdown';

var FooterMainMenu = function FooterMainMenu() {
  return React.createElement(Media, {
    query: {
      maxWidth: mediumScreen
    },
    render: function render() {
      return React.createElement("div", {
        className: "footer-mainmenu"
      }, React.createElement("div", {
        className: "fmenu-left"
      }, React.createElement("ul", null, React.createElement("li", null, React.createElement(Link, {
        to: appPaths.baseUrl
      }, React.createElement(ReactSVG, {
        path: svgOrdershop,
        className: "uicon"
      }), React.createElement("span", null, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u0430"))), React.createElement("li", null, React.createElement(Link, {
        to: appPaths.baseUrl
      }, React.createElement(ReactSVG, {
        path: svgPart,
        className: "uicon"
      }), React.createElement("span", null, "\u0421\u044D\u043B\u0431\u044D\u0433"))))), React.createElement("div", {
        className: "fmenu-center"
      }, React.createElement(Link, {
        to: appPaths.baseUrl,
        className: "mobile-ulogo"
      }, React.createElement(ReactSVG, {
        path: svgRedLogo,
        className: "icon-symbol"
      }))), React.createElement("div", {
        className: "fmenu-right"
      }, React.createElement("ul", null, React.createElement("li", null, React.createElement(Link, {
        to: appPaths.baseUrl
      }, React.createElement(ReactSVG, {
        path: svgUuruu,
        className: "uicon"
      }), React.createElement("span", null, "\u04E8\u04E9\u0440\u04E9\u04E9 \u0430\u0432\u0430\u0445"))), React.createElement("li", null, React.createElement(Link, {
        to: appPaths.baseUrl
      }, React.createElement(ReactSVG, {
        path: svgCargo,
        className: "uicon"
      }), React.createElement("span", null, "\u041A\u0430\u0440\u0433\u043E"))))));
    }
  });
};

export default FooterMainMenu;