/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 12;
export var SUPPORT_EMAIL = "lkhagva.w8@gmail.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  STRIPE: {
    label: "Stripe"
  },
  QPAY: {
    label: "QPay"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  }
};
export var STATIC_PAGES = [{
  label: "Бидний тухай",
  url: generatePageUrl("about")
}, {
  label: "Холбоо барих",
  url: generatePageUrl("contact-us")
}, {
  label: "Асуулт хариулт",
  url: generatePageUrl("faq")
}, {
  label: "Захиалгын журам",
  url: generatePageUrl("term-condition")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook",
  href: "https://www.facebook.com/unurshop.mn/",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "instagram",
  href: "https://www.instagram.com/unurshop_uk_n01/",
  path: require("../images/instagram-icon.svg")
}, {
  ariaLabel: "twitter",
  href: "https://twitter.com/unurshopmn",
  path: require("../images/twitter-icon.svg")
}, {
  ariaLabel: "youtube",
  href: "https://www.youtube.com/channel/UCmop8soQqOOUP5h-JFN59RA/videos",
  path: require("../images/youtube-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "Unurshop Англи бараа захиалга",
  image: "".concat(window.location.origin).concat(require("../images/logo.svg")),
  title: "Англи бараа захиалга – UNURSHOP",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["UKShipping"] = 3] = "UKShipping";
  CheckoutStep[CheckoutStep["Payment"] = 4] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 5] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 6] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Хаяг",
  nextActionName: "Continue to Shipping",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, // {
//   index: 1,
//   link: "/checkout/shipping",
//   name: "Тээвэр",
//   nextActionName: "Continue to Payment",
//   onlyIfShippingRequired: true,
//   step: CheckoutStep.Shipping,
// },
{
  index: 1,
  link: "/checkout/uk-shipping",
  name: "Англи дахь хүргэлт",
  nextActionName: "Үргэлжлүүлэх",
  onlyIfShippingRequired: false,
  step: CheckoutStep.UKShipping
}, {
  index: 2,
  link: "/checkout/payment",
  name: "Төлбөр",
  nextActionName: "Continue to Review",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  link: "/checkout/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];