import React from "react";
import { ButtonLink } from "@components/atoms";
import { Money } from "@components/containers";
import { CardHeader, OverlayItem } from "@components/molecules";
import { useHandlerWhenClickedOutside } from "@hooks";
import { Overlay } from "..";
import * as S from "./styles";
export var SelectShippingSidebar = function SelectShippingSidebar(_ref) {
  var title = _ref.title,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? [] : _ref$options,
      _ref$disabledOptions = _ref.disabledOptions,
      disabledOptions = _ref$disabledOptions === void 0 ? [] : _ref$disabledOptions,
      _ref$selectedOptions = _ref.selectedOptions,
      selectedOptions = _ref$selectedOptions === void 0 ? [] : _ref$selectedOptions,
      hide = _ref.hide,
      onSelect = _ref.onSelect,
      show = _ref.show,
      target = _ref.target,
      footerTitle = _ref.footerTitle,
      onClickFooter = _ref.onClickFooter,
      testingContextId = _ref.testingContextId;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    hide();
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  return React.createElement(Overlay, {
    position: "right",
    duration: 0,
    show: show,
    hide: hide,
    transparent: true,
    target: target,
    testingContext: "attributeSelection",
    testingContextId: testingContextId
  }, React.createElement(S.Wrapper, {
    ref: setElementRef()
  }, React.createElement(CardHeader, {
    divider: true,
    onHide: hide
  }, React.createElement("span", null, title), React.createElement(S.TitleDescription, null, "\u0422\u044D\u044D\u0432\u044D\u0440\u043B\u044D\u043B\u0442\u0438\u0439\u043D \u043D\u044D\u043C\u044D\u043B\u0442 \u0442\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u0433 \u0442\u0430 \u0438\u043B\u0433\u044D\u044D\u043C\u0436\u044D\u044D \u0430\u0432\u0430\u0445\u0434\u0430\u0430 \u0442\u04E9\u043B\u043D\u04E9")), React.createElement(S.Content, null, options.map(function (option) {
    var isSelected = selectedOptions.some(function (value) {
      return value === option.value;
    });
    var isDisabled = disabledOptions.some(function (value) {
      return value === option.value;
    });
    return React.createElement(S.Option, {
      key: option.value,
      disabled: isDisabled
    }, React.createElement(OverlayItem, {
      testingContextId: option.value,
      selected: isSelected,
      disabled: isDisabled,
      onClick: function onClick() {
        return onSelect(option.value);
      }
    }, React.createElement(S.OptionContent, null, React.createElement(S.OptionTitle, null, React.createElement("input", {
      type: "radio",
      name: "ShippingType",
      value: "ShippingType"
    }), "\xA0", option.label), React.createElement(S.OptionItem, null, option.id === "air" ? React.createElement(React.Fragment, null, React.createElement(S.OptionItemItems, null, React.createElement("span", null, "1\u043A\u0433 \u0445\u04AF\u0440\u0442\u043B\u044D\u0445"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 11,
        currency: "GBP"
      }
    }))), React.createElement(S.OptionItemItems, null, React.createElement("span", null, "1\u043A\u0433-\u0441 \u0434\u044D\u044D\u0448"), React.createElement("span", null, "100 \u0433\u0440\u0430\u043C\u043C \u0442\u0443\u0442\u0430\u043C\u0434\xA0", React.createElement(Money, {
      money: {
        amount: 1.1,
        currency: "GBP"
      }
    }))), React.createElement(S.Line, null), React.createElement("div", null, React.createElement(S.DateDescription, null, "\u0414\u0430\u0440\u0430\u0430\u0433\u0438\u0439\u043D \u0430\u0447\u0430\u0430 \u0445\u04E9\u0434\u043B\u04E9\u0445 \u043E\u0433\u043D\u043E\u043E"), React.createElement(S.Ognoo, null, "2022/06/29"))) : React.createElement(React.Fragment, null, React.createElement(S.OptionItemItems, null, React.createElement("span", null, "1 - 10 \u043A\u0433"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 4,
        currency: "GBP"
      }
    }))), React.createElement(S.OptionItemItems, null, React.createElement("span", null, "11 - 30 \u043A\u0433"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 3.5,
        currency: "GBP"
      }
    }))), React.createElement(S.OptionItemItems, null, React.createElement("span", null, "31 - 60 \u043A\u0433"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 3,
        currency: "GBP"
      }
    }))), React.createElement(S.OptionItemItems, null, React.createElement("span", null, "61 - 100 \u043A\u0433"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 2.5,
        currency: "GBP"
      }
    }))), React.createElement(S.OptionItemItems, null, React.createElement("span", null, "101 - 150 \u043A\u0433"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 2,
        currency: "GBP"
      }
    }))), React.createElement(S.OptionItemItems, null, React.createElement("span", null, "150 \u043A\u0433-\u0441 \u0434\u044D\u044D\u0448"), React.createElement("span", null, React.createElement(Money, {
      money: {
        amount: 1.5,
        currency: "GBP"
      }
    }))), React.createElement(S.Line, null), React.createElement("div", null, React.createElement(S.DateDescription, null, "\u0414\u0430\u0440\u0430\u0430\u0433\u0438\u0439\u043D \u0430\u0447\u0430\u0430 \u0445\u04E9\u0434\u043B\u04E9\u0445 \u043E\u0433\u043D\u043E\u043E"), React.createElement(S.Ognoo, null, "2022/07/02")))))));
  })), footerTitle && React.createElement(S.Footer, {
    onClick: onClickFooter
  }, React.createElement(ButtonLink, {
    testingContext: "footerActionButton",
    color: "secondary"
  }, footerTitle))));
};