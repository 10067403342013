import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";

var AddressSummary = function AddressSummary(_ref) {
  var address = _ref.address,
      email = _ref.email;

  if (address) {
    var _address$country, _address$country2;

    return React.createElement("div", null, React.createElement("h2", null, "\u0425\u04AF\u043B\u044D\u044D\u043D \u0430\u0432\u0430\u0445 \u0445\u0430\u044F\u0433"), React.createElement("div", {
      className: "addressSection"
    }, React.createElement("div", {
      style: {
        marginBottom: "0.5rem"
      }
    }, React.createElement("strong", null, "".concat(address.firstName, " ").concat(address.lastName))), React.createElement("div", null, address.phone && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, _extends({}, commonMessages.phoneNumber, {
      values: {
        phone: address.phone
      }
    })), React.createElement("br", null)), address.streetAddress2 && React.createElement(React.Fragment, null, "\u0420\u0414: ", address.streetAddress2, React.createElement("br", null)), address.companyName && React.createElement(React.Fragment, null, address.companyName, " ", React.createElement("br", null)), address.streetAddress1 && React.createElement(React.Fragment, null, address.streetAddress1, React.createElement("br", null)), address.countryArea && React.createElement(React.Fragment, null, address.countryArea, React.createElement("br", null)), address.city && React.createElement(React.Fragment, null, address.city, ", ", address.postalCode, React.createElement("br", null)), ((_address$country = address.country) === null || _address$country === void 0 ? void 0 : _address$country.country) && React.createElement(React.Fragment, null, (_address$country2 = address.country) === null || _address$country2 === void 0 ? void 0 : _address$country2.country, React.createElement("br", null)), email && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, _extends({}, commonMessages.showEmail, {
      values: {
        email: email
      }
    }))))));
  }

  if (email) {
    return React.createElement(React.Fragment, null, email);
  }

  return null;
};

export { AddressSummary };