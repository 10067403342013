import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Thumbnail } from "@components/molecules";
import { IssueStatus } from "gqlTypes/globalTypes";
import React from "react";
import { TypedIssueAddChat, TypedIssueUpdate } from "../../query";

var IssueDialog = function IssueDialog(props) {
  var issue = props.issue,
      refetchOrder = props.refetchOrder,
      line = props.line,
      setRefetchChat = props.setRefetchChat;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loading = _React$useState2[0],
      setLoading = _React$useState2[1];

  var renderLineInfo = function renderLineInfo(line) {
    var _line$variant, _line$variant2, _line$variant2$produc, _line$variant3, _line$variant3$attrib;

    return React.createElement("div", {
      className: "product-info",
      style: {
        marginBottom: "0.5rem"
      }
    }, React.createElement(Thumbnail, {
      source: line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.product
    }), React.createElement("div", {
      className: "product-name-size"
    }, React.createElement("b", null, line === null || line === void 0 ? void 0 : (_line$variant2 = line.variant) === null || _line$variant2 === void 0 ? void 0 : (_line$variant2$produc = _line$variant2.product) === null || _line$variant2$produc === void 0 ? void 0 : _line$variant2$produc.name), line === null || line === void 0 ? void 0 : (_line$variant3 = line.variant) === null || _line$variant3 === void 0 ? void 0 : (_line$variant3$attrib = _line$variant3.attributes) === null || _line$variant3$attrib === void 0 ? void 0 : _line$variant3$attrib.map(function (_ref, attributeIndex) {
      var attribute = _ref.attribute,
          values = _ref.values;
      return React.createElement("p", {
        key: "product-attribute-".concat(attribute === null || attribute === void 0 ? void 0 : attribute.id, "-").concat(attributeIndex)
      }, attribute === null || attribute === void 0 ? void 0 : attribute.name, ": ", values.map(function (value) {
        return value === null || value === void 0 ? void 0 : value.name;
      }).join(", "));
    })));
  };

  return React.createElement(React.Fragment, null, React.createElement(TypedIssueUpdate, {
    onCompleted: function onCompleted() {
      return setRefetchChat(true);
    }
  }, function (issueUpdate) {
    return React.createElement(TypedIssueAddChat, {
      onCompleted: refetchOrder
    }, function (issueAddNote) {
      return React.createElement("div", {
        className: "issue-alert"
      }, !line && React.createElement("div", {
        className: "issue-alert__errorSymbol"
      }, "!"), React.createElement("div", {
        style: {
          width: "100%"
        }
      }, line && renderLineInfo(line), !line && React.createElement("h6", {
        className: "issue-alert__title"
      }, issue === null || issue === void 0 ? void 0 : issue.name), React.createElement("div", {
        className: "issue-alert__content"
      }, React.createElement("span", null, issue === null || issue === void 0 ? void 0 : issue.description), React.createElement("div", {
        className: "issue-button"
      }, React.createElement("button", {
        className: "issue-button__issueYesButton",
        disabled: loading,
        onClick: function onClick() {
          var _issue$parent;

          setLoading(true);
          issueAddNote({
            variables: {
              issue: issue === null || issue === void 0 ? void 0 : issue.id,
              input: {
                message: "Авна"
              }
            }
          });
          issueUpdate({
            variables: {
              id: issue === null || issue === void 0 ? void 0 : issue.id,
              input: {
                status: IssueStatus.DONE
              }
            }
          });
          issueUpdate({
            variables: {
              id: issue === null || issue === void 0 ? void 0 : (_issue$parent = issue.parent) === null || _issue$parent === void 0 ? void 0 : _issue$parent.id,
              input: {
                status: IssueStatus.TODO
              }
            }
          });
        }
      }, "\u0410\u0432\u043D\u0430"), React.createElement("button", {
        className: "issue-button__issueNoButton",
        disabled: loading,
        onClick: function onClick() {
          var _issue$parent2;

          setLoading(true);
          issueAddNote({
            variables: {
              issue: issue === null || issue === void 0 ? void 0 : issue.id,
              input: {
                message: "Авахгүй"
              }
            }
          });
          issueUpdate({
            variables: {
              id: issue === null || issue === void 0 ? void 0 : issue.id,
              input: {
                status: IssueStatus.DONE
              }
            }
          });
          issueUpdate({
            variables: {
              id: issue === null || issue === void 0 ? void 0 : (_issue$parent2 = issue.parent) === null || _issue$parent2 === void 0 ? void 0 : _issue$parent2.id,
              input: {
                status: IssueStatus.TODO
              }
            }
          });
        }
      }, "\u0410\u0432\u0430\u0445\u0433\u04AF\u0439")))));
    });
  }));
};

export default IssueDialog;