import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import OrderDetailModal from "./OrderDetailModal";
export var OrderDetailManagerContext = React.createContext({
  openOrderDetailModal: function openOrderDetailModal() {},
  closeModal: function closeModal() {}
});

var OrderDetailManagerProvider = function OrderDetailManagerProvider(_ref) {
  var children = _ref.children;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var _React$useState3 = React.useState({
    line: undefined,
    testingContext: null
  }),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      orderDetailParams = _React$useState4[0],
      setOrderDetailParams = _React$useState4[1];

  var openOrderDetailModal = React.useCallback(function (params) {
    setOpen(true);
    setOrderDetailParams(params);
  }, []);

  var closeModal = function closeModal() {
    setOpen(false);
    setOrderDetailParams({
      line: undefined,
      testingContext: null
    });
  };

  return React.createElement(OrderDetailManagerContext.Provider, {
    value: {
      closeModal: closeModal,
      openOrderDetailModal: openOrderDetailModal
    }
  }, children, open && React.createElement(OrderDetailModal, {
    closeModal: closeModal,
    open: open,
    line: orderDetailParams.line,
    testingContext: null
  }));
};

export default OrderDetailManagerProvider;