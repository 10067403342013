import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query Wallet($userId: ID, $currency: String) {\n    wallet(userId: $userId, currency: $currency) {\n      id\n      balanceAmount\n      currency\n      balance {\n        amount\n        currency\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation CheckoutWalletTransactionCreate(\n    $checkoutId: ID!\n    $amount: PositiveDecimal!\n  ) {\n    checkoutWalletTransactionCreate(\n      checkoutId: $checkoutId\n      input: { amount: $amount }\n    ) {\n      walletTransaction {\n        id\n        credit\n        status\n      }\n      checkout {\n        id\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
import { TypedQuery } from "@temp/core/queries";
export var CheckoutWalletTransactionCreateDocument = gql(_templateObject());
export var TypedCheckoutWalletTransactionCreate = TypedMutation(CheckoutWalletTransactionCreateDocument);
export var CheckoutWallet = gql(_templateObject2());
export var TypedCheckoutWallet = TypedQuery(CheckoutWallet);