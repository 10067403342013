import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { PRODUCT_TYPE_SHIPPING } from "@temp/constants";
import ProductRow from "./ProductRowUnfulfilled";
import ShopRow from "./ShopRowUnfulfilled";
import "./scss/index.scss";

var TableUnfulfillmentMobile = function TableUnfulfillmentMobile(_ref) {
  var lines = _ref.lines,
      setModalOpen = _ref.setModalOpen,
      setLine = _ref.setLine,
      rowProps = _objectWithoutProperties(_ref, ["lines", "setModalOpen", "setLine"]);

  var ushops = [];
  var productLines = lines.filter(function (i) {
    var _i$variant$product;

    return ((_i$variant$product = i.variant.product) === null || _i$variant$product === void 0 ? void 0 : _i$variant$product.productType.id) !== PRODUCT_TYPE_SHIPPING;
  });
  var shippingUkLines = lines.filter(function (i) {
    var _i$variant$product2;

    return ((_i$variant$product2 = i.variant.product) === null || _i$variant$product2 === void 0 ? void 0 : _i$variant$product2.productType.id) === PRODUCT_TYPE_SHIPPING;
  });
  productLines.map(function (line) {
    var shop = ushops.find(function (u) {
      return u.id === line.variant.product.ushop.id;
    });

    if (!shop) {
      ushops.push(_objectSpread({}, line.variant.product.ushop, {
        lines: [line],
        ukShipping: shippingUkLines.find(function (i) {
          return line.variant.product.ushop.id === i.variant.product.ushop.id;
        })
      }));
    } else {
      shop.lines.push(line);
    }
  });
  return React.createElement("table", {
    className: "cart-table"
  }, ushops && ushops.map(function (ushop, index) {
    return React.createElement("tbody", {
      key: index
    }, React.createElement(ShopRow, {
      line: ushop
    }), ushop.lines.map(function (line, lineIndex) {
      return React.createElement(ProductRow, _extends({
        key: "".concat(ushop.id, "-").concat(lineIndex),
        line: line,
        setModalOpen: setModalOpen,
        setLine: setLine
      }, rowProps));
    }));
  }));
};

export default TableUnfulfillmentMobile;