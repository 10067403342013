import React from "react";
import Media from "react-responsive";
import { Button } from "@components/atoms";
import { mediumScreen } from "@styles/constants";
import { TypedQpayCheck } from "@temp/userAccount/views/OrderDetails/query";
import { MNT } from "@temp/constants";

var QPayRender = function QPayRender(_ref) {
  var payment = _ref.payment,
      orderNumber = _ref.orderNumber,
      totalAmount = _ref.totalAmount,
      refetchOrder = _ref.refetchOrder,
      setCurrentStep = _ref.setCurrentStep;
  var payment_json = JSON.parse(payment.extraData);
  var qpay_json = payment_json === null || payment_json === void 0 ? void 0 : payment_json.qpay_invoice;
  return React.createElement(TypedQpayCheck, {
    variables: {
      id: payment.id
    },
    fetchPolicy: "network-only"
  }, function (_ref2) {
    var loading = _ref2.loading,
        refetch = _ref2.refetch;

    // if (data.paymentQpayCheck?.chargeStatus) {
    //   alert.show(
    //     { title: "Төлбөр амжилттай төлөгдлөө" },
    //     { type: "success" }
    //   );
    //   // refetchOrder();
    // }
    var recheck = function recheck() {
      if (!loading) {
        refetch();
        refetchOrder();
      }
    }; // setLoadingBtn(false);


    return React.createElement("div", {
      className: ""
    }, React.createElement("div", {
      className: "ushop-title",
      style: {
        marginTop: "-16px",
        marginBottom: 32
      }
    }, React.createElement("h4", null, "QPAY \u041D\u042D\u0425\u042D\u041C\u0416\u041B\u042D\u0425"), React.createElement("h3", null, "\u041D\u042D\u0425\u042D\u041C\u0416\u041B\u042D\u0425 \u0414\u0423\u0413\u0410\u0410\u0420: #", orderNumber)), React.createElement("div", {
      style: {
        display: "flex",
        justifyContent: "center",
        fontSize: 18,
        marginBottom: 24
      }
    }, React.createElement("div", {
      style: {
        textAlign: "right",
        marginRight: 16
      }
    }, "\u041D\u044D\u0445\u044D\u043C\u0436\u043B\u044D\u0441\u044D\u043D \u0434\u04AF\u043D", totalAmount > payment.total.amount && React.createElement("span", {
      style: {
        fontSize: 14
      }
    }, React.createElement("br", null), "\u0423\u0440\u044C\u0434\u0447\u0438\u043B\u0433\u0430\u0430 30%", " ")), React.createElement("div", null, React.createElement("b", {
      style: {
        fontWeight: "bold"
      }
    }, "".concat((payment.total.amount * MNT).toLocaleString(), "\u20AE")), "\xA0", React.createElement("small", {
      style: {
        fontSize: 12
      }
    }, "/\xA0", payment.total.localized, "\xA0/"))), React.createElement(Media, {
      minWidth: mediumScreen
    }, qpay_json ? React.createElement("div", {
      style: {
        textAlign: "center"
      }
    }, React.createElement("img", {
      src: "data:image/png;base64, ".concat(qpay_json === null || qpay_json === void 0 ? void 0 : qpay_json.qr_image),
      alt: "Qpay",
      style: {
        marginBottom: 24
      }
    }), React.createElement("p", {
      style: {
        marginBottom: 16
      }
    }, "QPay \u0442\u04E9\u043B\u0431\u04E9\u0440 \u0442\u04E9\u043B\u0441\u04E9\u043D\u0438\u0439 \u0434\u0430\u0440\u0430\u0430 \u0434\u043E\u043E\u0440\u0445 \u0442\u043E\u0432\u0447\u0438\u043D ", React.createElement("br", null), "\u0434\u044D\u044D\u0440 \u0434\u0430\u0440\u0436 \u0442\u04E9\u043B\u0431\u04E9\u0440\u04E9\u04E9 \u0431\u0430\u0442\u0430\u0433\u0430\u0430\u0436\u0443\u0443\u043B\u0430\u0430\u0440\u0430\u0439"), React.createElement("div", {
      style: {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        justifyContent: "center"
      }
    }, React.createElement(Button, {
      color: "primary",
      size: "sm",
      testingContext: "qpay-check",
      onClick: recheck
    }, loading ? "Түр хүлээнэ үү" : "Төлбөр шалгах"), React.createElement(Button, {
      color: "secondary",
      size: "sm",
      testingContext: "review-back-btn",
      onClick: function onClick() {
        return setCurrentStep(1);
      }
    }, "\u0411\u0443\u0446\u0430\u0445"))) : React.createElement("p", null, "Undefined")), React.createElement(Media, {
      maxWidth: mediumScreen
    }, React.createElement("h4", {
      style: {
        marginTop: "2.5rem",
        marginBottom: "1.5rem",
        fontWeight: "bold"
      }
    }, "\u0422\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u043D\u04E9\u0445\u0446\u04E9\u043B\u04E9\u04E9 \u0441\u043E\u043D\u0433\u043E\u043D\u043E \u0443\u0443"), qpay_json && React.createElement("div", {
      className: "bank-buttoms"
    }, qpay_json === null || qpay_json === void 0 ? void 0 : qpay_json.urls.map(function (bank) {
      return React.createElement("a", {
        href: bank.link,
        key: bank.name
      }, React.createElement("img", {
        src: bank.logo,
        alt: bank.name
      }), bank.name);
    }))));
  });
};

export default QPayRender;