import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Loader } from "@components/atoms";
import { PaymentsHistoryTable } from "@components/organisms/PaymensHistoryTable";
import React from "react";
import DatePickers from "./components/DatePickers";
import { TypedWalletTransactionHistoriesByUser } from "./query";
import * as S from "./styles";
var PAYMENT_HISTORIES_PER_APICALL = 10;

var WalletTransactionHistory = function WalletTransactionHistory(_ref) {
  var history = _ref.history;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      load = _React$useState2[0],
      setLoad = _React$useState2[1];

  var _React$useState3 = React.useState({
    start: null,
    end: null
  }),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      dateRange = _React$useState4[0],
      setDateRange = _React$useState4[1];

  return React.createElement(TypedWalletTransactionHistoriesByUser, {
    variables: {
      first: PAYMENT_HISTORIES_PER_APICALL,
      after: null,
      gte: (dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) !== "NaN-NaN-NaN" ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.start : null,
      lte: (dateRange === null || dateRange === void 0 ? void 0 : dateRange.end) !== "NaN-NaN-NaN" ? dateRange === null || dateRange === void 0 ? void 0 : dateRange.end : null
    },
    fetchPolicy: "network-only"
  }, function (_ref2) {
    var _data$me, _data$me$walletTransa, _data$me2, _data$me2$walletTrans, _data$me2$walletTrans2;

    var data = _ref2.data,
        loading = _ref2.loading,
        loadMore = _ref2.loadMore;
    if (loading && !data) return React.createElement(Loader, null);
    var walletTransactionHistories = data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : (_data$me$walletTransa = _data$me.walletTransactions) === null || _data$me$walletTransa === void 0 ? void 0 : _data$me$walletTransa.edges.map(function (edges) {
      return edges.node;
    }).filter(function (f) {
      return f.status === "SUCCESS";
    });
    var hasNextPage = data === null || data === void 0 ? void 0 : (_data$me2 = data.me) === null || _data$me2 === void 0 ? void 0 : (_data$me2$walletTrans = _data$me2.walletTransactions) === null || _data$me2$walletTrans === void 0 ? void 0 : (_data$me2$walletTrans2 = _data$me2$walletTrans.pageInfo) === null || _data$me2$walletTrans2 === void 0 ? void 0 : _data$me2$walletTrans2.hasNextPage;
    return React.createElement(React.Fragment, null, React.createElement(S.Wrapper, null, React.createElement(DatePickers, {
      setDateRange: setDateRange
    })), React.createElement(PaymentsHistoryTable, {
      paymentHistories: walletTransactionHistories,
      history: history
    }), hasNextPage && React.createElement(S.Wrapper, null, React.createElement(S.Tovch, null, React.createElement("button", {
      onClick: function onClick() {
        var _data$me3, _data$me3$walletTrans;

        setLoad(true);
        loadMore(function (prev, next) {
          var _prev$me, _prev$me$walletTransa, _next$me, _next$me$walletTransa, _next$me2, _next$me2$walletTrans;

          setLoad(false);
          return {
            me: {
              __typename: "User",
              id: "dd",
              walletTransactions: {
                __typename: "WalletTransactionCountableConnection",
                edges: [].concat(_toConsumableArray(((_prev$me = prev.me) === null || _prev$me === void 0 ? void 0 : (_prev$me$walletTransa = _prev$me.walletTransactions) === null || _prev$me$walletTransa === void 0 ? void 0 : _prev$me$walletTransa.edges) || []), _toConsumableArray(((_next$me = next.me) === null || _next$me === void 0 ? void 0 : (_next$me$walletTransa = _next$me.walletTransactions) === null || _next$me$walletTransa === void 0 ? void 0 : _next$me$walletTransa.edges) || [])),
                pageInfo: (next === null || next === void 0 ? void 0 : (_next$me2 = next.me) === null || _next$me2 === void 0 ? void 0 : (_next$me2$walletTrans = _next$me2.walletTransactions) === null || _next$me2$walletTrans === void 0 ? void 0 : _next$me2$walletTrans.pageInfo) || {
                  __typename: "PageInfo",
                  hasNextPage: false,
                  endCursor: null
                }
              }
            }
          };
        }, {
          after: ((_data$me3 = data.me) === null || _data$me3 === void 0 ? void 0 : (_data$me3$walletTrans = _data$me3.walletTransactions) === null || _data$me3$walletTrans === void 0 ? void 0 : _data$me3$walletTrans.pageInfo.endCursor) || null
        });
      }
    }, load ? "Loading..." : React.createElement(S.ButtonText, {
      style: {
        fontSize: "0.85rem"
      }
    }, "Load more")))));
  });
};

export default WalletTransactionHistory;