import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, CartFooter, CartHeader, Loader } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { Cart, CartEmpty } from "@components/templates";
import { useAuth, useCart, useCheckout } from "unurshop-sdk";
import { BASE_URL } from "@temp/core/config";
import { checkoutMessages } from "@temp/intl";
import GroupUshop from "./GroupUshop";
import GroupUshopCantBuy from "./GroupUshopCantBuy";
import { TypedCanBuyIts } from "./query";
var title = React.createElement("h3", {
  "data-test": "cartPageTitle"
}, React.createElement(FormattedMessage, {
  id: "@next.pages.CartPage.CartPage.3536902689",
  defaultMessage: "\u0422\u0430\u043D\u044B \u0441\u0430\u0433\u0441"
}));

var getShoppingButton = function getShoppingButton(history) {
  return React.createElement(Button, {
    testingContext: "cartPageContinueShoppingButton",
    onClick: function onClick() {
      return history.push(BASE_URL);
    }
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping));
};

var getCheckoutButton = function getCheckoutButton(history, canBuy, user) {
  return React.createElement(Button, {
    disabled: !canBuy,
    testingContext: "proceedToCheckoutButton",
    onClick: function onClick() {
      return history.push(user ? "/checkout/" : "/login/");
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.pages.CartPage.CartPage.1128468947",
    defaultMessage: "\u0417\u0430\u0445\u0438\u0430\u043B\u0430\u0445"
  }));
};

var cartHeader = React.createElement(CartHeader, null);

var prepareCartFooter = function prepareCartFooter(totalPrice, shippingTaxedPrice, promoTaxedPrice, subtotalPrice) {
  return React.createElement(CartFooter, {
    subtotalPrice: React.createElement(TaxedMoney, {
      "data-test": "subtotalPrice",
      taxedMoney: subtotalPrice
    }),
    totalPrice: React.createElement(TaxedMoney, {
      "data-test": "totalPrice",
      taxedMoney: totalPrice
    }),
    shippingPrice: shippingTaxedPrice && shippingTaxedPrice.gross.amount !== 0 && React.createElement(TaxedMoney, {
      "data-test": "shippingPrice",
      taxedMoney: shippingTaxedPrice
    }),
    discountPrice: promoTaxedPrice && promoTaxedPrice.gross.amount !== 0 && React.createElement(TaxedMoney, {
      "data-test": "discountPrice",
      taxedMoney: promoTaxedPrice
    })
  });
};

var generateCart = function generateCart(items, removeItem, updateItem, addItem, setCanBuy) {
  var missingVariants = function missingVariants() {
    return items === null || items === void 0 ? void 0 : items.find(function (item) {
      return !item.variant || !item.totalPrice;
    });
  };

  var can_buy_variables = (items === null || items === void 0 ? void 0 : items.map(function (i) {
    return {
      variantId: i.variant.id,
      quantity: i.quantity
    };
  })) || [];
  return React.createElement(React.Fragment, null, missingVariants() ? React.createElement(Loader, null) : React.createElement(TypedCanBuyIts, {
    variables: {
      variants: can_buy_variables
    }
  }, function (_ref) {
    var _data$canBuyIts;

    var data = _ref.data,
        loading = _ref.loading;

    if (loading) {
      return React.createElement(Loader, null);
    }

    var can_buy_variant_ids = (data === null || data === void 0 ? void 0 : (_data$canBuyIts = data.canBuyIts) === null || _data$canBuyIts === void 0 ? void 0 : _data$canBuyIts.filter(function (l) {
      return l === null || l === void 0 ? void 0 : l.canBuy;
    }).map(function (l) {
      return l === null || l === void 0 ? void 0 : l.variantId;
    })) || [];
    var cant_buy = false;
    var d = items === null || items === void 0 ? void 0 : items.filter(function (i) {
      return !can_buy_variant_ids.includes(i.variant.id);
    });

    if (d) {
      cant_buy = d.length > 0;

      if (cant_buy) {
        setCanBuy(false);
      } else {
        setCanBuy(true);
      }
    }

    return React.createElement(React.Fragment, null, React.createElement(GroupUshopCantBuy, {
      lines: items === null || items === void 0 ? void 0 : items.filter(function (i) {
        return !can_buy_variant_ids.includes(i.variant.id);
      }),
      messages: (data === null || data === void 0 ? void 0 : data.canBuyIts) || [],
      removeItem: removeItem,
      updateItem: updateItem,
      addItem: addItem
    }), React.createElement(GroupUshop, {
      lines: items === null || items === void 0 ? void 0 : items.filter(function (i) {
        return can_buy_variant_ids.includes(i.variant.id);
      }),
      removeItem: removeItem,
      updateItem: updateItem,
      addItem: addItem
    }));
  }));
};

export var CartPage = function CartPage(_ref2) {
  var _checkout$shippingMet;

  _objectDestructuringEmpty(_ref2);

  var history = useHistory();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useCart = useCart(),
      loaded = _useCart.loaded,
      addItem = _useCart.addItem,
      removeItem = _useCart.removeItem,
      updateItem = _useCart.updateItem,
      items = _useCart.items,
      totalPrice = _useCart.totalPrice,
      subtotalPrice = _useCart.subtotalPrice,
      shippingPrice = _useCart.shippingPrice,
      discount = _useCart.discount;

  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      canBuy = _React$useState2[0],
      setCanBuy = _React$useState2[1];

  if (!loaded) {
    return React.createElement(Loader, null);
  }

  if (loaded && (items === null || items === void 0 ? void 0 : items.length)) {
    return React.createElement(Cart, {
      title: title,
      button: getCheckoutButton(history, canBuy, user),
      cartHeader: cartHeader,
      cartFooter: prepareCartFooter(totalPrice, shippingTaxedPrice, promoTaxedPrice, subtotalPrice),
      cart: items && generateCart(items, removeItem, updateItem, addItem, setCanBuy)
    });
  }

  return React.createElement(CartEmpty, {
    button: getShoppingButton(history)
  });
};