import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink as Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { commonMessages } from "@temp/intl";
import { MenuDropdown, Offline, Online, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import cartImg from "../../images/cart.svg";
import logoImg from "../../images/unurshop/logo-without-border.svg"; // import logoPNG from "../../images/unurshop/logo-without-border.png";

import searchImg from "../../images/search.svg";
import userImg from "../../images/user.svg";
import { TypedMainMenuQuery } from "./queries";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import { maybe } from "../../core/utils";

var WebMainMenu = function WebMainMenu(props) {
  var overlayContext = props.overlayContext,
      user = props.user,
      handleSignOut = props.handleSignOut,
      cartItemsQuantity = props.cartItemsQuantity;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "main-menu__left"
  }, React.createElement(TypedMainMenuQuery, {
    renderOnError: true,
    displayLoader: false
  }, function (_ref) {
    var data = _ref.data;
    var items = maybe(function () {
      return data.shop.navigation.main.items;
    }, []);
    return React.createElement("ul", null, React.createElement("li", {
      "data-test": "toggleSideMenuLink",
      className: "main-menu__hamburger",
      onClick: function onClick() {
        return overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
          data: items
        });
      }
    }, React.createElement(ReactSVG, {
      path: hamburgerImg,
      className: "main-menu__hamburger--icon"
    }), React.createElement(ReactSVG, {
      path: hamburgerHoverImg,
      className: "main-menu__hamburger--hover"
    })), React.createElement("li", {
      className: "main-menu__unurshop-logo"
    }, React.createElement(Link, {
      to: appPaths.baseUrl
    }, React.createElement(ReactSVG, {
      path: logoImg
    }))));
  })), React.createElement("div", {
    className: "main-menu__center"
  }, React.createElement("div", {
    className: "ushop__searchbox"
  }, React.createElement("div", {
    className: "ushop-search-input"
  }, React.createElement("button", {
    onClick: function onClick() {
      return overlayContext.show(OverlayType.search, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, commonMessages.searchText), React.createElement("span", null, React.createElement(ReactSVG, {
    path: searchImg
  })))))), React.createElement("div", {
    className: "main-menu__right"
  }, React.createElement("ul", null, React.createElement(Online, null, React.createElement(React.Fragment, null, user ? React.createElement(MenuDropdown, {
    head: React.createElement("li", {
      className: "main-menu__icon main-menu__user--active"
    }, React.createElement(ReactSVG, {
      path: userImg
    }), React.createElement("span", {
      style: {
        paddingLeft: "1rem"
      }
    }, user.firstName ? user.firstName : user.email)),
    content: React.createElement("ul", {
      className: "main-menu__dropdown"
    }, React.createElement("li", null, React.createElement("p", null, React.createElement(FormattedMessage, commonMessages.userCode), " ", React.createElement("span", null, user.code))), React.createElement("li", {
      "data-test": "desktopMenuMyAccountLink"
    }, React.createElement(Link, {
      to: appPaths.accountUrl
    }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
      "data-test": "desktopMenuOrderHistoryLink"
    }, React.createElement(Link, {
      to: appPaths.orderHistoryUrl
    }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
      "data-test": "desktopMenuAddressBookLink"
    }, React.createElement(Link, {
      to: appPaths.addressBookUrl
    }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
      onClick: handleSignOut,
      "data-test": "desktopMenuLogoutLink"
    }, React.createElement(FormattedMessage, commonMessages.logOut)))
  }) : React.createElement("li", {
    "data-test": "desktopMenuLoginOverlayLink",
    className: "main-menu__icon user-icon",
    onClick: function onClick() {
      return overlayContext.show(OverlayType.login, OverlayTheme.right);
    }
  }, React.createElement(ReactSVG, {
    path: userImg
  }), React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.login)))), React.createElement("li", {
    "data-test": "menuCartOverlayLink",
    className: "main-menu__icon main-menu__cart",
    onClick: function onClick() {
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
    }
  }, React.createElement(ReactSVG, {
    path: cartImg
  }), cartItemsQuantity > 0 ? React.createElement("span", {
    className: "main-menu__cart__quantity"
  }, cartItemsQuantity) : null)), React.createElement(Offline, null, React.createElement("li", {
    className: "main-menu__offline"
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.MainMenu.WebMainmenu.4144939532",
    defaultMessage: "Offline"
  })))))));
};

export default WebMainMenu;