import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  mutation ResendAccountConfirmationEmail($id: ID!) {\n    confirmEmailResend(id: $id) {\n      user {\n        id\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query HasUserQuery($email: String!) {\n    checkUser(email: $email) {\n      id\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation RegisterAccount(\n    $email: String!\n    $password: String!\n    $redirectUrl: String!\n    $firstName: String!\n    $lastName: String!\n    $phone: String!\n  ) {\n    accountRegister(\n      input: {\n        email: $email\n        password: $password\n        firstName: $firstName\n        lastName: $lastName\n        phone: $phone\n        redirectUrl: $redirectUrl\n      }\n    ) {\n      errors {\n        field\n        message\n      }\n      requiresConfirmation\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
import { TypedQuery } from "@temp/core/queries";
var accountRegisterMutation = gql(_templateObject());
export var TypedAccountRegisterMutation = TypedMutation(accountRegisterMutation);
var hasUserQuery = gql(_templateObject2());
export var TypedHasUserQuery = TypedQuery(hasUserQuery);
export var resendAccountConfirmationEmailMutation = gql(_templateObject3());
export var TypedResendAccountConfirmationEmail = TypedMutation(resendAccountConfirmationEmailMutation);