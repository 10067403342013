import React from "react";
import { Money, TaxedMoney } from "@components/containers"; // import { Togrog } from "@components/containers/Togrog";

import { Thumbnail } from "@components/molecules"; // import { MNT, PRODUCT_TYPE_SHIPPING } from "@temp/constants";

import { getFbLiveAttrs, getSalePercent, getWasPrice } from "@temp/views/Product/utils"; // import ZaraLogo from '../../../../images/unurshop/xd/zara.jpg';
// import UshopLogo from "../../../../images/unurshop/logo-v3.png";

import * as S from "./styles"; // import { IProps } from "./types";

export var ProductTile = function ProductTile(_ref) {
  var _product$ushop, _product$ushop$logoIm, _product$ushop2;

  var product = _ref.product;
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;
  var wasPrice = getWasPrice(product);
  return React.createElement(S.Wrapper, null, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  }), price && getSalePercent(wasPrice, price.gross.amount) && React.createElement(S.Sale, null, "-", getSalePercent(wasPrice, price.gross.amount))), React.createElement(S.RowLk, null, React.createElement(S.ShopLogo, null, (product === null || product === void 0 ? void 0 : (_product$ushop = product.ushop) === null || _product$ushop === void 0 ? void 0 : (_product$ushop$logoIm = _product$ushop.logoImage) === null || _product$ushop$logoIm === void 0 ? void 0 : _product$ushop$logoIm.url) && React.createElement("img", {
    src: (_product$ushop2 = product.ushop) === null || _product$ushop2 === void 0 ? void 0 : _product$ushop2.logoImage.url,
    alt: "Zara"
  })), React.createElement(S.TitleAndPrice, null, React.createElement(S.Price, null, React.createElement(TaxedMoney, {
    taxedMoney: price
  }), wasPrice && price && React.createElement(React.Fragment, null, "\xA0\xA0\xA0", React.createElement("span", {
    style: {
      textDecoration: "line-through"
    }
  }, React.createElement(Money, {
    money: {
      amount: parseFloat(wasPrice),
      currency: price.gross.currency
    }
  })))), React.createElement(S.Title, null, product.name, " ", getFbLiveAttrs(product)))));
};