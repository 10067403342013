import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  max-height: 100vh;\n  height: {\n    ", "px\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { getContentWindowHeight } from "@utils/styles";
export var Modal = styled.div(_templateObject(), getContentWindowHeight());
export var Content = styled.div(_templateObject2(), function (_ref) {
  var spacing = _ref.theme.spacing;
  return "1rem ".concat(spacing.gutter, " 1.8rem ").concat(spacing.gutter);
});