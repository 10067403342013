import React from "react";
import RenderGroupByPackage from "./RenderGroupByPackage";
import RenderGroupByUshop from "./RenderGroupByUshop";

var Accordion = function Accordion(props) {
  var item = props.item,
      expanded = props.expanded,
      refetchOrder = props.refetchOrder,
      setRefetchChat = props.setRefetchChat,
      setExpanded = props.setExpanded; // const totalTask = item.doneLines.length + item.pendingLines.length;

  var totalTask = item.groupsElbeg.reduce(function (p, c) {
    return c.lines.length + p;
  }, 0);
  var itemStatus = "success";

  if (item.groupsElbeg.filter(function (g) {
    return g.color === "error" && g.key !== "cancelled";
  }).reduce(function (p, c) {
    return c.lines.length + p;
  }, 0)) {
    itemStatus = "error";
  } else if (item.groupsElbeg.filter(function (g) {
    return g.color === "pending";
  }).reduce(function (p, c) {
    return c.lines.length + p;
  }, 0)) {
    itemStatus = "pending";
  }

  return React.createElement("div", {
    className: "op-accordion ".concat(expanded ? "expanded" : "", " ").concat(itemStatus)
  }, React.createElement("div", {
    className: "op-accordion__summary",
    onClick: function onClick() {
      if (totalTask > 0) {
        setExpanded(expanded ? false : item.ustatus);
      }
    }
  }, React.createElement("div", {
    className: "op-accordion__title"
  }, React.createElement("h4", null, item.title), React.createElement("div", {
    className: "secondory__title"
  }, React.createElement("div", null, item.groupsElbeg.filter(function (g) {
    return g.lines.length > 0;
  }).map(function (g) {
    return React.createElement("div", {
      key: "second-title-code-".concat(g.key)
    }, "".concat(g.label, ": ").concat(g.lines.length, " \u0431\u0430\u0440\u0430\u0430"));
  })))), React.createElement("div", {
    className: "op-accordion__date"
  }, React.createElement("div", {
    className: "op-accordion__date__top"
  }, "\xA0"))), expanded && React.createElement("div", {
    className: "op-accordion__details"
  }, item.groupsElbeg.filter(function (g) {
    return g.lines.length > 0;
  }).map(function (g, index) {
    return React.createElement("div", {
      className: "op-accordion__details__task ".concat(g.color),
      key: "accordion-group-".concat(g.key, "-").concat(index)
    }, React.createElement("h4", null, g.label), React.createElement("div", {
      className: "op-accordion__details__task-list"
    }, item.groupBy === "USHOP" ? React.createElement(RenderGroupByUshop, {
      lines: g.lines,
      gkey: g.key,
      refetchOrder: refetchOrder,
      setRefetchChat: setRefetchChat
    }) : React.createElement(RenderGroupByPackage, {
      lines: g.lines,
      gkey: g.key,
      refetchOrder: refetchOrder,
      setRefetchChat: setRefetchChat
    })));
  })));
};

export default Accordion;