import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import * as S from "./styles";

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PaymensHistoryTable.PaymentsHistoryTable.1383321068",
    defaultMessage: "\u041E\u043D \u0441\u0430\u0440"
  })), matches && React.createElement(React.Fragment, null, React.createElement(S.PaymentType, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PaymensHistoryTable.PaymentsHistoryTable.1585103234",
    defaultMessage: "\u0422\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u0445\u044D\u043B\u0431\u044D\u0440"
  })), React.createElement(S.PaymentDescription, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PaymensHistoryTable.PaymentsHistoryTable.521738134",
    defaultMessage: "\u0422\u0430\u0439\u043B\u0431\u0430\u0440"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PaymensHistoryTable.PaymentsHistoryTable.4092855181",
    defaultMessage: "\u041E\u0440\u043B\u043E\u0433\u043E"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PaymensHistoryTable.PaymentsHistoryTable.1826998545",
    defaultMessage: "\u0417\u0430\u0440\u043B\u0430\u0433\u0430"
  })), React.createElement(S.Balance, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.PaymensHistoryTable.PaymentsHistoryTable.3283181329",
    defaultMessage: "\u04AE\u043B\u0434\u044D\u0433\u0434\u044D\u043B"
  }))));
};

export var PaymentsHistoryTable = function PaymentsHistoryTable(_ref) {
  var paymentHistories = _ref.paymentHistories;
  var theme = React.useContext(ThemeContext);
  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, " ", header(matches), " "), paymentHistories && (paymentHistories === null || paymentHistories === void 0 ? void 0 : paymentHistories.map(function (history, index) {
      var _history$order, _history$order2;

      var date = new Date(history.createdAt);
      return React.createElement(S.Row, {
        "data-test": "paymentHistoryEntry",
        "data-test-id": index,
        key: index // onClick={() =>
        //   console.log(
        //     "1)event avna 2) wallet transactionoos token avaad history deer pushlen",
        //     history
        //   )
        // }

      }, React.createElement(S.DateOfOrder, null, React.createElement(FormattedDate, {
        value: date
      })), React.createElement(S.PaymentType, null, history.status), React.createElement(S.PaymentDescription, null, ((_history$order = history.order) === null || _history$order === void 0 ? void 0 : _history$order.number) ? "".concat((_history$order2 = history.order) === null || _history$order2 === void 0 ? void 0 : _history$order2.number, " ") : "? ", React.createElement("span", null, "\u0437\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0442\u04E9\u043B\u0431\u04E9\u0440")), history.debit === 0 ? React.createElement(S.Value, null, "-") : React.createElement(S.Value, null, history.debit, " \xA3"), history.credit === 0 ? React.createElement(S.Value, null, "-") : React.createElement(S.Value, null, history.credit, " \xA3"), React.createElement(S.Balance, null, history.balanceAfter, " \xA3"));
    })));
  }));
};