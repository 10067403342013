import { Formik } from "formik";
import React from "react";
import { Radio } from "@components/atoms";
import * as S from "./styles";
var statuses_qpay = [{
  token: "pending",
  label: "Pending"
}];
/**
 * Qpay payment gateway.
 */

var QpayPaymentGateway = function QpayPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId,
      initialStatus = _ref.initialStatus;
  return React.createElement(Formik, {
    initialValues: {
      status: initialStatus || statuses_qpay[0].token
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment(values.status);
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        values = _ref3.values;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "qpayPaymentGatewayForm"
    }, statuses_qpay.map(function (_ref4) {
      var token = _ref4.token,
          label = _ref4.label;
      return React.createElement(S.Status, {
        key: token
      }, React.createElement(Radio, {
        key: token,
        type: "radio",
        name: "status",
        value: token,
        checked: values.status === token,
        onChange: handleChange
      }, React.createElement("span", null, label)));
    }));
  });
};

export { QpayPaymentGateway };