import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    position: absolute;\n    top: 72px;\n    bottom: 85px;\n    width: 100%;\n    overflow-y: auto;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  /*", "*/\n  padding: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    position: absolute;\n    top: 0; left: 0; right: 0; bottom: 0;\n    background: #fff;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  // justify-content: space-between;\n  justify-content: flex-start;\n  width: 100%;\n  max-height: 100vh;\n  \n  /*", "*/\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles"; // export const Modal = styled.div``;

export var Modal = styled.div(_templateObject(), media.smallScreen(_templateObject2())); // export const Content = styled.div``;

export var Content = styled.div(_templateObject3(), media.smallScreen(_templateObject4()), function (_ref) {
  var spacing = _ref.theme.spacing;
  return "1rem ".concat(spacing.gutter);
});