import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { InputSelect } from "@components/molecules";
import * as S from "./styles";
import { AddressFormContentDefault } from "./AddressFormContentDefault";
import { AddressFormContentMongolia } from "./AddressFormContentMongolia";
export var AddressFormContent = function AddressFormContent(_ref) {
  var _values$country;

  var formRef = _ref.formRef,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      formId = _ref.formId,
      errors = _ref.errors,
      handleSubmit = _ref.handleSubmit,
      values = _ref.values,
      countriesOptions = _ref.countriesOptions,
      defaultValue = _ref.defaultValue,
      setFieldValue = _ref.setFieldValue,
      testingContext = _ref.testingContext,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;
  var basicInputProps = useCallback(function () {
    return {
      onBlur: handleBlur,
      onChange: handleChange
    };
  }, [handleChange, handleBlur]);
  var intl = useIntl();
  var fieldErrors = {};

  if (errors) {
    errors.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;
      fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
        message: message
      }]) : [{
        message: message
      }];
    });
  } // console.log(values);


  return React.createElement(S.AddressForm, {
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit,
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithOneCell, null, React.createElement(InputSelect, {
    defaultValue: defaultValue,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Улс",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.4205897874"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.4205897874")),
    name: "country",
    options: countriesOptions,
    value: values.country && countriesOptions && countriesOptions.find(function (option) {
      return option.code === values.country.code;
    }),
    onChange: function onChange(value, name) {
      return setFieldValue(name, value);
    },
    optionLabelKey: "country",
    optionValueKey: "code",
    errors: fieldErrors.country,
    autoComplete: "country"
  })), (values === null || values === void 0 ? void 0 : (_values$country = values.country) === null || _values$country === void 0 ? void 0 : _values$country.code) === "MN" ? React.createElement(AddressFormContentMongolia, {
    basicInputProps: basicInputProps,
    fieldErrors: fieldErrors,
    setFieldValue: setFieldValue,
    values: values,
    includeEmail: includeEmail
  }) : React.createElement(AddressFormContentDefault, {
    basicInputProps: basicInputProps,
    fieldErrors: fieldErrors,
    values: values,
    includeEmail: includeEmail
  })));
};