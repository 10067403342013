import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation OrderShippingAddressUpdate($id: ID!, $input: OrderUpdateInput!) {\n    orderUpdate(id: $id, input: $input) {\n      errors {\n        message\n      }\n      orderErrors {\n        message\n      }\n      order {\n        id\n        shippingAddress {\n          id\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
export var orderUpdateShippingAddressMutation = gql(_templateObject());
export var TypedOrderUpdateShippingAddress = TypedMutation(orderUpdateShippingAddressMutation);