import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/atoms";
import { Money } from "@components/containers";
import { AddressSummary } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";
/**
 * Review order view showed in checkout.
 */

var ReviewDetails = function ReviewDetails(_ref) {
  var shippingAddress = _ref.shippingAddress,
      paymentMethodName = _ref.paymentMethodName,
      email = _ref.email,
      errors = _ref.errors,
      payment = _ref.payment,
      totalPrice = _ref.totalPrice;
  var amount = payment.total.amount;
  return React.createElement("div", {
    "data-test": "sectionTitle"
  }, React.createElement(S.TitleReview, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.reviewOrder)), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(S.DividerReview, null), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.DraftOrder.components.ReviewDetails.2644048082",
    defaultMessage: "\u0422\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u043D\u04E9\u0445\u0446\u04E9\u043B"
  })), React.createElement(S.DividerReview, null), React.createElement(S.TextSummary, null, amount < (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount) ? React.createElement("small", {
    style: {
      fontSize: 12
    }
  }, "\u0423\u0440\u044C\u0434\u0447\u0438\u043B\u0433\u0430\u0430 30%:", " ", React.createElement(Money, {
    money: {
      amount: amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  })) : React.createElement("small", {
    style: {
      fontSize: 12
    }
  }, "\u0411\u04AF\u0442\u044D\u043D \u0442\u04E9\u043B\u0431\u04E9\u0440:", " ", React.createElement(Money, {
    money: {
      amount: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  }))), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, paymentMethodName, " \u043A\u0430\u0440\u0442\u0430\u0430\u0440"), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, amount < (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount) ? React.createElement(React.Fragment, null, React.createElement(Money, {
    money: {
      amount: amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  }), " ") : React.createElement(React.Fragment, null, React.createElement(Money, {
    money: {
      amount: amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  }), " ")))), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};

export { ReviewDetails };