import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import { ModalSafari as Modal } from "@components/organisms/ModalSafari";
import QPayRender from "../../QPayRender"; // import * as S from "./styles";
// import { IProps } from "./types";

var QpayModal = function QpayModal(_ref) {
  var payment = _ref.payment,
      orderNumber = _ref.orderNumber,
      totalAmount = _ref.totalAmount,
      refetchOrder = _ref.refetchOrder;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      close = _React$useState2[0],
      setClose = _React$useState2[1];

  return React.createElement(Modal, {
    submitButtonTestingContext: "submitAddressFormModalButton",
    testingContext: " ",
    title: "Qpay",
    hide: undefined,
    formId: "formid",
    disabled: false,
    show: close,
    submitBtnText: "\u0411\u0443\u0446\u0430\u0445",
    onSubmit: function onSubmit() {
      return setClose(false);
    },
    position: "center-w-max-900"
  }, React.createElement(QPayRender, {
    setCurrentStep: function setCurrentStep() {},
    payment: payment,
    orderNumber: orderNumber,
    totalAmount: totalAmount,
    refetchOrder: refetchOrder
  }));
};

export default QpayModal;