import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useAlert } from "react-alert";
import { Money } from "@components/containers";
import { Togrog } from "@components/containers/Togrog";
import { HR } from "@components/templates/CartEmpty/styles";
import { MNT, PRODUCT_TYPE_SHIPPING } from "@temp/constants";
import { TypedPaymentOrderRemain, TypedWalletToOrder } from "../../query";
import { OrderPayment } from "./OrderPayment";
import { PaymentHistory } from "./PaymentHistory";

var PaymentSlip = function PaymentSlip(_ref) {
  var order = _ref.order,
      refetchOrder = _ref.refetchOrder;
  var alert = useAlert();
  var lines = order === null || order === void 0 ? void 0 : order.lines;
  var total = order === null || order === void 0 ? void 0 : order.total;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showHistory = _React$useState2[0],
      setShowHistory = _React$useState2[1];

  var toggleHistory = function toggleHistory() {
    setShowHistory(!showHistory);
  };

  var productLines = lines.filter(function (l) {
    return l.variant.product.productType.id !== PRODUCT_TYPE_SHIPPING;
  });
  var productCount = productLines.reduce(function (a, b) {
    return a + b.quantity;
  }, 0);
  var productTotalPrice = productLines.reduce(function (a, b) {
    return a + b.totalPrice.gross.amount;
  }, 0);
  var productShippingLines = lines.filter(function (l) {
    return l.variant.product.productType.id === PRODUCT_TYPE_SHIPPING;
  });
  var productShippingTotalPrice = productShippingLines.reduce(function (a, b) {
    return a + b.totalPrice.gross.amount;
  }, 0);
  var shippingLines = productShippingLines.filter(function (p) {
    var _p$variant$product;

    return ((_p$variant$product = p.variant.product) === null || _p$variant$product === void 0 ? void 0 : _p$variant$product.id) === "UHJvZHVjdDoyMDI=";
  }); // fixme

  var shippingLinesTotalPrice = shippingLines.reduce(function (a, b) {
    return a + b.totalPrice.gross.amount;
  }, 0);

  var onCompletedPayment = function onCompletedPayment(data) {
    var _data$paymentOrderRem, _data$paymentOrderRem2;

    if ((data === null || data === void 0 ? void 0 : (_data$paymentOrderRem = data.paymentOrderRemain) === null || _data$paymentOrderRem === void 0 ? void 0 : (_data$paymentOrderRem2 = _data$paymentOrderRem.payment) === null || _data$paymentOrderRem2 === void 0 ? void 0 : _data$paymentOrderRem2.gateway) === "unurshop.payments.qpay") {
      alert.show({
        title: "Qpay нэхэмжлэх үүслээ"
      }, {
        type: "success"
      });
    } else {
      alert.show({
        title: "Төлбөр амжилттай төлөгдлөө"
      }, {
        type: "success"
      });
    }

    refetchOrder();
  };

  return React.createElement("div", null, React.createElement("div", {
    className: "wrapperContainer",
    style: {
      marginBottom: "16px"
    }
  }, React.createElement("h2", null, "\u0422\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u0445\u0443\u0443\u0434\u0430\u0441"), React.createElement("div", {
    className: "slip-container"
  }, React.createElement("div", {
    className: "slip-container__row"
  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, "\u041D\u0438\u0439\u0442 \u0431\u0430\u0440\u0430\u0430"), React.createElement("div", {
    className: "slip-container__row__column"
  }, productCount)), React.createElement("div", {
    className: "slip-container__row"
  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u0442\u04E9\u043B\u0431\u04E9\u0440"), React.createElement("div", {
    className: "slip-container__row__column"
  }, React.createElement(Togrog, {
    amount: Number((productTotalPrice * MNT).toFixed(0))
  }), "\xA0", React.createElement("span", {
    style: {
      color: "rgba(0,0,0,0.4)"
    }
  }, "(", React.createElement(Money, {
    money: {
      amount: productTotalPrice,
      currency: total.gross.currency
    }
  }), ")"))), React.createElement("div", {
    className: "slip-container__row"
  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, "UK \u0434\u043E\u0442\u043E\u0440\u0445 \u0445\u04AF\u0440\u0433\u044D\u043B\u0442"), React.createElement("div", {
    className: "slip-container__row__column"
  }, React.createElement(Togrog, {
    amount: Number(((productShippingTotalPrice - shippingLinesTotalPrice) * MNT).toFixed(0))
  }), "\xA0", React.createElement("span", {
    style: {
      color: "rgba(0,0,0,0.4)"
    }
  }, "(", React.createElement(Money, {
    money: {
      amount: productShippingTotalPrice - shippingLinesTotalPrice,
      currency: total.gross.currency
    }
  }), ")"))), React.createElement("div", {
    className: "slip-container__row"
  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, "\u041C\u043E\u043D\u0433\u043E\u043B \u0440\u0443\u0443 \u0442\u044D\u044D\u0432\u044D\u0440"), React.createElement("div", {
    className: "slip-container__row__column"
  }, React.createElement(Togrog, {
    amount: Number((shippingLinesTotalPrice * MNT).toFixed(0))
  }), "\xA0", React.createElement("span", {
    style: {
      color: "rgba(0,0,0,0.4)"
    }
  }, "(", React.createElement(Money, {
    money: {
      amount: shippingLinesTotalPrice,
      currency: total.gross.currency
    }
  }), ")"))), React.createElement(HR, {
    style: {
      margin: 0
    }
  }), React.createElement("div", {
    className: "slip-container__row total"
  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, "\u041D\u0438\u0439\u0442 \u0442\u04E9\u043B\u0431\u04E9\u0440"), React.createElement("div", {
    className: "slip-container__row__column"
  }, React.createElement(Togrog, {
    amount: Number((total.gross.amount * MNT).toFixed(0))
  }), "\xA0", React.createElement("span", {
    style: {
      color: "rgba(0,0,0,0.4)"
    }
  }, "(", React.createElement(Money, {
    money: total.gross
  }), ")"))), React.createElement(HR, {
    style: {
      margin: 0
    }
  }), React.createElement("div", {
    className: "slip-container__row",
    onClick: toggleHistory,
    style: {
      cursor: "pointer"
    }
  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, "\u0422\u04E9\u043B\u0441\u04E9\u043D", order.total.gross.amount > 0 && React.createElement(React.Fragment, null, "(", (order.totalCaptured.amount * 100 / order.total.gross.amount).toFixed(0), "%)")), React.createElement("div", {
    className: "slip-container__row__column"
  }, React.createElement(Togrog, {
    amount: Number((order.totalCaptured.amount * MNT).toFixed(0))
  }), "\xA0", React.createElement("span", {
    style: {
      color: "rgba(0,0,0,0.4)"
    }
  }, "(", React.createElement(Money, {
    money: order.totalCaptured
  }), ")"), React.createElement("div", {
    style: {
      margin: "auto"
    }
  }, showHistory ? React.createElement("span", null, React.createElement("i", {
    className: "arrowUp"
  })) : React.createElement("span", null, React.createElement("i", {
    className: "arrowDown"
  }))))), showHistory && React.createElement(PaymentHistory, {
    order: order,
    refetchOrder: refetchOrder
  }), React.createElement("div", {
    className: "slip-container__row" // style={{ fontSize: "18px" }}

  }, React.createElement("div", {
    className: "slip-container__row__column"
  }, order.totalBalance.amount > 0 ? "Илүү төлөлт" : "Үлдэгдэл "), React.createElement("div", {
    className: "slip-container__row__column",
    style: {
      fontWeight: 500,
      color: order.totalBalance.amount < 0 ? "red" : "inherit"
    }
  }, React.createElement(Togrog, {
    amount: Number((Math.abs(order.totalBalance.amount) * MNT).toFixed(0))
  }), "\xA0", React.createElement("span", {
    style: {
      color: order.totalBalance.amount < 0 ? "red" : "rgba(0,0,0,0.4)"
    }
  }, "(", React.createElement(Money, {
    money: {
      amount: Math.abs(order.totalBalance.amount),
      currency: order.totalBalance.currency
    }
  }), ")"))))), order.totalBalance.amount < 0 && React.createElement("div", {
    className: "wrapperContainer-white"
  }, React.createElement(TypedPaymentOrderRemain, {
    onCompleted: onCompletedPayment
  }, function (paymentOrderRemain) {
    return React.createElement(TypedWalletToOrder, {
      onCompleted: onCompletedPayment
    }, function (walletToOrder) {
      return React.createElement(React.Fragment, null, React.createElement(OrderPayment, {
        order: order,
        mutation: paymentOrderRemain,
        walletToOrder: walletToOrder
      }));
    });
  })));
};

export { PaymentSlip };