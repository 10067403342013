import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { CartRow } from "@components/organisms";
import { Money, TaxedMoney } from "@components/containers";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";

var GroupUshop = function GroupUshop(_ref) {
  var lines = _ref.lines,
      removeItem = _ref.removeItem,
      updateItem = _ref.updateItem,
      addItem = _ref.addItem;

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      _ushops = _useState2[0],
      setUshops = _useState2[1];

  useEffect(function () {
    var ushops = [];
    var removeIds = [];
    var variants = lines === null || lines === void 0 ? void 0 : lines.map(function (tmp) {
      return tmp.variant;
    });
    var productVariants = variants === null || variants === void 0 ? void 0 : variants.filter(function (tmp) {
      var _tmp$product;

      return (tmp === null || tmp === void 0 ? void 0 : (_tmp$product = tmp.product) === null || _tmp$product === void 0 ? void 0 : _tmp$product.productType.id) !== PRODUCT_TYPE_SHIPPING;
    });
    var shippingVariants = variants === null || variants === void 0 ? void 0 : variants.filter(function (tmp) {
      var _tmp$product2;

      return (tmp === null || tmp === void 0 ? void 0 : (_tmp$product2 = tmp.product) === null || _tmp$product2 === void 0 ? void 0 : _tmp$product2.productType.id) === PRODUCT_TYPE_SHIPPING;
    });
    /** productuudiig delguurt hargalzuulj bga hesge */

    productVariants === null || productVariants === void 0 ? void 0 : productVariants.map(function (variant) {
      var shop = ushops.find(function (el) {
        var _variant$product, _variant$product$usho;

        return el.id === (variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : (_variant$product$usho = _variant$product.ushop) === null || _variant$product$usho === void 0 ? void 0 : _variant$product$usho.id);
      });
      var line = lines === null || lines === void 0 ? void 0 : lines.find(function (el) {
        return el.variant.id === variant.id;
      }); // if (variant.product.metadata) {
      //   line.variant.product["metadata"] = variant.product.metadata;
      // }

      if (!shop) {
        var _variant$product2;

        ushops.push(_objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.ushop, {
          lines: [line]
        }));
      } else {
        shop.lines.push(line);
      }
    });
    /** remove hiih shaardlagtai id bwal olj bga  */

    shippingVariants === null || shippingVariants === void 0 ? void 0 : shippingVariants.map(function (el) {
      var ushop = ushops.find(function (shop) {
        var _el$product, _el$product$ushop;

        return shop.id === (el === null || el === void 0 ? void 0 : (_el$product = el.product) === null || _el$product === void 0 ? void 0 : (_el$product$ushop = _el$product.ushop) === null || _el$product$ushop === void 0 ? void 0 : _el$product$ushop.id);
      });

      if (ushop) {
        ushop.shippingVariantId = el.id;
      } else {
        removeIds.push(el.id);
      }
    });
    var addIds = [];
    ushops.filter(function (ushop) {
      var _ushop$shippingProduc;

      return ushop.shippingVariantId === undefined && ushop.shippingProduct !== null && ((_ushop$shippingProduc = ushop.shippingProduct) === null || _ushop$shippingProduc === void 0 ? void 0 : _ushop$shippingProduc.variants.length) > 0;
    }).map(function (ushop) {
      var max_variant = ushop.shippingProduct.variants.reduce(function (p, v) {
        return p.pricing.price.gross.amount > v.pricing.price.gross.amount ? p : v;
      });
      addIds.push(max_variant.id);
    }); // addIds.map(id => {
    //   addItem(id, 1);
    // });
    // removeIds.map(id => {
    //   removeItem(id);
    // });

    setUshops(ushops);
  }, [lines]);

  var changeUkShipping = function changeUkShipping(addId, removeId) {
    addItem(addId, 1);
    removeItem(removeId);
  };

  return React.createElement(React.Fragment, null, _ushops.map(function (_shop, index) {
    var shopTotal = _shop.lines.map(function (line) {
      return line.totalPrice.gross.amount;
    }).reduce(function (a, c) {
      return a + c;
    });

    var priceFormat = null;
    var selectedShipping = _shop.shippingProduct !== null ? _shop.shippingProduct.variants.find(function (v) {
      return v.id === _shop.shippingVariantId;
    }) : null;
    return React.createElement("div", {
      key: index,
      className: "cart__shop"
    }, React.createElement("h5", {
      className: "cart__shop__title"
    }, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("img", {
      className: "cart__shop__logo",
      src: _shop.logoImage.url,
      alt: _shop.name
    }), React.createElement("span", null, _shop.name)), React.createElement("span", {
      className: "cart__shop__total"
    }, React.createElement(Money, {
      money: {
        amount: shopTotal,
        currency: "GBP"
      }
    }))), React.createElement(React.Fragment, null, React.createElement("ul", {
      className: "cart__list",
      style: {
        padding: 0
      }
    }, _shop.lines.map(function (line, index) {
      var _variant$product3, _variant$product4, _variant$product5, _variant$product6, _variant$product6$thu, _variant$pricing, _variant$attributes;

      var id = line.id,
          variant = line.variant,
          quantity = line.quantity,
          totalPrice = line.totalPrice;

      if (!priceFormat) {
        priceFormat = line.variant.pricing.price.gross;
      }

      return React.createElement(CartRow, {
        key: id ? "id-".concat(id) : "idx-".concat(index),
        index: index,
        id: (variant === null || variant === void 0 ? void 0 : (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : _variant$product3.id) || "",
        name: (variant === null || variant === void 0 ? void 0 : (_variant$product4 = variant.product) === null || _variant$product4 === void 0 ? void 0 : _variant$product4.name) || "",
        maxQuantity: variant.quantityAvailable || quantity,
        quantity: quantity,
        onRemove: function onRemove() {
          return removeItem(variant.id);
        },
        onQuantityChange: function onQuantityChange(quantity) {
          return updateItem(variant.id, quantity);
        },
        thumbnail: _objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product5 = variant.product) === null || _variant$product5 === void 0 ? void 0 : _variant$product5.thumbnail, {
          alt: (variant === null || variant === void 0 ? void 0 : (_variant$product6 = variant.product) === null || _variant$product6 === void 0 ? void 0 : (_variant$product6$thu = _variant$product6.thumbnail) === null || _variant$product6$thu === void 0 ? void 0 : _variant$product6$thu.alt) || ""
        }),
        line: line,
        totalPrice: React.createElement(TaxedMoney, {
          taxedMoney: totalPrice
        }),
        unitPrice: React.createElement(TaxedMoney, {
          taxedMoney: variant === null || variant === void 0 ? void 0 : (_variant$pricing = variant.pricing) === null || _variant$pricing === void 0 ? void 0 : _variant$pricing.price
        }),
        sku: variant.sku,
        attributes: (_variant$attributes = variant.attributes) === null || _variant$attributes === void 0 ? void 0 : _variant$attributes.map(function (attribute) {
          return {
            attribute: {
              id: attribute.attribute.id,
              name: attribute.attribute.name || ""
            },
            values: attribute.values.map(function (value) {
              return {
                id: value === null || value === void 0 ? void 0 : value.id,
                name: (value === null || value === void 0 ? void 0 : value.name) || "",
                value: value === null || value === void 0 ? void 0 : value.value
              };
            })
          };
        })
      });
    })), React.createElement("table", {
      className: "ushop-price-table",
      style: {
        margin: 0,
        width: "100%",
        fontSize: "1rem"
      }
    }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u04AF\u043D\u044D"), React.createElement("td", {
      style: {
        width: 120
      }
    }, React.createElement("b", null, React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: shopTotal
      })
    })))), React.createElement("tr", null, React.createElement("td", null, "\u0410\u043D\u0433\u043B\u0438 \u0434\u043E\u0442\u043E\u043E\u0434 \u0445\u04AF\u0440\u0433\u044D\u043B\u0442"), React.createElement("td", null, selectedShipping ? React.createElement("select", {
      style: {
        fontSize: "1rem",
        padding: 5
      },
      onChange: function onChange(el) {
        changeUkShipping(el.target.value, selectedShipping.id);
      }
    }, _shop.shippingProduct.variants.map(function (variant, index) {
      return React.createElement("option", {
        key: index,
        value: variant.id,
        selected: variant.id === selectedShipping.id
      }, variant.name === "Next day" ? "Дараа өдөр" : variant.name === "Regular" ? "Энгийн" : "Үнэгүй", ": \xA3", variant.pricing.price.gross.amount);
    })) : _shop.id === "VXNob3A6MQ==" ? "Free" : "0"))), React.createElement("tfoot", null, React.createElement("tr", null, React.createElement("td", null, "\u041D\u0438\u0439\u0442 (".concat(_shop.name, ")")), React.createElement("td", null, React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: shopTotal + (selectedShipping ? selectedShipping.pricing.price.gross.amount : 0)
      })
    })))))));
  }));
  /*
  return lines?.map((line, index) => {
    const { id, variant, quantity, totalPrice } = line;
    return (
      <CartRow
        key={id ? `id-${id}` : `idx-${index}`}
        index={index}
        id={variant?.product?.id || ""}
        name={variant?.product?.name || ""}
        maxQuantity={variant.quantityAvailable || quantity}
        quantity={quantity}
        onRemove={() => removeItem(variant.id)}
        onQuantityChange={quantity => updateItem(variant.id, quantity)}
        thumbnail={{
          ...variant?.product?.thumbnail,
          alt: variant?.product?.thumbnail?.alt || "",
        }}
        line={line}
        totalPrice={<TaxedMoney taxedMoney={totalPrice} />}
        unitPrice={<TaxedMoney taxedMoney={variant?.pricing?.price} />}
        sku={variant.sku}
        attributes={variant.attributes?.map(attribute => {
          return {
            attribute: {
              id: attribute.attribute.id,
              name: attribute.attribute.name || "",
            },
            values: attribute.values.map(value => {
              return {
                id: value?.id,
                name: value?.name || "",
                value: value?.value,
              };
            }),
          };
        })}
      />
    );
  });
  */
};

export default GroupUshop;