import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import "./scss/index.scss";
import TableBody from "./TableBody";

var Table = function Table(_ref) {
  var fulfillments = _ref.fulfillments,
      setModalOpen = _ref.setModalOpen,
      setLine = _ref.setLine,
      rowProps = _objectWithoutProperties(_ref, ["fulfillments", "setModalOpen", "setLine"]);

  return fulfillments && fulfillments.length > 0 && React.createElement("table", {
    className: "cart-table"
  }, fulfillments.map(function (fulfillment) {
    return React.createElement(React.Fragment, {
      key: fulfillment.id
    }, React.createElement(TableBody, _extends({
      fulfillment: fulfillment
    }, rowProps, {
      setModalOpen: setModalOpen,
      setLine: setLine
    })));
  }));
};

export default Table;