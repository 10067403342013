import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonLink, Checkbox } from "@components/atoms";
import * as S from "./styles";
export var AttributeValuesChecklist = function AttributeValuesChecklist(_ref) {
  var title = _ref.title,
      name = _ref.name,
      values = _ref.values,
      _ref$valuesShowLimit = _ref.valuesShowLimit,
      valuesShowLimit = _ref$valuesShowLimit === void 0 ? false : _ref$valuesShowLimit,
      _ref$valuesShowLimitN = _ref.valuesShowLimitNumber,
      valuesShowLimitNumber = _ref$valuesShowLimitN === void 0 ? 5 : _ref$valuesShowLimitN,
      onValueClick = _ref.onValueClick;

  var _React$useState = React.useState(!valuesShowLimit),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      viewAllOptions = _React$useState2[0],
      setViewAllOptions = _React$useState2[1];

  return React.createElement(S.Wrapper, null, title && React.createElement(S.Header, null, title), values && values.map(function (value, index) {
    if (!viewAllOptions && index > valuesShowLimitNumber - 1) {
      return React.createElement(React.Fragment, {
        key: index
      }, "\xA0");
    }

    return React.createElement(Checkbox, {
      name: name,
      checked: !!value.selected,
      onChange: function onChange() {
        return onValueClick(value);
      },
      key: index
    }, value && value.name);
  }), !viewAllOptions && values.length > valuesShowLimitNumber && React.createElement(S.ViewMoreButton, null, React.createElement(ButtonLink, {
    testingContext: "viewAllButton",
    size: "sm",
    color: "secondary",
    onClick: function onClick() {
      return setViewAllOptions(true);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AttributeValuesChecklist.AttributeValuesChecklist.2029012727",
    defaultMessage: "VIEW ALL OPTIONS"
  }))), React.createElement(S.BottomBorder, null));
};