import ReactSVG from "react-svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import svgOk from "images/unurshop/thankyou-ok.svg"; // import svgBg from "images/unurshop/thankyou-bg.svg";
// fixme ene zurag conflict uuseed bga uchiriig oloh
// import pngBg from "images/unurshop/thankyou-bg.png";

import * as S from "./styles";

/**
 * Thank you page after completing the checkout.
 */
var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      continueShopping = _ref.continueShopping,
      orderDetails = _ref.orderDetails;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "ushop-title"
  }, React.createElement(ReactSVG, {
    path: svgOk
  }), React.createElement("br", null), React.createElement("h4", null, "\u0422\u04E9\u043B\u0431\u04E9\u0440 \u0430\u043C\u0436\u0438\u043B\u0442\u0442\u0430\u0439 \u0445\u0438\u0439\u0433\u0434\u043B\u044D\u044D !"), React.createElement("h3", null, "\u0422\u0430\u043D\u0434 \u0431\u0430\u044F\u0440\u043B\u0430\u043B\u0430\u0430")), React.createElement(S.ONum, null, React.createElement("span", {
    onClick: orderDetails
  }, React.createElement("span", {
    className: "b"
  }, React.createElement("span", {
    className: "t"
  }, "\u0422\u0430\u043D\u044B \u0437\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0434\u0443\u0433\u0430\u0430\u0440"), "#", orderNumber))), React.createElement("div", null), React.createElement(S.Buttons, null, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    onClick: continueShopping,
    color: "secondary",
    fullWidth: true
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping)), React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    onClick: orderDetails,
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.2309655192",
    defaultMessage: "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u044B\u043D \u0434\u044D\u043B\u0433\u044D\u0440\u044D\u043D\u0433\u04AF\u0439"
  })))));
};

export { ThankYou };