import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { LoginForm } from "..";
import ForgottenPassword from "../OverlayManager/Login/ForgottenPassword";

var SignInForm = function SignInForm(_ref) {
  var onForgottenPasswordClick = _ref.onForgottenPasswordClick;
  return React.createElement(React.Fragment, null, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, commonMessages.login)), React.createElement(LoginForm, {
    forgottenPassword: React.createElement(ForgottenPassword, {
      onClick: onForgottenPasswordClick
    })
  }));
};

export default SignInForm;