import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Breadcrumbs, extractBreadcrumbs, ProductsFeatured } from "../../components";
import { ProductListHeader } from "../../@next/components/molecules";
import { ProductList } from "../../@next/components/organisms";
import { FilterSidebar } from "../../@next/components/organisms/FilterSidebar";
import { maybe } from "../../core/utils";

var Page = function Page(_ref) {
  var activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      category = _ref.category,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      clearFilters = _ref.clearFilters,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  return React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: extractBreadcrumbs(category)
  }), React.createElement("h3", null, category.name), React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), React.createElement(ProductListHeader, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), canDisplayProducts && React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  })), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;