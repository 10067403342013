import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: #b02a37;\n  border: 1px solid rgb(241, 174, 181);\n  background: rgb(248, 215, 218);\n  margin-top: 1rem;\n  padding: 16px 12px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ErrorMessage = styled.div(_templateObject());
export var ErrorParagraph = styled.p(_templateObject2());
ErrorMessage.displayName = "S.ErrorMessage";