import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react"; // import { FormattedMessage, useIntl } from 'react-intl';

import { Link } from "react-router-dom";
import { useAuth } from "unurshop-sdk";
import UserPhoneUpdateModal from "@components/templates/UserPhoneUpdateModal/UserPhoneUpdateModal"; // import { useAuth } from "unurshop-sdk";
// import { Money } from "@components/containers";

import DraftOrderModal from "@temp/userAccount/views/OrderDetails/DraftOrder/components/DraftOrderModal"; // import LatestProducts from "./LatestProducts";

import ForHomePageProductList from "@temp/views/Category/ForHomePage";
import { Loader } from "../../components"; // import { Loader, UshopFeatured } from "../../components";

import { structuredData } from "../../core/SEO/Homepage/structuredData";
import { generateCategoryUrl } from "../../core/utils";
import noPhotoImg from "../../images/no-photo.svg"; // import ushopBackgroundImage from "../../images/unurshop/xd/homepage-cover.png";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;

  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated,
      user = _useAuth.user;

  var categoriesExist = function categoriesExist() {
    return categories && categories.edges && categories.edges.length > 0;
  }; // const intl = useIntl();


  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), authenticated && (user.phone === null || user.phone === "") ? React.createElement(UserPhoneUpdateModal, {
    user: user
  }) : React.createElement(DraftOrderModal, null), loading && !categories && React.createElement(Loader, null), categoriesExist() && React.createElement("div", {
    className: "home-page__categories"
  }, React.createElement("div", {
    className: "home-page__categories__list"
  }, categories.edges.map(function (_ref2) {
    var category = _ref2.node;
    return React.createElement("div", {
      key: category.id
    }, React.createElement(Link, {
      to: generateCategoryUrl(category.id, category.name),
      key: category.id
    }, React.createElement("div", {
      className: classNames("home-page__categories__list__image", {
        "home-page__categories__list__image--no-photo": !category.backgroundImage
      }),
      style: {
        backgroundImage: "url(".concat(category.backgroundImage ? category.backgroundImage.url : noPhotoImg, ")")
      }
    }), React.createElement("h3", null, category.name)));
  }))), React.createElement(ForHomePageProductList, null));
};

export default Page;