import * as React from "react";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import { generateProductUrl } from "../../../core/utils"; // import { SearchResults_products_edges } from "./gqlTypes/SearchResults";
// import { SearchBySResults_products_edges } from "./gqlTypes/SearchBySKUResults";

var ProductItem = function ProductItem(_ref) {
  var product = _ref.node;
  return React.createElement("li", {
    className: "search__products__item"
  }, React.createElement(Link, {
    to: generateProductUrl(product.id, product.name)
  }, React.createElement(Thumbnail, {
    source: product
  }), React.createElement("span", null, React.createElement("h4", null, product.name), React.createElement("p", null, product.category.name))));
};

export default ProductItem;