import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react"; // import { FormattedMessage } from "react-intl";

import { components } from "react-select";
import { ThemeContext } from "styled-components";
import { useHandlerWhenClickedOutside } from "../../../hooks";
import { Icon } from "../Icon"; // import { Label } from "../Label";

import { Select } from "../Select";
import * as S from "./styles";
export var DropdownSelect = function DropdownSelect(_ref) {
  var options = _ref.options,
      name = _ref.name,
      value = _ref.value,
      _onChange = _ref.onChange;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      menuIsOpen = _React$useState2[0],
      setMenuIsOpen = _React$useState2[1];

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    setMenuIsOpen(false);
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  var customComponents = {
    Control: function Control() {
      return React.createElement(S.SortLine, {
        onClick: function onClick() {
          return setMenuIsOpen(!menuIsOpen);
        }
      }, React.createElement(S.Value, null, " ".concat(value ? value.label : "")), React.createElement(S.Indicator, {
        rotate: String(menuIsOpen)
      }, React.createElement(Icon, {
        name: "select_arrow",
        size: 10
      })));
    },
    IndicatorSeparator: function IndicatorSeparator() {
      return null;
    },
    IndicatorsContainer: function IndicatorsContainer() {
      return null;
    },
    Option: function Option(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(components.Option, _objectSpread({
        customTheme: customTheme
      }, props));
    }
  };
  return React.createElement(S.Wrapper, {
    "data-test": "sortingDropdown",
    ref: setElementRef()
  }, React.createElement(Select, {
    options: options,
    value: value,
    onChange: function onChange(value) {
      setMenuIsOpen(false);

      _onChange(value);
    },
    name: name,
    menuIsOpen: menuIsOpen,
    customComponents: customComponents
  }));
};