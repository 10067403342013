import React from "react";
import { TypedOrderAddNoteMutation, TypedOrderChatsQuery } from "../../query";
import { OrderChats } from "./OrderChats";
export var OrderNote = function OrderNote(props) {
  var orderId = props.orderId,
      userEmail = props.userEmail,
      refetchChat = props.refetchChat,
      refetchOrder = props.refetchOrder,
      setRefetchChat = props.setRefetchChat;
  return React.createElement(TypedOrderChatsQuery, {
    variables: {
      id: orderId
    }
  }, function (_ref) {
    var data = _ref.data,
        refetch = _ref.refetch;

    if (refetchChat) {
      setRefetchChat(false);
      refetch();
    }

    var onCompletedNote = function onCompletedNote() {
      refetch();
    };

    return React.createElement(TypedOrderAddNoteMutation, {
      onCompleted: onCompletedNote
    }, function (orderAddNote) {
      return React.createElement(OrderChats, {
        addMessage: orderAddNote,
        chats: data.orderChats,
        orderId: orderId,
        userEmail: userEmail,
        refetchOrder: refetchOrder,
        setRefetchChat: setRefetchChat
      });
    });
  });
};