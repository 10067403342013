import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import Media from "react-media";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";
import { useAuth, useCart } from "unurshop-sdk";
import { OverlayContext } from "..";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import WebMainMenu from "./WebMainmenu";
import MobileMainMenu from "./MobileMainmenu";

var MainMenu = function MainMenu() {
  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useCart = useCart(),
      items = _useCart.items;

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      _setScrollY = _React$useState2[1];

  var handleScroll = function handleScroll() {
    var _window = window,
        scrollY = _window.scrollY;

    if (scrollY > 10) {
      _setScrollY(function (prev) {
        if (prev >= scrollY) {
          document.body.classList.add("body-sticky");
        } else {
          document.body.classList.remove("body-sticky");
        }

        return scrollY;
      });
    } else {
      document.body.classList.remove("body-sticky");
    }
  };

  React.useEffect(function () {
    window.addEventListener("scroll", handleScroll);
    return function () {
      return window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  var handleSignOut = function handleSignOut() {
    signOut();
  };

  var cartItemsQuantity = items && items.filter(function (item) {
    if (item.variant.product) {
      return item.variant.product.productType.id !== PRODUCT_TYPE_SHIPPING;
    }

    return true;
  }).reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0;
  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement("div", {
      style: {
        borderBottom: "1px solid #ebebeb"
      }
    }, React.createElement("nav", {
      className: "main-menu",
      id: "header"
    }, React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return React.createElement(WebMainMenu, {
          overlayContext: overlayContext,
          handleSignOut: handleSignOut,
          cartItemsQuantity: cartItemsQuantity,
          user: user
        });
      }
    }), React.createElement(Media, {
      query: {
        maxWidth: mediumScreen
      },
      render: function render() {
        return React.createElement(MobileMainMenu, {
          overlayContext: overlayContext,
          handleSignOut: handleSignOut,
          cartItemsQuantity: cartItemsQuantity,
          user: user
        });
      }
    })));
  });
};

export default MainMenu;