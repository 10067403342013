import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  margin-bottom: 3rem;\n\n  span.b {\n    display: inline-block;\n    border: 2px solid #707070;\n    border-radius: 20px;\n    font-size: ", ";\n    text-align: center;\n    padding-bottom: 5px;\n    font-weight: 600;\n    span.t {\n      display: block;\n      font-size: 12px;\n      margin: -11px 12px 0;\n      padding: 0 10px\n      text-transform: uppercase;\n      background: #fff;\n      font-weight: normal;\n    }\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n    margin: 20px 0;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n  margin: 40px 25%;\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n\n  button {\n    padding-left: 0;\n    padding-right: 0;\n  }\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 170%;\n\n  span {\n    font-weight: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 110%;\n  span {\n    font-weight: ", ";\n  }\n  padding-bottom: 40px;\n  border-bottom: 1px solid\n    ", ";\n  margin-bottom: 40px;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    margin: 40px 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 80px 0;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.smallScreen(_templateObject2()));
export var ThankYouHeader = styled.p(_templateObject3(), function (props) {
  return props.theme.typography.ultraBigFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.smallScreen(_templateObject4(), function (props) {
  return props.theme.typography.h2FontSize;
}));
export var Paragraph = styled.p(_templateObject5(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Buttons = styled.div(_templateObject6(), media.smallScreen(_templateObject7()));
export var ONum = styled.div(_templateObject8(), function (props) {
  return props.theme.typography.h3FontSize;
});