import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query CanBuyIts($variants: [CanBuyInput]!) {\n    canBuyIts(variants: $variants) {\n      variantId\n      canBuy\n      message\n      quantity\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
export var canBuyIts = gql(_templateObject());
export var TypedCanBuyIts = TypedQuery(canBuyIts);