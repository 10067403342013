import { PRODUCT_TYPE_FBLIVE } from "@temp/constants";
export function getLinkImages(product) {
  var metadata = (product === null || product === void 0 ? void 0 : product.metadata) ? product === null || product === void 0 ? void 0 : product.metadata.find(function (el) {
    return el.key === "linkImages";
  }) : null;
  return JSON.parse(metadata ? metadata.value : "[]" || "[]");
}
export function getAvatarImage(product) {
  var imageLinks = getLinkImages(product);
  return (product === null || product === void 0 ? void 0 : product.thumbnail) ? product.thumbnail.url : imageLinks.length ? imageLinks[0] : null;
}
export function getAvatarImage2x(product) {
  var imageLinks = getLinkImages(product);
  return (product === null || product === void 0 ? void 0 : product.thumbnail2x) ? product.thumbnail2x.url : imageLinks.length ? imageLinks[0] : null;
}
export function getWasPrice(product) {
  if (product.wasPrice) {
    return product.wasPrice;
  }

  var metadata = (product === null || product === void 0 ? void 0 : product.metadata) ? product === null || product === void 0 ? void 0 : product.metadata.find(function (el) {
    return el.key === "wasPrice";
  }) : null;
  return metadata ? metadata.value : null;
}
export var getSalePercent = function getSalePercent(wasPrice, price) {
  return wasPrice ? "".concat(Math.round(100 - price * 100 / wasPrice), "%") : false;
};
export var getProductLink = function getProductLink(product) {
  if (product === null || product === void 0 ? void 0 : product.productUrl) {
    return product === null || product === void 0 ? void 0 : product.productUrl;
  }

  var metadata = (product === null || product === void 0 ? void 0 : product.metadata) ? product === null || product === void 0 ? void 0 : product.metadata.find(function (el) {
    return el.key === "url";
  }) : null;
  return metadata ? metadata.value : null;
};
export var getFbLiveAttrs = function getFbLiveAttrs(product) {
  if (product.attributes && product.productType.id === PRODUCT_TYPE_FBLIVE) {
    var size = product.attributes.find(function (i) {
      return i.attribute.slug === "all-size" && i.values.length > 0;
    });
    var brand = product.attributes.find(function (i) {
      return i.attribute.slug === "all-brand" && i.values.length > 0;
    });
    var texts = [];
    if (brand) texts.push(brand.values[0].name);

    if (size) {
      var val = size.values[0].name;

      if (parseInt(val, 10).toString() === val) {
        val = "UK ".concat(val);
      }

      texts.push(val);
    }

    return texts.join("-");
  }

  return "";
};