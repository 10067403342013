import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    position: absolute;\n    height: 85px;\n    width: 100%;\n    bottom: 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  text-align: right;\n  padding: ", ";\n  ", "\n  button {\n    &:last-child {\n    }\n  }\n\n  /*", "*/\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles"; // export const Footer = styled.div<{ divider: boolean }>``;

export var Footer = styled.div(_templateObject(), function (props) {
  return "1.1rem ".concat(props.theme.spacing.gutter);
}, function (_ref) {
  var divider = _ref.divider,
      theme = _ref.theme;
  return divider && "border-top: 1px solid ".concat(theme.colors.light, ";");
}, media.smallScreen(_templateObject2())); // export const Footer = styled.div<{ divider: boolean }>`
//   position: relative;
//   text-align: right;
//   padding: ${props => `1.1rem ${props.theme.spacing.gutter}`};
//   ${({ divider, theme }) =>
//     divider && `border-top: 1px solid ${theme.colors.light};`}
//   button {
//     &:last-child {
//       margin-left: 2rem;
//       margin-right: 0.7rem;
//     }
//   }
// `;