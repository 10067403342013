import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@components/atoms/Icon";
import { AddressFormModal } from "@components/organisms/AddressFormModal";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { ShopContext } from "../ShopProvider/context"; // import { FormattedMessage } from "react-intl";
// import { commonMessages } from "@temp/intl";
// import { FormAddressType } from "./types";

var CustomAddressSummary = function CustomAddressSummary(_ref) {
  var address = _ref.address,
      email = _ref.email,
      paragraphRef = _ref.paragraphRef,
      userId = _ref.userId,
      selected = _ref.selected,
      setSelected = _ref.setSelected;
  var intl = useIntl();

  var _React$useContext = React.useContext(ShopContext),
      defaultCountry = _React$useContext.defaultCountry,
      countries = _React$useContext.countries;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      displayNewModal = _React$useState2[0],
      setDisplayNewModal = _React$useState2[1];

  if (address) {
    return React.createElement("div", {
      className: "root"
    }, React.createElement("div", {
      className: "root1"
    }, React.createElement("div", {
      className: "add-btn",
      onClick: function onClick() {
        return setDisplayNewModal(true);
      }
    }, React.createElement("button", {
      className: "add-btn1"
    }, React.createElement("p", null, React.createElement(Icon, {
      size: 24,
      name: "plus"
    })), React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.CustomAddressSummary.index.2323805405",
      defaultMessage: "\u0425\u0430\u044F\u0433 \u043D\u044D\u043C\u044D\u0445"
    }))))), displayNewModal && React.createElement(AddressFormModal, _extends({
      hideModal: function hideModal() {
        setDisplayNewModal(false);
      },
      userId: userId
    }, {
      defaultValue: defaultCountry || {}
    }, {
      submitBtnText: intl.formatMessage(commonMessages.add),
      title: intl.formatMessage(checkoutMessages.addNewAddress)
    }, {
      countriesOptions: countries
    }, {
      formId: "address-form"
    })), address.map(function (a, index) {
      return React.createElement("div", {
        key: index,
        className: "root1"
      }, React.createElement("div", {
        className: "content ".concat(selected.id === a.id ? "active" : ""),
        onClick: function onClick() {
          return setSelected(a);
        }
      }, selected.id === a.id ? React.createElement("span", {
        className: "select ".concat(selected.id === a.id ? "active" : "")
      }, "\u0421\u043E\u043D\u0433\u043E\u0441\u043E\u043D") : React.createElement("span", {
        className: "select"
      }, "\u0421\u043E\u043D\u0433\u043E\u0445"), React.createElement("span", {
        className: "strong"
      }, "".concat(a.firstName, " ").concat(a.lastName)), React.createElement("div", null, a.phone && React.createElement(React.Fragment, null, "Phone number: ", a.phone, React.createElement("br", null)), a.streetAddress2 && React.createElement(React.Fragment, null, a.streetAddress2, React.createElement("br", null)), a.companyName && React.createElement(React.Fragment, null, a.companyName, React.createElement("br", null)), a.streetAddress1 && React.createElement(React.Fragment, null, a.streetAddress1, React.createElement("br", null)), a.postalCode, ",\xA0", a.city, React.createElement("br", null), a.countryArea, ",\xA0", a.country.country)));
    }));
  }

  if (email) {
    return React.createElement("p", {
      className: "address-summary",
      ref: paragraphRef
    }, email);
  }

  return null;
};

export default CustomAddressSummary;