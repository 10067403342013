import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { Field } from "formik";
import React from "react";
import { ErrorMessage } from "@components/atoms";
import * as S from "./styles";

var Checkbox = function Checkbox(_ref) {
  var errors = _ref.errors,
      helpText = _ref.helpText,
      rest = _objectWithoutProperties(_ref, ["errors", "helpText"]);

  return React.createElement(React.Fragment, null, React.createElement(S.Checkbox, null, rest.label, React.createElement(Field, rest), React.createElement("span", {
    className: "checkmark"
  }, "\xA0"), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  }), helpText && React.createElement(S.HelpText, null, helpText))));
};

export { Checkbox };