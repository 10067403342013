import "./scss/index.scss";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";
import * as React from "react"; // import { FormattedMessage } from "react-intl";

import { generatePath, Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { TaxedMoney } from "@components/containers";
import { useAuth, useCart, useCheckout } from "unurshop-sdk"; // import { commonMessages } from '@temp/intl';

import { Button, Offline, OfflinePlaceholder, Online, Overlay } from "../..";
import { cartUrl, checkoutLoginUrl, checkoutUrl } from "../../../app/routes";
import cartImg from "../../../images/cart.svg";
import closeImg from "../../../images/x.svg";
import Loader from "../../Loader";
import Empty from "./Empty";
import ProductList from "./ProductList";

var Cart = function Cart(_ref) {
  var _checkout$shippingMet;

  var overlay = _ref.overlay;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useCart = useCart(),
      items = _useCart.items,
      addItem = _useCart.addItem,
      removeItem = _useCart.removeItem,
      shippingPrice = _useCart.shippingPrice,
      discount = _useCart.discount,
      totalPrice = _useCart.totalPrice;

  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };

  var missingVariants = function missingVariants() {
    return items.find(function (item) {
      return !item.variant || !item.totalPrice;
    });
  };

  var itemsQuantity = (items === null || items === void 0 ? void 0 : items.filter(function (item) {
    if (item.variant.product) {
      return item.variant.product.productType.id !== PRODUCT_TYPE_SHIPPING;
    }

    return true;
  }).reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0)) || 0;
  return React.createElement(Overlay, {
    testingContext: "cartOverlay",
    context: overlay
  }, React.createElement(Online, null, React.createElement("div", {
    className: "cart"
  }, React.createElement("div", {
    className: "overlay__header"
  }, React.createElement(ReactSVG, {
    path: closeImg,
    onClick: overlay.hide,
    className: "overlay__header__close-icon"
  })), React.createElement("div", {
    className: "ushop-title",
    style: {
      marginTop: "-16px"
    }
  }, React.createElement("div", {
    className: "overlay__cart-icon"
  }, React.createElement(Link, {
    to: generatePath(cartUrl, {
      token: null
    })
  }, React.createElement(ReactSVG, {
    path: cartImg,
    className: "overlay__cart-icon__svg"
  }), React.createElement("span", {
    className: "overlay__cart-icon__quantity"
  }, itemsQuantity, " "))), React.createElement("h4", null, "\u0422\u0430\u043D\u044B \u0441\u0430\u0433\u0441\u0430\u043D \u0434\u0430\u0445\u044C"), React.createElement("h3", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u0436\u0430\u0433\u0441\u0430\u0430\u043B\u0442")), (items === null || items === void 0 ? void 0 : items.length) ? React.createElement(React.Fragment, null, missingVariants() ? React.createElement(Loader, {
    full: true
  }) : React.createElement(React.Fragment, null, React.createElement(ProductList, {
    lines: items,
    add: addItem,
    remove: removeItem
  }), React.createElement("div", {
    className: "cart__footer"
  }, shippingTaxedPrice && shippingTaxedPrice.gross.amount !== 0 && React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, "Shipping"), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "shippingPrice",
    taxedMoney: shippingTaxedPrice
  }))), promoTaxedPrice && promoTaxedPrice.gross.amount !== 0 && React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, "Promo code"), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "promoCodePrice",
    taxedMoney: promoTaxedPrice
  }))), React.createElement("div", {
    className: "cart__footer__price"
  }, React.createElement("span", null, "\u041E\u0434\u043E\u043E\u0433\u0438\u0439\u043D \u043D\u0438\u0439\u0442"), React.createElement("span", null, React.createElement(TaxedMoney, {
    "data-test": "totalPrice",
    taxedMoney: totalPrice
  }))), React.createElement("div", {
    className: "cart__footer__button"
  }, React.createElement(Link, {
    to: generatePath(cartUrl, {
      token: null
    })
  }, React.createElement(Button, {
    testingContext: "gotoBagViewButton",
    secondary: true
  }, "\u0421\u0430\u0433\u0441 \u0440\u0443\u0443 \u043E\u0447\u0438\u0445"))), React.createElement("div", {
    className: "cart__footer__button"
  }, React.createElement(Link, {
    to: user ? checkoutUrl : checkoutLoginUrl
  }, React.createElement(Button, {
    testingContext: "gotoCheckoutButton"
  }, "\u0417\u0430\u0445\u0438\u0430\u043B\u0433\u0430 \u0445\u0438\u0439\u0445")))))) : React.createElement(Empty, {
    overlayHide: overlay.hide
  }))), React.createElement(Offline, null, React.createElement("div", {
    className: "cart"
  }, React.createElement(OfflinePlaceholder, null))));
};

export default Cart;