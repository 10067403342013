import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Icon, Input } from "@components/atoms";
import { InputSelect } from "@components/molecules"; // import { useSelectableProductVariantsAttributeValues } from "@hooks";

import { SelectShippingSidebar } from "../SelectShippingSidebar";
import * as S from "./styles";
export var ShippingAttributeSelect = function ShippingAttributeSelect(_ref) {
  var _ref$selectSidebar = _ref.selectSidebar,
      selectSidebar = _ref$selectSidebar === void 0 ? false : _ref$selectSidebar,
      selectSidebarTarget = _ref.selectSidebarTarget,
      productVariantsAttributeId = _ref.productVariantsAttributeId,
      productVariants = _ref.productVariants,
      productVariantsAttribute = _ref.productVariantsAttribute,
      productVariantsAttributesSelectedValues = _ref.productVariantsAttributesSelectedValues,
      onChangeSelection = _ref.onChangeSelection,
      onClearSelection = _ref.onClearSelection,
      defaultValue = _ref.defaultValue;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showSelectSidebar = _React$useState2[0],
      setShowSelectSidebar = _React$useState2[1]; // const selectableProductVariantsAttributeValues = useSelectableProductVariantsAttributeValues(
  //   productVariantsAttributeId,
  //   productVariants,
  //   productVariantsAttributesSelectedValues
  // );


  var intl = useIntl();
  var selectedAttribute = productVariantsAttributesSelectedValues && productVariantsAttributesSelectedValues[productVariantsAttributeId];
  var selectedValue = selectedAttribute && {
    disabled: false,
    id: selectedAttribute.id,
    label: selectedAttribute.name,
    value: selectedAttribute.value
  }; //   const attributeOptions = productVariantsAttribute.values
  //     .filter(value => value)
  //     .map(value => {
  //       const selectableAttribute =
  //         selectableProductVariantsAttributeValues[productVariantsAttributeId];
  //       const isOptionDisabled =
  //         selectableAttribute && !selectableAttribute.values.includes(value);
  //       return {
  //         disabled: isOptionDisabled,
  //         id: value.id,
  //         label: value.name!,
  //         value: value.value!,
  //       };
  //     });

  var attributeOptions = [{
    id: "air",
    name: "Агаараар",
    value: "Агаараар",
    __typename: "AttributeValue"
  }, {
    id: "ground",
    name: "Газраар",
    value: "Газраар",
    __typename: "AttributeValue"
  }].filter(function (value) {
    return value;
  }).map(function (value) {
    //   const selectableAttribute =
    //     selectableProductVariantsAttributeValues[productVariantsAttributeId];
    //   const isOptionDisabled =
    //     selectableAttribute && !selectableAttribute.values.includes(value);
    return {
      disabled: false,
      id: value.id,
      label: value.name,
      value: value.value
    };
  });
  var selectLabel = productVariantsAttribute.attribute.name || "";
  var selectedValuesList = selectedValue ? [selectedValue.value] : [];
  var disabledValuesList = attributeOptions.filter(function (optionValue) {
    return optionValue.disabled;
  }).map(function (optionValue) {
    return optionValue.value;
  });

  var onSelectValueHandler = function onSelectValueHandler(optionValue, callback) {
    if (disabledValuesList.every(function (disabledValue) {
      return disabledValue !== optionValue;
    })) {
      onChangeSelection(optionValue);

      if (callback) {
        callback();
      }
    }
  };

  var handleSelectValueInSidebar = function handleSelectValueInSidebar(optionValue) {
    return onSelectValueHandler(optionValue, function () {
      return setShowSelectSidebar(false);
    });
  };

  var getRightInputContent = function getRightInputContent(isInputFilled) {
    if (isInputFilled) {
      return React.createElement(S.SelectIndicator, {
        onClick: onClearSelection
      }, React.createElement(Icon, {
        name: "select_x",
        size: 10
      }));
    }

    return React.createElement(S.SelectIndicator, {
      onClick: function onClick() {
        return setShowSelectSidebar(true);
      }
    }, React.createElement(Icon, {
      name: "subcategories",
      size: 10
    }));
  };

  useEffect(function () {
    if (defaultValue) {
      onSelectValueHandler(defaultValue);
    }
  }, [defaultValue]);

  if (selectSidebar) {
    return React.createElement(React.Fragment, null, React.createElement(Input, {
      onFocus: function onFocus() {
        return setShowSelectSidebar(true);
      },
      label: "\u0422\u044D\u044D\u0432\u044D\u0440\u043B\u044D\u043B\u0442",
      value: selectedValue ? selectedValue.value : "",
      onChange: function onChange() {
        return null;
      },
      contentRight: getRightInputContent(!!selectedValue),
      readOnly: true,
      name: productVariantsAttribute.attribute.slug ? productVariantsAttribute.attribute.slug : "",
      "data-test": "variantPicker"
    }), React.createElement(SelectShippingSidebar, {
      options: attributeOptions,
      selectedOptions: selectedValuesList,
      disabledOptions: disabledValuesList,
      title: intl.formatMessage(_defineProperty({
        defaultMessage: "Тээвэрлэлт",
        "id": "@next.components.organisms.ShippingPicker.ShippingAttributeSelect.178564282"
      }, "id", "@next.components.organisms.ShippingPicker.ShippingAttributeSelect.178564282"), {
        selectLabel: selectLabel
      }),
      show: showSelectSidebar,
      hide: function hide() {
        return setShowSelectSidebar(false);
      },
      onSelect: handleSelectValueInSidebar,
      target: selectSidebarTarget,
      testingContextId: productVariantsAttribute.attribute.slug ? productVariantsAttribute.attribute.slug : ""
    }));
  }

  return React.createElement(InputSelect, {
    name: productVariantsAttribute.attribute.id,
    label: selectLabel,
    value: selectedValue,
    options: attributeOptions,
    isOptionDisabled: function isOptionDisabled(optionValue) {
      return optionValue.disabled;
    },
    onChange: function onChange(optionValue) {
      return onChangeSelection(optionValue === null || optionValue === void 0 ? void 0 : optionValue.value);
    },
    clearable: true,
    clearValue: onClearSelection
  });
};