import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import iconEyeHide from "images/unurshop/eye-hide.svg";
import iconEye from "images/unurshop/eye.svg";
import ReactSVG from "react-svg";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import * as S from "@components/organisms/CheckoutAddress/styles";
import { accountConfirmUrl } from "../../../app/routes";
import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { TypedAccountRegisterMutation } from "./queries";

var RegisterForm = function RegisterForm(_ref) {
  var hide = _ref.hide,
      _email = _ref._email,
      _setEmail = _ref._setEmail,
      _setPage = _ref._setPage,
      pageLoading = _ref.loading;
  var alert = useAlert();
  var intl = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showInfo = _React$useState2[0],
      setShowInfo = _React$useState2[1];

  var _React$useState3 = React.useState(iconEye),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      icon = _React$useState4[0],
      setIcon = _React$useState4[1];

  var _React$useState5 = React.useState("password"),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      passInputType = _React$useState6[0],
      setPassInputType = _React$useState6[1];

  var togglePassInput = function togglePassInput() {
    if (passInputType === "password") {
      setPassInputType("text");
      setIcon(iconEyeHide);
    } else {
      setPassInputType("password");
      setIcon(iconEye);
    }
  };

  var showSuccessNotification = function showSuccessNotification(data, hide, alert, intl) {
    var successful = maybe(function () {
      return !data.accountRegister.errors.length;
    });

    if (successful) {
      setShowInfo(true); // hide();
      // alert.show(
      //   {
      //     title: data.accountRegister.requiresConfirmation
      //       ? intl.formatMessage({
      //           defaultMessage:
      //             "Та и-мэйл хаягаа шалгалж бүртгэлээ баталгаажуулна уу",
      //         })
      //       : intl.formatMessage({
      //           defaultMessage: "New user has been created",
      //         }),
      //   },
      //   { type: "success", timeout: 5000 }
      // );
    }
  };

  return React.createElement(TypedAccountRegisterMutation, {
    onCompleted: function onCompleted(data) {
      return showSuccessNotification(data, hide, alert, intl);
    }
  }, function (registerCustomer, _ref2) {
    var loading = _ref2.loading,
        data = _ref2.data;
    return React.createElement(React.Fragment, null, !showInfo && React.createElement(Form, {
      data: {
        email: _email,
        password: "",
        firstName: "",
        lastName: "",
        phone: ""
      },
      errors: maybe(function () {
        return data.accountRegister.errors;
      }, []),
      onSubmit: function onSubmit(event, _ref3) {
        var email = _ref3.email,
            password = _ref3.password,
            firstName = _ref3.firstName,
            lastName = _ref3.lastName,
            phone = _ref3.phone;
        event.preventDefault();
        var redirectUrl = "".concat(window.location.origin).concat(accountConfirmUrl);
        registerCustomer({
          variables: {
            email: email.trim().toLowerCase(),
            password: password,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            redirectUrl: redirectUrl
          }
        });
      }
    }, React.createElement(TextField, {
      name: "email",
      autoComplete: "email",
      label: intl.formatMessage(commonMessages.eMail),
      type: "email",
      onChange: function onChange(e) {
        _setEmail(e.target.value);
      },
      required: true
    }), React.createElement(TextField, {
      name: "lastName",
      label: intl.formatMessage(commonMessages.lastName),
      type: "text",
      required: true
    }), React.createElement(TextField, {
      name: "firstName",
      label: intl.formatMessage(commonMessages.firstName),
      type: "text",
      required: true
    }), React.createElement(TextField, {
      name: "phone",
      label: intl.formatMessage(commonMessages.phone),
      type: "number",
      required: true
    }), React.createElement(TextField, {
      name: "password",
      autoComplete: "password",
      label: intl.formatMessage(commonMessages.password),
      type: passInputType,
      required: true,
      iconRight: React.createElement(ReactSVG, {
        path: icon,
        onClick: function onClick() {
          return togglePassInput();
        },
        style: {
          marginRight: 10,
          marginBottom: -10
        }
      })
    }), React.createElement("div", {
      className: "login__content__button"
    }, React.createElement(Button, _extends({
      className: "full",
      testingContext: "submitRegisterFormButton",
      type: "submit"
    }, (loading || pageLoading) && {
      disabled: true
    }), loading || pageLoading ? intl.formatMessage(commonMessages.loading) : intl.formatMessage(commonMessages.createAnAccount)))), showInfo && React.createElement("section", null, React.createElement(S.SuccessContainer, null, React.createElement(S.AlertTitle, null, "\u0410\u043C\u0436\u0438\u043B\u0442\u0442\u0430\u0439: "), React.createElement(S.AlertContent, null, "\u0422\u0430\u043D\u044B \u0431\u04AF\u0440\u0442\u0433\u044D\u043B \u0430\u043C\u0436\u0438\u043B\u0442\u0442\u0430\u0439 \u0445\u0438\u0439\u0433\u0434\u043B\u044D\u044D")), React.createElement(S.AlertContainer, null, React.createElement(S.AlertTitle, null, "\u0410\u043D\u0445\u0430\u0430\u0440\u0443\u0443\u043B\u0433\u0430: "), React.createElement(S.AlertContent, null, "\u0422\u0430 ", React.createElement("b", {
      style: {
        fontWeight: 600
      }
    }, _email), " \u0445\u0430\u044F\u0433\u0430\u0430 \u0448\u0430\u043B\u0433\u0430\u043B\u0436 \u0431\u04AF\u0440\u0442\u0433\u044D\u043B\u044D\u044D \u0431\u0430\u0442\u0430\u043B\u0433\u0430\u0430\u0436\u0443\u0443\u043B\u043D\u0430 \u0443\u0443. Junk \u044D\u0441\u0432\u044D\u043B Spam \u0444\u043E\u043B\u0434\u0435\u0440\u0442 \u043E\u0440\u0441\u043E\u043D \u0431\u0430\u0439\u0436 \u0431\u043E\u043B\u0437\u043E\u0448\u0433\u04AF\u0439"))), React.createElement(Button, {
      style: {
        marginTop: "6em"
      },
      testingContext: "registerButton",
      secondary: true,
      className: "full small",
      onClick: function onClick() {
        return _setPage("login");
      }
    }, intl.formatMessage(commonMessages.back)));
  });
};

export default RegisterForm;