import { IssueStatus, IssueType } from "gqlTypes/globalTypes";
import React from "react";
import { Money } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import useOrderDetailModal from "@hooks/useOrderDetailModal";
// import { TypedIssueAddChat, TypedIssueUpdate } from "../../query";
import IssueDialog from "./IssueDialog";

var renderLineInfo = function renderLineInfo(line) {
  return React.createElement("div", {
    className: "product-info"
  }, React.createElement(Thumbnail, {
    source: line.orderLine.variant.product
  }), React.createElement("div", {
    className: "product-name-size"
  }, React.createElement("b", null, line.orderLine.variant.product.name), line.orderLine.variant.attributes.map(function (_ref, attributeIndex) {
    var attribute = _ref.attribute,
        values = _ref.values;
    return React.createElement("p", {
      key: "product-attribute-".concat(attribute.id, "-").concat(attributeIndex)
    }, attribute.name, ": ", values.map(function (value) {
      return value.name;
    }).join(", "));
  })));
};

var RenderLineTr = function RenderLineTr(props) {
  var _line$orderLine;

  var line = props.line,
      gkey = props.gkey,
      refetchOrder = props.refetchOrder,
      setRefetchChat = props.setRefetchChat;

  var _useOrderDetailModal = useOrderDetailModal(),
      openOrderDetailModal = _useOrderDetailModal.openOrderDetailModal; // const [loading, setLoading] = React.useState(false);
  // const onCompletedIssueUpdate = () => {
  //   () => refetchOrder();
  // };


  return React.createElement(React.Fragment, null, React.createElement("tr", {
    onClick: function onClick() {
      return openOrderDetailModal({
        line: line.orderLine,
        testingContext: null
      });
    },
    style: line.quantity === 0 ? {
      opacity: 0.4
    } : null
  }, React.createElement("td", null, line === null || line === void 0 ? void 0 : (_line$orderLine = line.orderLine) === null || _line$orderLine === void 0 ? void 0 : _line$orderLine.sortOrder), React.createElement("td", null, renderLineInfo(line)), React.createElement("td", null, React.createElement(Money, {
    money: line.orderLine.unitPrice.gross
  })), React.createElement("td", null, gkey === "cancelled" ? line.orderLine.quantityCanceled : line.quantity), React.createElement("td", null, React.createElement(Money, {
    money: {
      currency: line.orderLine.unitPrice.gross.currency,
      amount: gkey === "cancelled" ? line.orderLine.quantityCanceled * line.orderLine.unitPrice.gross.amount : line.quantity * line.orderLine.unitPrice.gross.amount
    }
  }))), line.orderLine.issues.edges.length > 0 && React.createElement("tr", null, React.createElement("td", {
    colSpan: 5
  }, line.orderLine.issues.edges.map(function (e) {
    return e.node;
  }).filter(function (i) {
    return i.status === IssueStatus.TODO && i.type === IssueType.ISSUE;
  }).map(function (i) {
    return (// <div className="issue-alert" key={`ushop-alert-${i.id}`}>
      //   <div className="issue-alert__errorSymbol">!</div>
      //   <div style={{ width: "100%" }}>
      //     <h6 className="issue-alert__title">{i.name}</h6>
      //     <div className="issue-alert__content">
      //       <span>{i.description}</span>
      //       <TypedIssueUpdate
      //         onCompleted={() => setRefetchChat(true)}
      //       >
      //         {issueUpdate => {
      //           return (
      //             <TypedIssueAddChat onCompleted={refetchOrder}>
      //               {issueAddNote => {
      //                 return (
      //                   <div className="issue-button">
      //                     <button
      //                       className="issue-button__issueYesButton"
      //                       disabled={loading}
      //                       onClick={() => {
      //                         setLoading(true);
      //                         issueAddNote({
      //                           variables: {
      //                             issue: i.id,
      //                             input: { message: "Тийм" },
      //                           },
      //                         });
      //                         issueUpdate({
      //                           variables: {
      //                             id: i.id,
      //                             input: { status: IssueStatus.DONE },
      //                           },
      //                         });
      //                         issueUpdate({
      //                           variables: {
      //                             id: i.parent.id,
      //                             input: { status: IssueStatus.TODO },
      //                           },
      //                         });
      //                       }}
      //                     >
      //                       Тийм
      //                     </button>
      //                     <button
      //                       className="issue-button__issueNoButton"
      //                       disabled={loading}
      //                       onClick={() => {
      //                         setLoading(true);
      //                         issueAddNote({
      //                           variables: {
      //                             issue: i.id,
      //                             input: { message: "Үгүй" },
      //                           },
      //                         });
      //                         issueUpdate({
      //                           variables: {
      //                             id: i.id,
      //                             input: { status: IssueStatus.DONE },
      //                           },
      //                         });
      //                         issueUpdate({
      //                           variables: {
      //                             id: i.parent.id,
      //                             input: { status: IssueStatus.TODO },
      //                           },
      //                         });
      //                       }}
      //                     >
      //                       Үгүй
      //                     </button>
      //                   </div>
      //                 );
      //               }}
      //             </TypedIssueAddChat>
      //           );
      //         }}
      //       </TypedIssueUpdate>
      //     </div>
      //   </div>
      // </div>
      React.createElement(IssueDialog, {
        key: "issue-".concat(i.id),
        issue: i,
        refetchOrder: refetchOrder,
        setRefetchChat: setRefetchChat
      })
    );
  }))));
};

export default RenderLineTr;