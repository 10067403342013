import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React from "react";
import { FormattedDate } from "react-intl";
import { Money } from "@components/containers";
import { Togrog } from "@components/containers/Togrog";
import { MNT } from "@temp/constants";
import { maybe } from "@temp/misc";
import { HR } from "../../DraftOrder/components/styles";

var PaymentHistory = function PaymentHistory(_ref) {
  var order = _ref.order,
      refetchOrder = _ref.refetchOrder;
  var gatewayName = {
    "mirumee.payments.stripe": "Stripe төлсөн",
    "unurshop.payments.qpay": "QPay төлсөн"
  };
  var paymentsWTMerged = [].concat(_toConsumableArray(order.payments.filter(function (payment) {
    return payment.chargeStatus === "FULLY_CHARGED" || payment.chargeStatus === "PARTIALLY_CHARGED";
  }).map(function (p) {
    return {
      capturedAmount: p.capturedAmount.amount,
      created: p.created,
      description: p.gateway in gatewayName ? gatewayName[p.gateway] : p.gateway,
      id: p.id
    };
  })), _toConsumableArray(order.walletTransactions.filter(function (wt) {
    return wt.deletedAt === null && wt.credit !== 0 && wt.status === "SUCCESS";
  }).map(function (wt) {
    return {
      capturedAmount: wt.credit,
      created: wt.createdAt,
      description: "Хэтэвчээр төлсөн",
      id: wt.id
    };
  }))).sort(function a(a, b) {
    var _a;

    return ((_a = a) === null || _a === void 0 ? void 0 : _a.created) > (b === null || b === void 0 ? void 0 : b.created) ? -1 : 1;
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    style: {
      opacity: 0.7
    }
  }, maybe(function () {
    return paymentsWTMerged;
  }) === undefined ? "Hello" : paymentsWTMerged === null || paymentsWTMerged === void 0 ? void 0 : paymentsWTMerged.map(function (list, index) {
    return React.createElement(React.Fragment, {
      key: list === null || list === void 0 ? void 0 : list.id
    }, React.createElement("div", {
      className: "slip-container__row small"
    }, React.createElement("div", {
      className: "slip-container__row__column",
      style: {
        padding: "5px 0px"
      }
    }, React.createElement("div", {
      style: {
        lineHeight: "14px"
      }
    }, list === null || list === void 0 ? void 0 : list.description, " ", React.createElement("br", null), React.createElement("span", {
      style: {
        fontSize: 10
      }
    }, "(", React.createElement(FormattedDate, {
      value: list.created
    }), ")"))), React.createElement("div", {
      className: "slip-container__row__column",
      style: {
        padding: "5px 0px"
      }
    }, React.createElement(Togrog, {
      amount: Number((list.capturedAmount * MNT).toFixed(0))
    }), "\xA0", React.createElement("span", {
      style: {
        color: "rgba(0,0,0,.4)"
      }
    }, "(", React.createElement(Money, {
      money: {
        amount: list.capturedAmount,
        currency: order.totalCaptured.currency
      }
    }), ")"))), React.createElement(HR, {
      className: index + 1 !== paymentsWTMerged.length ? "hr" : null,
      style: index + 1 !== paymentsWTMerged.length ? {} : {
        marginTop: "1rem"
      }
    }));
  })));
};

export { PaymentHistory };