import _extends from "@babel/runtime/helpers/extends";
import React, { useCallback } from "react";
import { Checkbox, TextField } from "@components/molecules"; // import { Checkbox, InputSelect, TextField } from '@components/molecules';

import { Button } from "@temp/components";
import * as S from "./styles";

var BookingForm = function BookingForm(_ref) {
  var handleBlur = _ref.handleBlur,
      handleChange = _ref.handleChange,
      handleSubmit = _ref.handleSubmit,
      setFieldTouched = _ref.setFieldTouched,
      setFieldValue = _ref.setFieldValue,
      values = _ref.values,
      ushopId = _ref.ushopId,
      productTypeOptions = _ref.productTypeOptions;
  var basicInputProps = useCallback(function () {
    return {
      onBlur: handleBlur,
      onChange: handleChange
    };
  }, [handleChange, handleBlur]); // if (errors) {
  //     errors.map(({ field, message }: { field: string; message: string }) => {
  //         fieldErrors[field] = fieldErrors[field]
  //             ? [...fieldErrors[field], { message }]
  //             : [{ message }];
  //     });
  // }

  var fieldErrors = {};
  return React.createElement(S.Wrapper, null, React.createElement(S.Form, {
    onSubmit: handleSubmit
  }, React.createElement("div", {
    className: "ushop-title"
  }, React.createElement("h4", null, "\u0422\u0430 \u0441\u043E\u043D\u0433\u043E\u0441\u043E\u043D \u0431\u0430\u0440\u0430\u0430\u043D\u044B\u0445\u0430\u0430"), React.createElement("h3", null, "\u041C\u044D\u0434\u044D\u044D\u043B\u043B\u0438\u0439\u0433 \u043E\u0440\u0443\u0443\u043B\u043D\u0430 \u0443\u0443!")), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    autoComplete: "off",
    "data-cy": "bookingName",
    name: "name",
    label: "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u043D\u044D\u0440",
    value: values.name,
    errors: fieldErrors.name
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    autoComplete: "off",
    type: "number",
    "data-cy": "bookingPrice",
    name: "price",
    step: ".01",
    min: ".5",
    label: "\u04AE\u043D\u044D /\u0444\u0443\u043D\u0442/",
    value: values.price,
    errors: fieldErrors.price
  }, basicInputProps())), React.createElement(TextField, _extends({
    autoComplete: "off",
    type: "number",
    "data-cy": "bookingQuantity",
    name: "quantity",
    step: "1",
    min: "1",
    label: "\u0422\u043E\u043E \u0448\u0438\u0440\u0445\u044D\u0433",
    value: values.quantity,
    errors: fieldErrors.quantity
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    autoComplete: "off",
    "data-cy": "bookingColor",
    name: "color",
    label: "\u04E8\u043D\u0433\u04E9",
    value: values.color,
    errors: fieldErrors.color
  }, basicInputProps())), React.createElement(TextField, _extends({
    autoComplete: "off",
    "data-cy": "bookingSize",
    name: "size",
    label: "\u0425\u044D\u043C\u0436\u044D\u044D",
    value: values.size,
    errors: fieldErrors.size
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    autoComplete: "off",
    "data-cy": "bookingCode",
    name: "code",
    label: "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u043A\u043E\u0434",
    value: values.code,
    errors: fieldErrors.code
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(Checkbox, _extends({
    name: "isRequired",
    type: "checkbox",
    label: "\u0417\u0430\u0430\u0432\u0430\u043B \u0430\u0432\u0430\u0445 \u0431\u0430\u0440\u0430\u0430",
    value: values.isRequired,
    errors: fieldErrors.isRequired
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    autoComplete: "off",
    "data-cy": "bookingDescription",
    name: "description",
    label: "\u041D\u044D\u043C\u044D\u043B\u0442 \u0441\u0430\u043D\u0430\u043B \u0445\u04AF\u0441\u044D\u043B\u0442",
    value: values.description,
    errors: fieldErrors.description
  }, basicInputProps()))), React.createElement(Checkbox, _extends({
    name: "addMore",
    type: "checkbox",
    label: "\u04E8\u04E9\u0440 \u04E9\u043D\u0433\u04E9, \u0445\u044D\u043C\u0436\u044D\u044D\u0433\u044D\u044D\u0440 \u0434\u0430\u0445\u0438\u043D \u0437\u0430\u0445\u0438\u0430\u043B\u0430\u0445",
    value: values.isRequired,
    errors: fieldErrors.isRequired
  }, basicInputProps())), React.createElement(S.FormControl, null, React.createElement(Button, {
    testingContext: "addbasket",
    type: "submit"
  }, "\u0421\u0430\u0433\u0441\u0430\u043D\u0434 \u0445\u0438\u0439\u0445"), React.createElement(Button, {
    testingContext: "back",
    secondary: true
  }, "\u0411\u0443\u0446\u0430\u0445"))));
};

export { BookingForm };