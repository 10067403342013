import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { Link } from "react-router-dom";
import { PRODUCT_TYPE_SHIPPING } from "@app/custom/constants";
import { Money, TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { generateProductUrl } from "@temp/core/utils";

var ProductList = function ProductList(_ref) {
  var lines = _ref.lines;

  var missingVariants = function missingVariants() {
    return lines === null || lines === void 0 ? void 0 : lines.find(function (item) {
      return !item.variant || !item.totalPrice;
    });
  };

  if (missingVariants()) {
    return React.createElement(React.Fragment, null, "loading...");
  }

  var ushops = [];
  var variants = (lines === null || lines === void 0 ? void 0 : lines.map(function (tmp) {
    return tmp.variant;
  })) || [];
  var productVariants = variants.filter(function (tmp) {
    var _tmp$product;

    return ((_tmp$product = tmp.product) === null || _tmp$product === void 0 ? void 0 : _tmp$product.productType.id) !== PRODUCT_TYPE_SHIPPING;
  });
  var shippingVariants = variants.filter(function (tmp) {
    var _tmp$product2;

    return ((_tmp$product2 = tmp.product) === null || _tmp$product2 === void 0 ? void 0 : _tmp$product2.productType.id) === PRODUCT_TYPE_SHIPPING;
  });
  /** productuudiig delguurt hargalzuulj bga hesge */

  productVariants.map(function (variant) {
    var shop = ushops.find(function (el) {
      var _variant$product;

      return el.id === ((_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.ushop.id);
    });
    var line = lines.find(function (el) {
      return el.variant.id === variant.id;
    }); // if (variant.product.metadata) {
    //   line.variant.product["metadata"] = variant.product.metadata;
    // }

    if (!shop) {
      var _variant$product2;

      ushops.push(_objectSpread({}, (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.ushop, {
        lines: [line]
      }));
    } else {
      shop.lines.push(line);
    }
  });
  shippingVariants.map(function (el) {
    var ushop = ushops.find(function (shop) {
      var _el$product;

      return shop.id === ((_el$product = el.product) === null || _el$product === void 0 ? void 0 : _el$product.ushop.id);
    });

    if (ushop) {
      var _el$pricing, _el$pricing$price;

      ushop.shippingUkGross = el === null || el === void 0 ? void 0 : (_el$pricing = el.pricing) === null || _el$pricing === void 0 ? void 0 : (_el$pricing$price = _el$pricing.price) === null || _el$pricing$price === void 0 ? void 0 : _el$pricing$price.gross;
    }
  });
  return React.createElement(React.Fragment, null, ushops === null || ushops === void 0 ? void 0 : ushops.map(function (_shop, index) {
    var shopTotal = _shop.lines.map(function (line) {
      var _line$totalPrice;

      return (_line$totalPrice = line.totalPrice) === null || _line$totalPrice === void 0 ? void 0 : _line$totalPrice.gross.amount;
    }).reduce(function (a, c) {
      return a + c;
    }, 0);

    var priceFormat = null;
    return React.createElement("div", {
      key: index,
      className: "cart__shop"
    }, React.createElement("h5", {
      className: "cart__shop__title"
    }, React.createElement("span", {
      className: "cart__shop__name"
    }, React.createElement("img", {
      className: "cart__shop__logo",
      src: _shop.logoImage.url,
      alt: _shop.name
    }), React.createElement("span", null, _shop.name)), React.createElement("span", {
      className: "cart__shop__total"
    }, React.createElement(Money, {
      money: {
        amount: shopTotal,
        currency: "GBP"
      }
    }))), React.createElement(React.Fragment, null, React.createElement("ul", {
      className: "cart__list"
    }, _shop.lines.map(function (line, index) {
      var productUrl = generateProductUrl(line.variant.product.id, line.variant.product.name);
      var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);

      if (!priceFormat) {
        var _line$variant$pricing;

        priceFormat = (_line$variant$pricing = line.variant.pricing.price) === null || _line$variant$pricing === void 0 ? void 0 : _line$variant$pricing.gross;
      }

      return React.createElement("li", {
        key: key,
        className: "cart__list__item"
      }, React.createElement(Link, {
        to: productUrl
      }, React.createElement(Thumbnail, {
        source: line.variant.product
      })), React.createElement("div", {
        className: "cart__list__item__details"
      }, React.createElement("p", null, React.createElement(TaxedMoney, {
        taxedMoney: line.variant.pricing.price
      })), React.createElement(Link, {
        to: productUrl
      }, React.createElement("p", null, line.variant.product.name)), React.createElement("span", {
        className: "cart__list__item__details__variant"
      }, React.createElement("span", null, line.variant.name), React.createElement("span", null, "\u0422\u043E\u043E: ".concat(line.quantity)))));
    })), React.createElement("table", {
      className: "ushop-price-table"
    }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u04AF\u043D\u044D"), React.createElement("td", null, React.createElement("b", null, React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: shopTotal
      })
    })))), React.createElement("tr", null, React.createElement("td", null, "\u0410\u043D\u0433\u043B\u0438 \u0434\u043E\u0442\u043E\u043E\u0434 \u0445\u04AF\u0440\u0433\u044D\u043B\u0442"), React.createElement("td", null, (_shop === null || _shop === void 0 ? void 0 : _shop.shippingUkGross) ? React.createElement(Money, {
      money: _shop.shippingUkGross
    }) : _shop.id === "VXNob3A6MQ==" ? "Free" : "-"))), React.createElement("tfoot", null, React.createElement("tr", null, React.createElement("td", null, "\u041D\u0438\u0439\u0442 (".concat(_shop.name, ")")), React.createElement("td", null, React.createElement(Money, {
      money: _objectSpread({}, priceFormat, {
        amount: Number(shopTotal) + ((_shop === null || _shop === void 0 ? void 0 : _shop.shippingUkGross) ? _shop === null || _shop === void 0 ? void 0 : _shop.shippingUkGross.amount : 0)
      })
    })))))));
  }));
};

export default ProductList;