import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { useIntl } from "react-intl";
import { InputSelect, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AddressFormContentMongolia = function AddressFormContentMongolia(_ref) {
  var basicInputProps = _ref.basicInputProps,
      fieldErrors = _ref.fieldErrors,
      setFieldValue = _ref.setFieldValue,
      values = _ref.values,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;
  var intl = useIntl();
  var citiesOptions = [{
    key: "Улаанбаатар",
    value: "Улаанбаатар"
  }, {
    key: "Дархан",
    value: "Дархан"
  }, {
    key: "Эрдэнэт",
    value: "Эрдэнэт"
  }];
  var districtsOptions = [{
    key: "Багануур",
    value: "Багануур",
    city: "Улаанбаатар"
  }, {
    key: "Багахангай",
    value: "Багахангай",
    city: "Улаанбаатар"
  }, {
    key: "Баянгол",
    value: "Баянгол",
    city: "Улаанбаатар"
  }, {
    key: "Баянзүрх",
    value: "Баянзүрх",
    city: "Улаанбаатар"
  }, {
    key: "Налайх",
    value: "Налайх",
    city: "Улаанбаатар"
  }, {
    key: "Сонгинохайрхан",
    value: "Сонгинохайрхан",
    city: "Улаанбаатар"
  }, {
    key: "Сүхбаатар",
    value: "Сүхбаатар",
    city: "Улаанбаатар"
  }, {
    key: "Хан-Уул",
    value: "Хан-Уул",
    city: "Улаанбаатар"
  }, {
    key: "Чингэлтэй",
    value: "Чингэлтэй",
    city: "Улаанбаатар"
  }, {
    key: "Дархан",
    value: "Дархан",
    city: "Дархан"
  }, {
    key: "Эрдэнэт",
    value: "Эрдэнэт",
    city: "Эрдэнэт"
  }]; // console.log(values);

  return React.createElement(React.Fragment, null, React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "lastName",
    label: intl.formatMessage(commonMessages.lastName),
    value: values.lastName,
    autoComplete: "family-name",
    placeholder: "\u0414\u0430\u043B\u0445\u0441\u04AF\u0440\u044D\u043D",
    errors: fieldErrors.lastName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "firstName",
    label: intl.formatMessage(commonMessages.firstName),
    value: values.firstName,
    autoComplete: "given-name",
    placeholder: "\u04E8\u043D\u04E9\u0440\u0446\u044D\u0446\u044D\u0433",
    errors: fieldErrors.firstName
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress2",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Регистрийн дугаар",
      "id": "@next.components.organisms.AddressForm.AddressFormContentMongolia.3545066774"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentMongolia.3545066774")),
    value: values.streetAddress2,
    autoComplete: "address-line2",
    errors: fieldErrors.streetAddress2
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "companyName",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Байгууллагын нэр (заавал биш)",
      "id": "@next.components.organisms.AddressForm.AddressFormContentMongolia.2765405485"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentMongolia.2765405485")),
    value: values.companyName,
    autoComplete: "organization",
    errors: fieldErrors.companyName
  }, basicInputProps())), React.createElement(TextField, _extends({
    name: "phone",
    label: intl.formatMessage(commonMessages.phone),
    value: values.phone || undefined,
    autoComplete: "tel",
    errors: fieldErrors.phone
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(InputSelect, {
    defaultValue: values.city,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Хот / Аймаг",
      "id": "@next.components.organisms.AddressForm.AddressFormContentMongolia.3618125493"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentMongolia.3618125493")),
    name: "city",
    options: citiesOptions,
    value: values.city && citiesOptions && citiesOptions.find(function (option) {
      return String(option.key).trim().toLowerCase() === String(values.city).trim().toLowerCase();
    }),
    onChange: function onChange(value, name) {
      return setFieldValue(name, value.key);
    },
    optionLabelKey: "value",
    optionValueKey: "key",
    errors: fieldErrors.city,
    autoComplete: "country"
  }), React.createElement(InputSelect, {
    defaultValue: values.countryArea,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Дүүрэг",
      "id": "@next.components.organisms.AddressForm.AddressFormContentMongolia.4076576086"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentMongolia.4076576086")),
    name: "countryArea",
    options: districtsOptions.filter(function (e) {
      return String(e.city).trim().toLowerCase() === String(values.city).trim().toLowerCase();
    }),
    value: values.countryArea && districtsOptions && districtsOptions.find(function (option) {
      return String(option.key).trim().toLowerCase() === String(values.countryArea).trim().toLowerCase();
    }),
    onChange: function onChange(value, name) {
      return setFieldValue(name, value.key);
    },
    optionLabelKey: "value",
    optionValueKey: "key",
    errors: fieldErrors.countryArea,
    autoComplete: "countryArea"
  })), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress1",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Хаяг",
      "id": "@next.components.organisms.AddressForm.AddressFormContentMongolia.1522408335"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentMongolia.1522408335")),
    value: values.streetAddress1,
    autoComplete: "address-line1",
    errors: fieldErrors.streetAddress1
  }, basicInputProps()))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "postalCode",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "ZIP/Postal Code",
      "id": "@next.components.organisms.AddressForm.AddressFormContentMongolia.2796540622"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContentMongolia.2796540622")),
    value: values.postalCode,
    autoComplete: "postal-code",
    errors: fieldErrors.postalCode
  }, basicInputProps()))), includeEmail && React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "email",
    label: intl.formatMessage(commonMessages.shortEmail),
    value: values.email,
    autoComplete: "email",
    errors: fieldErrors.email
  }, basicInputProps()))));
};