export function renderCollection(collection, renderItem, renderEmpty) {
  if (collection === undefined) {
    return renderItem(undefined, undefined, collection);
  }

  if (collection.length === 0) {
    return !renderEmpty ? renderEmpty(collection) : null;
  }

  return collection.map(renderItem);
}
export function decimal(value) {
  if (typeof value === "string") {
    return value === "" ? null : value;
  }

  return value;
}
export function weight(value) {
  return value === "" ? null : parseFloat(value);
}
export var removeDoubleSlashes = function removeDoubleSlashes(url) {
  return url.replace(/([^:]\/)\/+/g, "$1");
};
export function maybe(exp, d) {
  try {
    var result = exp();
    return result === undefined ? d : result;
  } catch (_unused) {
    return d;
  }
}
export function only(obj, key) {
  return Object.keys(obj).every(function (objKey) {
    return objKey === key ? obj[key] !== undefined : obj[key] === undefined;
  });
}
export function empty(obj) {
  return Object.keys(obj).every(function (key) {
    return obj[key] === undefined;
  });
}
export function formatDate(date) {
  var d = new Date(date);
  var month = "".concat(d.getMonth() + 1);
  var day = "".concat(d.getDate());
  var year = d.getFullYear();
  if (month.length < 2) month = "0".concat(month);
  if (day.length < 2) day = "0".concat(day);
  return [year, month, day].join("-");
}