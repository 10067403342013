import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  background-image: url(", ");\n  width: 100px;\n  height: 120px;\n  background-size: cover;\n  background-repeat: no-repeat;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  background-color: #f7f7f7;\n  padding: 2px 5px;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  margin-bottom: 1rem;\n  padding-top: 1rem;\n  // border-top: 0.3px solid rgba(0, 0, 0, 0.3);\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n    align-items:start;\n    flex-direction:column;\n    .titleMoney{\n      justify-content:flex-end;\n      margin-top:10px;\n    }\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  padding: 1rem;\n  justify-content: space-between;\n  background-color: #f7f7f7;\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  background-color: black;\n  color: white;\n  padding: 1rem 3.5rem;\n  margin: 1rem;\n  text-transform: uppercase;\n  font-weight: bold;\n  border-radius: 4px;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n    margin-top:110px;\n  "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  position: initial;\n  margin-top: 84px;\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    width: auto;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  text-align: center;\n  width: 566px;\n  padding: 8px 0;\n  border-bottom: 0.3px solid #ddd;\n  background-color: white;\n  text-transform: uppercase;\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  // padding: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    align-items: inherit;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: fixed;\n  overflow-y: auto;\n  width: 100%;\n  height: 100%;\n  min-height: 80vh;\n  top: 0;\n  z-index: 30;\n  transition: opacity 0.2s ease;\n  transition-delay: ", ";\n  background-color: ", ";\n  justify-content: ", ";\n  opacity: ", ";\n  align-items: center;\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: fixed;\n  overflow-y: auto;\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  top: 0;\n  z-index: 30;\n  transition: opacity 0.2s ease;\n  transition-delay: ", ";\n  background-color: ", ";\n  align-items: center;\n  justify-content: ", ";\n  opacity: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n        ", ": 0;\n        transform: translateX(", ");\n        animation: ", " 0.4s both;\n        animation-delay: ", ";\n      "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  max-height: 95vh;\n  width: ", ";\n  min-height: ", "px;\n  overflow-y: auto;\n  background-color: ", ";\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        ", ": 0;\n        transform: translateX(", ");\n        animation: ", " 0.4s both;\n        animation-delay: ", ";\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  width: ", ";\n  min-height: ", "px;\n  height: ", ";\n  background-color: ", ";\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    from {\n      transform: translateX(", ");\n    }\n    to {\n      transform: translateX(", ");\n    }"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles";
import { css, keyframes } from "styled-components";

var getTranslate = function getTranslate(side) {
  return side === "left" ? "-100%" : "100%";
};

var slideAnimation = function slideAnimation(open, side) {
  var initialValue = open ? getTranslate(side) : 0;
  var endValue = open ? 0 : getTranslate(side);
  return keyframes(_templateObject(), initialValue, endValue);
};

var opacity = {
  entered: 1,
  entering: 0,
  exited: 0,
  exiting: 0,
  unmounted: 0
};
var justify = {
  center: "center",
  left: "flex-start",
  right: "flex-end"
};
var lightboxWidth = {
  center: "auto",
  left: "100%",
  right: "100%"
};

var lightboxHeight = function lightboxHeight(width) {
  return {
    center: "".concat(width, "px"),
    left: "auto",
    right: "auto"
  };
};

export var Lightbox = styled.div(_templateObject2(), function (_ref) {
  var position = _ref.position,
      draftModal = _ref.theme.draftModal;
  return lightboxHeight(draftModal.modalWidth)[position];
}, function (props) {
  return props.theme.draftModal.modalMinHeight;
}, function (_ref2) {
  var position = _ref2.position;
  return lightboxWidth[position];
}, function (props) {
  return props.theme.colors.white;
}, function (_ref3) {
  var open = _ref3.open,
      position = _ref3.position;

  if (position === "left" || position === "right") {
    return css(_templateObject3(), position, getTranslate(position), slideAnimation(open, position), open ? ".1s" : 0);
  }
});
Lightbox.displayName = "S.Lightbox";
export var LightboxSafari = styled.div(_templateObject4(), function (_ref4) {
  var position = _ref4.position,
      draftModal = _ref4.theme.draftModal;
  return lightboxHeight(draftModal.modalWidth)[position];
}, function (props) {
  return props.theme.draftModal.modalMinHeight;
}, function (props) {
  return props.theme.colors.white;
}, function (_ref5) {
  var open = _ref5.open,
      position = _ref5.position;

  if (position === "left" || position === "right") {
    return css(_templateObject5(), position, getTranslate(position), slideAnimation(open, position), open ? ".1s" : 0);
  }
});
LightboxSafari.displayName = "S.LightboxSafari";
export var Overlay = styled.div(_templateObject6(), function (_ref6) {
  var open = _ref6.open;
  return open ? 0 : ".4s";
}, function (_ref7) {
  var transparent = _ref7.transparent,
      theme = _ref7.theme;
  return transparent ? "" : theme.colors.overlay;
}, function (_ref8) {
  var position = _ref8.position;
  return justify[position];
}, function (_ref9) {
  var state = _ref9.state;
  return opacity[state];
});
Overlay.displayName = "S.Overlay";
export var OverlaySafari = styled.div(_templateObject7(), function (_ref10) {
  var open = _ref10.open;
  return open ? 0 : ".4s";
}, function (_ref11) {
  var transparent = _ref11.transparent,
      theme = _ref11.theme;
  return transparent ? "" : theme.colors.overlay;
}, function (_ref12) {
  var position = _ref12.position;
  return justify[position];
}, function (_ref13) {
  var state = _ref13.state;
  return opacity[state];
}, media.smallScreen(_templateObject8()));
OverlaySafari.displayName = "S.OverlaySafari";
export var Modal = styled.div(_templateObject9(), function (props) {
  return "".concat(props.theme.spacing.spacer, " ");
});
export var ModalTitle = styled.div(_templateObject10(), media.smallScreen(_templateObject11()));
export var ModalDraftOrderHistory = styled.div(_templateObject12(), media.smallScreen(_templateObject13()));
export var ModalButton = styled.div(_templateObject14());
export var ModalBtn = styled.button(_templateObject15());
export var LineTitle = styled.div(_templateObject16(), media.smallScreen(_templateObject17()));
export var OrderNumber = styled.div(_templateObject18());
export var CloseButton = styled.button(_templateObject19());
export var ProductImage = styled.div(_templateObject20(), function (props) {
  return props.imageUrl;
});