import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Address summary
 */
var AddressSummary = function AddressSummary(_ref) {
  var address = _ref.address,
      email = _ref.email;

  if (address) {
    var _address$country;

    return React.createElement(S.Wrapper, {
      "data-test": "addressTile"
    }, React.createElement("strong", null, "".concat(address.firstName, " ").concat(address.lastName)), React.createElement("br", null), address.phone && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, _extends({}, commonMessages.phoneNumber, {
      values: {
        phone: address.phone
      }
    })), " ", React.createElement("br", null)), address.streetAddress2 && React.createElement(React.Fragment, null, "\u0420\u0414: ", address.streetAddress2), " ", React.createElement("br", null), address.companyName && React.createElement(React.Fragment, null, address.companyName, " ", React.createElement("br", null)), address.streetAddress1 && React.createElement(React.Fragment, null, address.streetAddress1), React.createElement("br", null), address.city, " ", address.postalCode && React.createElement(React.Fragment, null, ", ", address.postalCode), address.countryArea && React.createElement(React.Fragment, null, ", ", address.countryArea), React.createElement("br", null), address.country && React.createElement(React.Fragment, null, (_address$country = address.country) === null || _address$country === void 0 ? void 0 : _address$country.country), " ", React.createElement("br", null), email && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, _extends({}, commonMessages.showEmail, {
      values: {
        email: email
      }
    })), " ", React.createElement("br", null), " ", React.createElement("br", null)));
  }

  if (email) {
    return React.createElement(S.Wrapper, {
      "data-test": "emailTile"
    }, email);
  }

  return null;
};

export { AddressSummary };