import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import Media from "react-media";
import { ProductDescription } from "@components/molecules";
import { ProductGallery } from "@components/organisms";
import AddToCartSection from "@components/organisms/AddToCartSection";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import { Breadcrumbs, OverlayContext, OverlayTheme, OverlayType } from "../../components";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils"; // import UshopLogo from "../../images/unurshop/logo-v3.png";

import GalleryCarousel from "./GalleryCarousel";
import OtherProducts from "./Other";
import { structuredData } from "../../core/SEO/Product/structuredData";
import { getFbLiveAttrs, getWasPrice, getSalePercent, getLinkImages, getProductLink } from "./utils";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: generateCategoryUrl(product.category.id, product.category.name),
    value: product.category.name
  }, {
    link: generateProductUrl(product.id, product.name),
    value: product.name
  }];
};

var Page = function Page(_ref) {
  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var overlayContext = React.useContext(OverlayContext);
  var productGallery = React.useRef();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var crawlerImages = function crawlerImages() {
    var images = getLinkImages(product);
    return images.map(function (image, index) {
      return {
        id: index,
        url: image,
        alt: product.name
      };
    });
  };

  var wasPrice = getWasPrice(product);

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.filter(function (variant) {
        return variant.id === variantId;
      }).pop();

      if (variant.images.length > 0) {
        return variant.images;
      }

      return product.images;
    }

    return product.images.length ? product.images : crawlerImages();
  };

  var handleAddToCart = function handleAddToCart(variantId, quantity) {
    add(variantId, quantity);
    overlayContext.show(OverlayType.cart, OverlayTheme.right);
  };

  var addToCartSection = React.createElement(AddToCartSection, {
    items: items,
    product: product,
    productId: product.id,
    name: "".concat(product.name, " ").concat(getFbLiveAttrs(product)),
    productVariants: product.variants.filter(function (v) {
      return v.deletedAt === null;
    }),
    productPricing: product.pricing,
    queryAttributes: queryAttributes,
    setVariantId: setVariantId,
    onAddToCart: handleAddToCart,
    onAttributeChangeHandler: onAttributeChangeHandler,
    isAvailableForPurchase: product.isAvailableForPurchase,
    availableForPurchase: product.availableForPurchase
  });
  return React.createElement("div", {
    className: "product-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product)
  })), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product"
  }, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(product)), React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    }
  }, function (matches) {
    var _product$pricing$pric, _product$pricing$pric2, _product$pricing$pric3, _product$pricing$pric4;

    return matches ? React.createElement(React.Fragment, null, product && wasPrice && React.createElement("div", {
      className: "product-page__product__sale-mobile"
    }, "-", getSalePercent(wasPrice, (_product$pricing$pric = product.pricing.priceRange) === null || _product$pricing$pric === void 0 ? void 0 : (_product$pricing$pric2 = _product$pricing$pric.start) === null || _product$pricing$pric2 === void 0 ? void 0 : _product$pricing$pric2.gross.amount)), React.createElement(GalleryCarousel, {
      images: getImages()
    }), React.createElement("div", {
      className: "product-page__product__info"
    }, addToCartSection, React.createElement("div", {
      className: "product-page__product__description"
    }, React.createElement(ProductDescription, {
      ushop: product.ushop,
      productLink: getProductLink(product),
      descriptionJson: product.descriptionJson,
      attributes: product.attributes
    })))) : React.createElement(React.Fragment, null, product && wasPrice && React.createElement("div", {
      className: "product-page__product__sale"
    }, "-", getSalePercent(wasPrice, (_product$pricing$pric3 = product.pricing.priceRange) === null || _product$pricing$pric3 === void 0 ? void 0 : (_product$pricing$pric4 = _product$pricing$pric3.start) === null || _product$pricing$pric4 === void 0 ? void 0 : _product$pricing$pric4.gross.amount)), React.createElement("div", {
      className: "product-page__product__gallery",
      ref: productGallery
    }, React.createElement(ProductGallery, {
      images: getImages()
    })), React.createElement("div", {
      className: "product-page__product__info"
    }, React.createElement("div", {
      className: classNames("product-page__product__info--fixed")
    }, addToCartSection, React.createElement("div", {
      className: "product-page__product__description"
    }, React.createElement(ProductDescription, {
      ushop: product.ushop,
      productLink: getProductLink(product),
      descriptionJson: product.descriptionJson,
      attributes: product.attributes
    })))));
  }))), React.createElement(OtherProducts, {
    products: product.category.products.edges
  }));
};

export default Page;