import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject56() {
  var data = _taggedTemplateLiteral(["\ndisplay: block;\nbackground-color: ", ";\npadding: 20px;\n", "\nfont-size: ", ";\ncursor: pointer;\n"]);

  _templateObject56 = function _templateObject56() {
    return data;
  };

  return data;
}

function _templateObject55() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  box-shadow: 2px 1px 5px #d9d9d9;\n  ", "\n  cursor: pointer;\n"]);

  _templateObject55 = function _templateObject55() {
    return data;
  };

  return data;
}

function _templateObject54() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 0 0 20px 0;\n"]);

  _templateObject54 = function _templateObject54() {
    return data;
  };

  return data;
}

function _templateObject53() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject53 = function _templateObject53() {
    return data;
  };

  return data;
}

function _templateObject52() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n"]);

  _templateObject52 = function _templateObject52() {
    return data;
  };

  return data;
}

function _templateObject51() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n"]);

  _templateObject51 = function _templateObject51() {
    return data;
  };

  return data;
}

function _templateObject50() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.6rem 0 1.4rem 0;\n  font-size: ", ";\n  color: rgba(50, 50, 50, 0.6);\n"]);

  _templateObject50 = function _templateObject50() {
    return data;
  };

  return data;
}

function _templateObject49() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]);

  _templateObject49 = function _templateObject49() {
    return data;
  };

  return data;
}

function _templateObject48() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n\n  ", "\n"]);

  _templateObject48 = function _templateObject48() {
    return data;
  };

  return data;
}

function _templateObject47() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  background-color: ", ";\n  padding: 1rem 1.5rem;\n  border-top: 1px solid #ddd;\n  & b {\n    font-weight: bold;\n  }\n"]);

  _templateObject47 = function _templateObject47() {
    return data;
  };

  return data;
}

function _templateObject46() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject46 = function _templateObject46() {
    return data;
  };

  return data;
}

function _templateObject45() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  min-width: 110px;\n\n  button {\n    padding: 0.8rem 1rem;\n  }\n"]);

  _templateObject45 = function _templateObject45() {
    return data;
  };

  return data;
}

function _templateObject44() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n"]);

  _templateObject44 = function _templateObject44() {
    return data;
  };

  return data;
}

function _templateObject43() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n"]);

  _templateObject43 = function _templateObject43() {
    return data;
  };

  return data;
}

function _templateObject42() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject42 = function _templateObject42() {
    return data;
  };

  return data;
}

function _templateObject41() {
  var data = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]);

  _templateObject41 = function _templateObject41() {
    return data;
  };

  return data;
}

function _templateObject40() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: 30px;\n  margin-bottom: ", ";\n\n  ", "\n"]);

  _templateObject40 = function _templateObject40() {
    return data;
  };

  return data;
}

function _templateObject39() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject39 = function _templateObject39() {
    return data;
  };

  return data;
}

function _templateObject38() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%\n  padding: 1rem 1.5rem;\n  background-color: ", ";\n  margin-bottom: ", ";\n  & button {\n    color: blue;\n  }\n"]);

  _templateObject38 = function _templateObject38() {
    return data;
  };

  return data;
}

function _templateObject37() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  cursor: pointer;\n  background-color: #f2f3f5;\n  padding: 1.875rem;\n  border: 2px solid rgb(33, 18, 94);\n  font-size: 0.875rem;\n  transition: (all 0.3s, color 0s, fill 0s);\n\n  .select {\n    position: absolute;\n    border: 1px solid #323232;\n    left: 50%;\n    top: -15px;\n    background-color: #f2f3f5;\n    padding: 3px 10px;\n    border-radius: 4px;\n    cursor: pointer;\n    -webkit-transform: translate(-50%, 0);\n    -ms-transform: translate(-50%, 0);\n    transform: translate(-50%, 0);\n\n    &.active {\n      cursor: pointer;\n      color: #fff;\n      background-color: rgb(33, 18, 94);\n      border: 2px solid rgb(33, 18, 94);\n    }\n  }\n\n  .strong {\n    font-weight: $bold-font-weight;\n    display: inline-block;\n    margin-bottom: 0.625rem;\n  }\n"]);

  _templateObject37 = function _templateObject37() {
    return data;
  };

  return data;
}

function _templateObject36() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: 1.125rem;\n  text-transform: uppercase;\n  font-weight: 600;\n  line-height: 1.25rem;\n"]);

  _templateObject36 = function _templateObject36() {
    return data;
  };

  return data;
}

function _templateObject35() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject35 = function _templateObject35() {
    return data;
  };

  return data;
}

function _templateObject34() {
  var data = _taggedTemplateLiteral(["\n  background-color: #323232;\n  padding: 0.9rem 3.7rem;\n  border: none;\n  border-radius: 4px;\n  transition: 0.3s;\n  outline: none;\n  color: #fff;\n  cursor: pointer;\n  width: auto;\n\n  :disabled {\n    opacity: 0.4;\n  }\n\n  ", "\n"]);

  _templateObject34 = function _templateObject34() {
    return data;
  };

  return data;
}

function _templateObject33() {
  var data = _taggedTemplateLiteral(["\n  padding-top:1rem;\n    padding-bottom:1rem;\n  "]);

  _templateObject33 = function _templateObject33() {
    return data;
  };

  return data;
}

function _templateObject32() {
  var data = _taggedTemplateLiteral(["\n  background-color: #f2f3f5;\n  border: 1px transparent solid;\n  overflow: auto;\n  height: 100%;\n  padding: 0;\n  transition: (all 0.3s, color 0s, fill 0s);\n  display: flex;\n  flex-direction: column;\n  color: rgb(33, 18, 94);\n  align-items: center;\n  justify-content: center;\n  &:hover {\n    cursor: pointer;\n    background-color: rgb(33, 18, 94);\n\n    .add-btn1 {\n      color: white;\n\n      & svg path {\n        fill: white;\n      }\n    }\n  }\n  ", "\n  .add-btn1 {\n    text-transform: uppercase;\n    font-size: 1.125rem;\n    text-align: center;\n    vertical-align: center;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    padding: 1rem 0 1rem 0;\n\n    p {\n      margin: 0;\n      margin-bottom: calc(1rem / 3);\n      font-weight: 600;\n    }\n  }\n"]);

  _templateObject32 = function _templateObject32() {
    return data;
  };

  return data;
}

function _templateObject31() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject31 = function _templateObject31() {
    return data;
  };

  return data;
}

function _templateObject30() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  margin-top: -1.875rem;\n  margin-left: -1.875rem;\n  .root1 {\n    margin: 0;\n    padding: 0;\n    padding-top: 1.875rem;\n    padding-left: 1.875rem;\n    width: calc(100% / 2);\n    ", "\n  }\n"]);

  _templateObject30 = function _templateObject30() {
    return data;
  };

  return data;
}

function _templateObject29() {
  var data = _taggedTemplateLiteral(["\n  grid-area: button;\n  margin: 3rem 0 0 0;\n"]);

  _templateObject29 = function _templateObject29() {
    return data;
  };

  return data;
}

function _templateObject28() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"]);

  _templateObject28 = function _templateObject28() {
    return data;
  };

  return data;
}

function _templateObject27() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n"]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fff3cd;\n  border: 1px solid #ffecb5;\n  border-radius: 0.25rem;\n  color: #664d03;\n  padding: 1rem;\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  clear: both;\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  float: left;\n  width: 48%;\n  text-align: center;\n  background-color: ", ";\n  border: 2px solid ", ";\n  \n  padding: 20px;\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n  \n  h6 { font-size: ", "; }\n  h3 { ", " }\n\n  &.right{float: right;}\n  b { font-weight: bold; }\n  &:after{\n    content: \"\u0421\u043E\u043D\u0433\u043E\u0445\";\n    position: absolute;\n    bottom: -15px;\n    left: 50%;\n    transform: translate(-50%, 0);\n    border: 1px solid #323232;\n    background-color: ", ";\n    padding: 3px 10px;\n    border-radius: 4px;\n  }\n\n  ", "\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  &:not(:last-child) {\n    width: 100%;\n  }\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  z-index: -1;\n  width: 100%;\n  height: 4px;\n  background-color: ", ";\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  top: 35px;\n  right: 0;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  top: 35px;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  white-space: pre;\n  display: block;\n  position: absolute;\n  top: 35px;\n  transform: translateX(-50%);\n  font-size: ", ";\n  letter-spacing: 2%;\n  color: ", ";\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  box-shadow: 0 0 0 4px #bc1f25;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  border-radius: 50%;\n  width: 12px;\n  height: 12px;\n  border: 6px solid ", ";\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    margin-bottom: 20px;\n    padding: 0 10px 0 10px;\n    &:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  span {\n    display: inline-block;\n  }\n  font-weight: ", ";\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n    transform: rotate(180deg);\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    display: unset;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    cursor: pointer;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 20px;\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  font-size: ", ";\n  text-transform: uppercase;\n  font-weight: ", ";\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 0;\n  padding: 0;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 30px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 0 0 20px 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    top: 0%;\n    overflow-y: scroll;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: calc(100% - 90px);\n    left: 0%;\n    transition: all 0.5s ease;\n    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.light;
}, media.mediumScreen(_templateObject2()), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject3());
});
export var Content = styled.div(_templateObject4());
export var ProductLine = styled.div(_templateObject5());
export var CartSummaryProductList = styled.div(_templateObject6());
export var HR = styled.hr(_templateObject7(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.div(_templateObject8(), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, media.mediumScreen(_templateObject9(), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var ArrowUp = styled.div(_templateObject10(), media.mediumScreen(_templateObject11()), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject12());
});
export var CostLine = styled.div(_templateObject13(), function (props) {
  return props.last ? props.theme.typography.boldFontWeight : "normal";
});
export var Costs = styled.div(_templateObject14());
export var Dot = styled.div(_templateObject15(), function (props) {
  return props.done ? "#BC1F25" : "#c2c2c2";
});
export var ActiveDot = styled.div(_templateObject16());
export var Label = styled.span(_templateObject17(), [function (props) {
  return props.theme.typography.smallFontSize;
}], function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var LeftLabel = styled(Label)(_templateObject18());
export var RightLabel = styled(Label)(_templateObject19());
export var ProgressBar = styled.div(_templateObject20(), function (props) {
  return props.done ? "#BC1F25" : "#c2c2c2";
});
export var Step = styled.div(_templateObject21());
export var WrapperNav = styled.div(_templateObject22());
export var TileRow = styled.label(_templateObject23(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.typography.smallFont2Size;
}, function (props) {
  return props.checked ? "color: #21125E;" : "color: #323232;";
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "\n    &:after{\n      content: \"\u0421\u043E\u043D\u0433\u043E\u0441\u043E\u043D\";\n      color: #fff;\n      border: 1px solid #21125E;\n      background-color: #21125E;\n    }\n  ";
});
export var Clearbox = styled.div(_templateObject24());
export var AlertContainer = styled.div(_templateObject25());
export var AlertTitle = styled.h6(_templateObject26());
export var AlertContent = styled.div(_templateObject27());
export var Divider = styled.div(_templateObject28(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var NButton = styled.div(_templateObject29());
export var ShippingAddress = styled.div(_templateObject30(), media.smallScreen(_templateObject31()));
export var ShippingAddressButton = styled.div(_templateObject32(), media.smallScreen(_templateObject33()));
export var NextButton = styled.button(_templateObject34(), media.smallScreen(_templateObject35()));
export var NextButtonText = styled.span(_templateObject36());
export var ShippingAddressSummary = styled.div(_templateObject37());
export var WalletAmount = styled.div(_templateObject38(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var RemoveWalletAmount = styled.button(_templateObject39());
export var DiscountField = styled.div(_templateObject40(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject41()));
export var Input = styled.div(_templateObject42());
export var InputWithButton = styled.div(_templateObject43());
export var InputWrapper = styled.div(_templateObject44());
export var ButtonWrapper = styled.div(_templateObject45());
export var TitleSub = styled.h4(_templateObject46(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var TwoColRow = styled.div(_templateObject47(), function (props) {
  return props.theme.colors.light;
});
export var Grid = styled.div(_templateObject48(), media.smallScreen(_templateObject49()));
export var SubTitle = styled.h4(_templateObject50(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var TextSummary = styled.p(_templateObject51(), function (props) {
  return props.theme.typography.h4FontSize;
});
export var ErrorMessages = styled.div(_templateObject52());
export var TitleReview = styled.h3(_templateObject53(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var DividerReview = styled.div(_templateObject54(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Tile = styled.label(_templateObject55(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
});
export var WalletTile = styled.label(_templateObject56(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
});