import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { Formik } from "formik";
import { BookingForm } from "./BookingForm";

var MyFormik = function MyFormik(_ref) {
  var productUrl = _ref.productUrl,
      ushopId = _ref.ushopId,
      handleSubmit = _ref.handleSubmit,
      loading = _ref.loading;
  var fInitialValues = {
    addMore: false,
    code: "",
    color: "",
    description: "",
    isRequired: false,
    name: "",
    price: "",
    quantity: 1,
    shippingType: "",
    size: "",
    url: productUrl,
    ushopId: ushopId
  };
  var productTypeOptions = {};
  return React.createElement(Formik, {
    initialValues: fInitialValues,
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting,
          resetForm = _ref2.resetForm;

      if (handleSubmit) {
        handleSubmit(values);
      }

      setSubmitting(false);
      resetForm(fInitialValues);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement(BookingForm, _extends({
      ushopId: ushopId,
      productTypeOptions: productTypeOptions
    }, {
      handleBlur: handleBlur,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      setFieldTouched: setFieldTouched,
      setFieldValue: setFieldValue,
      values: values
    }));
  });
};

export { MyFormik };