import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/atoms";
import { Money } from "@components/containers";
import { AddressSummary } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Review order view showed in checkout.
 */
var CheckoutReview = function CheckoutReview(_ref) {
  var shippingAddress = _ref.shippingAddress,
      billingAddress = _ref.billingAddress,
      shippingMethodName = _ref.shippingMethodName,
      paymentMethodName = _ref.paymentMethodName,
      email = _ref.email,
      errors = _ref.errors,
      amountState = _ref.amountState,
      totalPrice = _ref.totalPrice;
  var amount = parseFloat((amountState.maxAmount * (amountState.is30 ? 0.3 : 1) - amountState.walletAmount).toFixed(2));
  return React.createElement(S.Wrapper, {
    "data-test": "sectionTitle"
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.reviewOrder)), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), false && React.createElement("section", {
    "data-test": "billingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.4138151592",
    defaultMessage: "\u0422\u043E\u043E\u0446\u043E\u043E\u043D\u044B \u0445\u0430\u044F\u0433"
  })), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: billingAddress,
    email: email
  })), shippingMethodName && React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.3577164776",
    defaultMessage: "Shipping Method"
  })), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "shippingMethodName"
  }, shippingMethodName)), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.2644048082",
    defaultMessage: "\u0422\u04E9\u043B\u0431\u04E9\u0440\u0438\u0439\u043D \u043D\u04E9\u0445\u0446\u04E9\u043B"
  })), React.createElement(S.Divider, null), React.createElement(S.TextSummary, null, amountState.is30 ? React.createElement("small", {
    style: {
      fontSize: 12
    }
  }, "\u0423\u0440\u044C\u0434\u0447\u0438\u043B\u0433\u0430\u0430 30%:", " ", React.createElement(Money, {
    money: {
      amount: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount) * 0.3,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  })) : React.createElement("small", {
    style: {
      fontSize: 12
    }
  }, "\u0411\u04AF\u0442\u044D\u043D \u0442\u04E9\u043B\u0431\u04E9\u0440:", " ", React.createElement(Money, {
    money: {
      amount: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  }))), amountState.walletAmount > 0 && React.createElement(S.TextSummary, null, "\u0425\u044D\u0442\u044D\u0432\u0447\u043D\u044D\u044D\u0441:", " ", React.createElement(Money, {
    money: {
      amount: amountState.walletAmount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  })), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, paymentMethodName, " \u043A\u0430\u0440\u0442\u0430\u0430\u0440"), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, amountState.is30 ? React.createElement(React.Fragment, null, React.createElement(Money, {
    money: {
      amount: amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  }), " ") : React.createElement(React.Fragment, null, React.createElement(Money, {
    money: {
      amount: amount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency
    }
  }), " ")))), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};

export { CheckoutReview };