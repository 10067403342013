import * as React from "react";
import { ProductList } from "@components/organisms";

var OtherProducts = function OtherProducts(_ref) {
  var products = _ref.products;
  return React.createElement("div", {
    className: "product-page__other-products"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "ushop-title"
  }, React.createElement("h4", null, "\u0422\u0430\u043D\u0434 \u0441\u0430\u043D\u0430\u043B \u0431\u043E\u043B\u0433\u043E\u0445"), React.createElement("h3", null, "\u0418\u0436\u0438\u043B \u0442\u04E9\u0441\u0442\u044D\u0439 \u0431\u0430\u0440\u0430\u0430\u043D\u0443\u0443\u0434")), React.createElement(ProductList, {
    products: products.map(function (_ref2) {
      var node = _ref2.node;
      return node;
    })
  })));
};

export default OtherProducts;