import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import { Link } from "react-router-dom";
import { Money } from "@components/containers";
import { ModalSafari as Modal } from "@components/organisms/ModalSafari";
import { generateProductUrl } from "@temp/core/utils";
import * as S from "./styles";

var OrderDetailModal = function OrderDetailModal(_ref) {
  var _line$variant, _line$variant$product, _line$variant2, _line$variant2$produc, _line$variant3, _line$variant3$produc, _line$variant5, _line$variant5$produc, _line$variant5$produc2, _line$variant6, _line$variant6$produc, _line$variant6$produc2, _line$variant7, _line$variant8, _line$variant8$produc, _line$variant8$produc2, _line$variant9, _line$variant9$produc, _line$variant9$produc2, _line$variant10, _line$variant10$produ, _line$variant10$produ2, _line$variant11, _line$variant11$produ, _line$variant11$produ2, _line$variant12, _line$unitPrice, _line$totalPrice, _line$variant13, _line$variant13$produ, _line$variant13$produ2;

  var closeModal = _ref.closeModal,
      open = _ref.open,
      line = _ref.line,
      testingContext = _ref.testingContext;

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      imageUrl = _React$useState2[0],
      setImageUrl = _React$useState2[1];

  var productUrl = generateProductUrl(line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : (_line$variant$product = _line$variant.product) === null || _line$variant$product === void 0 ? void 0 : _line$variant$product.id, line === null || line === void 0 ? void 0 : (_line$variant2 = line.variant) === null || _line$variant2 === void 0 ? void 0 : (_line$variant2$produc = _line$variant2.product) === null || _line$variant2$produc === void 0 ? void 0 : _line$variant2$produc.name);
  var shopUrl = line === null || line === void 0 ? void 0 : (_line$variant3 = line.variant) === null || _line$variant3 === void 0 ? void 0 : (_line$variant3$produc = _line$variant3.product) === null || _line$variant3$produc === void 0 ? void 0 : _line$variant3$produc.metadata.find(function (f) {
    return (f === null || f === void 0 ? void 0 : f.key) === "url";
  });
  React.useEffect(function () {
    var _line$variant4, _line$variant4$produc;

    var images = line === null || line === void 0 ? void 0 : (_line$variant4 = line.variant) === null || _line$variant4 === void 0 ? void 0 : (_line$variant4$produc = _line$variant4.product) === null || _line$variant4$produc === void 0 ? void 0 : _line$variant4$produc.images;

    if (images) {
      var _images$;

      setImageUrl(((_images$ = images[0]) === null || _images$ === void 0 ? void 0 : _images$.url) || null);
    }
  }, [line]);
  return React.createElement(Modal, {
    submitButtonTestingContext: "submitAddressFormModalButton",
    testingContext: String(testingContext),
    title: JSON.stringify(line === null || line === void 0 ? void 0 : line.productName),
    hide: closeModal,
    formId: "formid",
    disabled: false,
    show: open,
    submitBtnText: "\u0411\u0443\u0446\u0430\u0445",
    onSubmit: closeModal,
    position: "center-w-max-900"
  }, React.createElement(S.ProductDetails, null, React.createElement(S.ProductImages, null, React.createElement(S.ProductThumbnails, {
    dd: (line === null || line === void 0 ? void 0 : (_line$variant5 = line.variant) === null || _line$variant5 === void 0 ? void 0 : (_line$variant5$produc = _line$variant5.product) === null || _line$variant5$produc === void 0 ? void 0 : (_line$variant5$produc2 = _line$variant5$produc.images) === null || _line$variant5$produc2 === void 0 ? void 0 : _line$variant5$produc2.length) || 1
  }, line === null || line === void 0 ? void 0 : (_line$variant6 = line.variant) === null || _line$variant6 === void 0 ? void 0 : (_line$variant6$produc = _line$variant6.product) === null || _line$variant6$produc === void 0 ? void 0 : (_line$variant6$produc2 = _line$variant6$produc.images) === null || _line$variant6$produc2 === void 0 ? void 0 : _line$variant6$produc2.map(function (i) {
    return React.createElement("button", {
      key: "product-thumb-".concat(i === null || i === void 0 ? void 0 : i.id),
      onClick: function onClick() {
        return setImageUrl((i === null || i === void 0 ? void 0 : i.url) || "");
      }
    }, React.createElement("img", {
      src: i === null || i === void 0 ? void 0 : i.url,
      alt: ""
    }));
  })), React.createElement(S.ProductImage, null, React.createElement("img", {
    src: imageUrl || "",
    alt: line === null || line === void 0 ? void 0 : (_line$variant7 = line.variant) === null || _line$variant7 === void 0 ? void 0 : _line$variant7.name
  }))), React.createElement(S.ProductInfo, null, React.createElement(S.RealLink, null, React.createElement("div", {
    className: "ushop-logo"
  }, (line === null || line === void 0 ? void 0 : (_line$variant8 = line.variant) === null || _line$variant8 === void 0 ? void 0 : (_line$variant8$produc = _line$variant8.product) === null || _line$variant8$produc === void 0 ? void 0 : (_line$variant8$produc2 = _line$variant8$produc.ushop) === null || _line$variant8$produc2 === void 0 ? void 0 : _line$variant8$produc2.logoImage) && React.createElement("img", {
    src: (_line$variant9 = line.variant) === null || _line$variant9 === void 0 ? void 0 : (_line$variant9$produc = _line$variant9.product) === null || _line$variant9$produc === void 0 ? void 0 : (_line$variant9$produc2 = _line$variant9$produc.ushop) === null || _line$variant9$produc2 === void 0 ? void 0 : _line$variant9$produc2.logoImage.url,
    alt: (_line$variant10 = line.variant) === null || _line$variant10 === void 0 ? void 0 : (_line$variant10$produ = _line$variant10.product) === null || _line$variant10$produ === void 0 ? void 0 : (_line$variant10$produ2 = _line$variant10$produ.ushop) === null || _line$variant10$produ2 === void 0 ? void 0 : _line$variant10$produ2.name
  })), React.createElement("h3", null, line === null || line === void 0 ? void 0 : (_line$variant11 = line.variant) === null || _line$variant11 === void 0 ? void 0 : (_line$variant11$produ = _line$variant11.product) === null || _line$variant11$produ === void 0 ? void 0 : (_line$variant11$produ2 = _line$variant11$produ.ushop) === null || _line$variant11$produ2 === void 0 ? void 0 : _line$variant11$produ2.name)), React.createElement("h2", null, line === null || line === void 0 ? void 0 : line.productName), React.createElement(S.Size, null, line === null || line === void 0 ? void 0 : (_line$variant12 = line.variant) === null || _line$variant12 === void 0 ? void 0 : _line$variant12.name), React.createElement(S.Price, null, React.createElement(Money, {
    money: line === null || line === void 0 ? void 0 : (_line$unitPrice = line.unitPrice) === null || _line$unitPrice === void 0 ? void 0 : _line$unitPrice.gross
  }), " x ", line === null || line === void 0 ? void 0 : line.quantity, " =", " ", React.createElement("b", null, React.createElement(Money, {
    money: line === null || line === void 0 ? void 0 : (_line$totalPrice = line.totalPrice) === null || _line$totalPrice === void 0 ? void 0 : _line$totalPrice.gross
  }))), React.createElement(S.UnurshopLink, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement("button", null, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u043B\u0438\u043D\u043A"))), React.createElement(S.ShopLink, null, React.createElement("a", {
    href: shopUrl === null || shopUrl === void 0 ? void 0 : shopUrl.value,
    target: "_blank"
  }, line === null || line === void 0 ? void 0 : (_line$variant13 = line.variant) === null || _line$variant13 === void 0 ? void 0 : (_line$variant13$produ = _line$variant13.product) === null || _line$variant13$produ === void 0 ? void 0 : (_line$variant13$produ2 = _line$variant13$produ.ushop) === null || _line$variant13$produ2 === void 0 ? void 0 : _line$variant13$produ2.name, " \u043B\u0438\u043D\u043A")))));
};

export default OrderDetailModal;