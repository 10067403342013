import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl"; // import { Link } from "react-router-dom";

import ReactSVG from "react-svg";
import { commonMessages } from "@temp/intl"; // import { MenuDropdown, Offline, Online, OverlayTheme, OverlayType } from "..";

import { Offline, Online, OverlayTheme, OverlayType } from ".."; // import * as appPaths from "../../app/routes";

import { maybe } from "../../core/utils";
import cartImg from "../../images/cart.svg";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import searchImg from "../../images/search.svg"; // import userImg from "../../images/user.svg";

import { TypedMainMenuQuery } from "./queries";

var MobileMainMenu = function MobileMainMenu(props) {
  // const { overlayContext, user, handleSignOut, cartItemsQuantity } = props;
  var overlayContext = props.overlayContext,
      cartItemsQuantity = props.cartItemsQuantity;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "main-menu__left"
  }, React.createElement(TypedMainMenuQuery, {
    renderOnError: true,
    displayLoader: false
  }, function (_ref) {
    var data = _ref.data;
    var items = maybe(function () {
      return data.shop.navigation.main.items;
    }, []);
    return React.createElement("ul", null, React.createElement("li", {
      "data-test": "toggleSideMenuLink",
      className: "main-menu__hamburger",
      onClick: function onClick() {
        return overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
          data: items
        });
      }
    }, React.createElement(ReactSVG, {
      path: hamburgerImg,
      className: "main-menu__hamburger--icon"
    }), React.createElement(ReactSVG, {
      path: hamburgerHoverImg,
      className: "main-menu__hamburger--hover"
    })));
  })), React.createElement("div", {
    className: "main-menu__center"
  }, React.createElement("div", {
    className: "ushop__searchbox"
  }, React.createElement("div", {
    className: "ushop-search-input"
  }, React.createElement("button", {
    onClick: function onClick() {
      return overlayContext.show(OverlayType.search, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, commonMessages.searchText), React.createElement("span", null, React.createElement(ReactSVG, {
    path: searchImg
  })))))), React.createElement("div", {
    className: "main-menu__right"
  }, React.createElement("ul", null, React.createElement(Online, null, React.createElement("li", {
    "data-test": "menuCartOverlayLink",
    className: "main-menu__icon main-menu__cart",
    onClick: function onClick() {
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
    }
  }, React.createElement(ReactSVG, {
    path: cartImg
  }), cartItemsQuantity > 0 ? React.createElement("span", {
    className: "main-menu__cart__quantity"
  }, cartItemsQuantity) : null)), React.createElement(Offline, null, React.createElement("li", {
    className: "main-menu__offline"
  }, "\xA0")))));
};

export default MobileMainMenu;