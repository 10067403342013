import * as React from "react";
import * as S from "./styles";

var ShippingAddressSummary = function ShippingAddressSummary(_ref) {
  var address = _ref.address,
      paragraphRef = _ref.paragraphRef;

  if (address) {
    return React.createElement(S.ShippingAddressSummary, null, React.createElement("span", {
      className: "select active"
    }, "\u0421\u043E\u043D\u0433\u043E\u0441\u043E\u043D"), React.createElement("span", {
      className: "strong"
    }, "".concat(address.firstName, " ").concat(address.lastName)), React.createElement("div", null, address.phone && React.createElement(React.Fragment, null, "Phone number: ", address.phone, React.createElement("br", null)), address.streetAddress2 && React.createElement(React.Fragment, null, address.streetAddress2, React.createElement("br", null)), address.companyName && React.createElement(React.Fragment, null, address.companyName, React.createElement("br", null)), address.streetAddress1 && React.createElement(React.Fragment, null, address.streetAddress1, React.createElement("br", null)), address.postalCode, ",\xA0", address.city, React.createElement("br", null), address.countryArea, ",\xA0", address.country.country));
  }
};

export default ShippingAddressSummary;