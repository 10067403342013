import _extends from "@babel/runtime/helpers/extends";
import React, { useEffect } from "react";
import { CardHeaderSafari, FormFooterSafari } from "@components/molecules";
import { OverlaySafari as Overlay } from "@components/organisms";
import * as S from "./styles";

var getCancelBtnProps = function getCancelBtnProps(action, text) {
  return text && {
    cancelBtn: {
      action: action,
      testingContext: "cancelButton",
      text: text
    }
  };
};

var getSubmitBtnProps = function getSubmitBtnProps(text, submitButtonTestingContext, action) {
  return {
    submitBtn: action ? {
      action: action,
      testingContext: submitButtonTestingContext,
      text: text
    } : {
      testingContext: submitButtonTestingContext,
      text: text
    }
  };
};

export var ModalSafari = function ModalSafari(_ref) {
  var cancelBtnText = _ref.cancelBtnText,
      children = _ref.children,
      disabled = _ref.disabled,
      hide = _ref.hide,
      _ref$formId = _ref.formId,
      formId = _ref$formId === void 0 ? "modal-submit" : _ref$formId,
      onSubmit = _ref.onSubmit,
      submitBtnText = _ref.submitBtnText,
      submitButtonTestingContext = _ref.submitButtonTestingContext,
      show = _ref.show,
      target = _ref.target,
      testingContext = _ref.testingContext,
      title = _ref.title,
      _ref$position = _ref.position,
      position = _ref$position === void 0 ? "center" : _ref$position;
  useEffect(function () {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return function () {
      document.body.style.overflow = "auto";
    };
  }, [show]);
  return React.createElement(Overlay, {
    testingContext: testingContext,
    position: position,
    show: show,
    hide: hide,
    target: target
  }, React.createElement(S.Modal, null, React.createElement(CardHeaderSafari, {
    divider: true,
    onHide: hide
  }, title), React.createElement(S.Content, null, children), (submitBtnText || cancelBtnText) && React.createElement(FormFooterSafari, _extends({
    divider: true,
    disabled: disabled
  }, getSubmitBtnProps(submitBtnText || "", submitButtonTestingContext, onSubmit), getCancelBtnProps(hide, cancelBtnText), {
    formId: formId
  }))));
};