import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query OrdersByUser($perPage: Int!, $after: String) {\n    me {\n      id\n      orders(first: $perPage, after: $after) {\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        edges {\n          node {\n            id\n            token\n            number\n            statusDisplay\n            created\n            total {\n              gross {\n                amount\n                currency\n              }\n              net {\n                amount\n                currency\n              }\n            }\n            lines {\n              id\n              productName\n              variant {\n                id\n                product {\n                  id\n                  productType {\n                    id\n                  }\n                }\n              }\n              thumbnail {\n                alt\n                url\n              }\n              thumbnail2x: thumbnail(size: 510) {\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
export var ordersByUser = gql(_templateObject());
export var TypedOrdersByUser = TypedQuery(ordersByUser);