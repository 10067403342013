import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RichTextContent } from "@components/atoms";
import * as S from "./styles";
var TABS;

(function (TABS) {
  TABS[TABS["DESCRIPTION"] = 0] = "DESCRIPTION";
  TABS[TABS["ATTRIBUTES"] = 1] = "ATTRIBUTES";
})(TABS || (TABS = {}));

export var ProductDescription = function ProductDescription(_ref) {
  var _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$descriptionJson = _ref.descriptionJson,
      descriptionJson = _ref$descriptionJson === void 0 ? "" : _ref$descriptionJson,
      attributes = _ref.attributes,
      ushop = _ref.ushop,
      productLink = _ref.productLink;

  var _React$useState = React.useState(TABS.DESCRIPTION),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeTab = _React$useState2[0],
      setActiveTab = _React$useState2[1];

  return React.createElement(S.Wrapper, null, React.createElement(S.Tabs, null, React.createElement(S.TabTitle, {
    active: activeTab === TABS.DESCRIPTION,
    onMouseEnter: function onMouseEnter(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.DESCRIPTION);
    },
    onClick: function onClick(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.DESCRIPTION);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.3735097141",
    defaultMessage: "\u0422\u0410\u0419\u041B\u0411\u0410\u0420"
  }))), activeTab === TABS.DESCRIPTION && (descriptionJson ? React.createElement(React.Fragment, null, React.createElement(RichTextContent, {
    descriptionJson: descriptionJson
  }), attributes && attributes.map(function (attribute, index) {
    return React.createElement("li", {
      key: index
    }, React.createElement(S.AttributeName, null, attribute.attribute.name, ":", " "), " ", attribute.values.map(function (value) {
      return value.name;
    }).join(", "));
  }), ushop && React.createElement(React.Fragment, null, "\u042D\u043D\u044D \u0431\u0430\u0440\u0430\u0430 \u043D\u044C", " ", React.createElement("a", {
    href: ushop.url,
    target: "_blank",
    rel: "noreferrer",
    style: {
      fontWeight: "bold"
    }
  }, ushop.name), " ", "\u0441\u0430\u0439\u0442 \u0434\u044D\u044D\u0440 \u0431\u0430\u0439\u0433\u0430\u0430 \u0431\u04E9\u0433\u04E9\u04E9\u0434 \u0442\u0430 \u0431\u0430\u0440\u0430\u0430\u043D\u044B \u0434\u044D\u043B\u0433\u044D\u0440\u044D\u043D\u0433\u04AF\u0439 \u043C\u044D\u0434\u044D\u044D\u043B\u043B\u0438\u0439\u0433 \u0445\u0430\u0440\u0430\u0445\u044B\u0433 \u0445\u04AF\u0441\u0432\u044D\u043B \u0434\u043E\u043E\u0440\u0445 \u043B\u0438\u043D\u043A \u0434\u044D\u044D\u0440 \u0434\u0430\u0440\u043D\u0430 \u0443\u0443", React.createElement("br", null), React.createElement("br", null)), productLink && React.createElement("a", {
    href: productLink,
    target: "_blank",
    rel: "noreferrer",
    style: {
      textDecoration: "underline"
    }
  }, "\u0411\u0430\u0440\u0430\u0430\u043D\u044B \u043B\u0438\u043D\u043A")) : React.createElement("p", null, description)), activeTab === TABS.ATTRIBUTES && React.createElement(S.AttributeList, null, attributes && attributes.map(function (attribute, index) {
    return React.createElement("li", {
      key: index
    }, React.createElement(S.AttributeName, null, attribute.attribute.name, ": "), " ", attribute.values.map(function (value) {
      return value.name;
    }).join(", "));
  })));
};