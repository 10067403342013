import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { AddressFormModal, AddressGrid } from "@components/organisms";
import { useDefaultUserAddress, useDeleteUserAddresss } from "unurshop-sdk";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { AddressTypeEnum, AccountErrorCode } from "unurshop-sdk/lib/gqlTypes/globalTypes";
import { getUserDetailsQuery } from "unurshop-sdk/lib/queries/user";
import { ShopContext } from "../../components/ShopProvider/context";

var AddressBook = function AddressBook(_ref) {
  var user = _ref.user;

  var _React$useContext = React.useContext(ShopContext),
      defaultCountry = _React$useContext.defaultCountry,
      countries = _React$useContext.countries;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      displayNewModal = _React$useState2[0],
      setDisplayNewModal = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      displayEditModal = _React$useState4[0],
      setDisplayEditModal = _React$useState4[1];

  var _React$useState5 = React.useState(null),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      addressData = _React$useState6[0],
      setAddressData = _React$useState6[1];

  var _useDefaultUserAddres = useDefaultUserAddress(undefined, {
    refetchQueries: function refetchQueries(result) {
      if (result.data.accountSetDefaultAddress.errors.length > 0) {
        if (result.data.accountSetDefaultAddress.errors.find(function (err) {
          return err.code === AccountErrorCode.NOT_FOUND;
        })) {
          return [{
            query: getUserDetailsQuery
          }];
        }
      }
    }
  }),
      _useDefaultUserAddres2 = _slicedToArray(_useDefaultUserAddres, 1),
      setDefaultUserAddress = _useDefaultUserAddres2[0];

  var _useDeleteUserAddress = useDeleteUserAddresss(undefined, {
    refetchQueries: function refetchQueries(result) {
      if (result.data.accountAddressDelete.errors.length > 0) {
        if (result.data.accountAddressDelete.errors.find(function (err) {
          return err.code === AccountErrorCode.NOT_FOUND;
        })) {
          return [{
            query: getUserDetailsQuery
          }];
        }
      }
    }
  }),
      _useDeleteUserAddress2 = _slicedToArray(_useDeleteUserAddress, 1),
      setDeleteUserAddress = _useDeleteUserAddress2[0];

  var intl = useIntl();
  var userAddresses = user.addresses.map(function (address) {
    var addressToDisplay = {
      address: _objectSpread({}, address)
    };

    addressToDisplay.onEdit = function () {
      setDisplayEditModal(true);
      setAddressData({
        address: address,
        id: address.id
      });
    };

    addressToDisplay.onRemove = function () {
      return setDeleteUserAddress({
        addressId: address.id
      });
    };

    addressToDisplay.setDefault = function (type) {
      setDefaultUserAddress({
        id: address.id,
        type: type === "BILLING" ? AddressTypeEnum.BILLING : AddressTypeEnum.SHIPPING
      });
    };

    return addressToDisplay;
  });
  return React.createElement("div", {
    className: "address-book-container"
  }, React.createElement(AddressGrid, {
    addresses: userAddresses,
    addNewAddress: function addNewAddress() {
      setDisplayNewModal(true);
    }
  }), displayNewModal && React.createElement(AddressFormModal, _extends({
    hideModal: function hideModal() {
      setDisplayNewModal(false);
    },
    userId: user.id
  }, {
    defaultValue: defaultCountry || {}
  }, {
    submitBtnText: intl.formatMessage(commonMessages.add),
    title: intl.formatMessage(checkoutMessages.addNewAddress)
  }, {
    countriesOptions: countries
  }, {
    formId: "address-form"
  })), displayEditModal && React.createElement(AddressFormModal, _extends({
    hideModal: function hideModal() {
      setDisplayEditModal(false);
    },
    address: addressData,
    submitBtnText: intl.formatMessage(commonMessages.save),
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "Edit address",
      "id": "account.AddressBook.AddressBook.2776756156"
    }, "id", "account.AddressBook.AddressBook.2776756156"))
  }, {
    countriesOptions: countries
  }, {
    formId: "address-form"
  })));
};

export default AddressBook;